import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import {permissionsStore} from "../PermissionsStore";
import useDebounce from "../../../../common/customHook/useDebounce";

const FilterAdmin = () => {
  const navigate = useNavigate();
    const updateUrlParams = (isSearchTriggered = false) => {
        const searchParams = new URLSearchParams();
        if (debouncedSearchStaff) {
            searchParams.set("search", debouncedSearchStaff);
        }
        permissionsStore.staticParam.page_number = 0;
        navigate({ search: searchParams.toString() }, { replace: true });
        if (isSearchTriggered) {
            permissionsStore.fetchListRole();
        }
    };

    const debouncedSearchStaff = useDebounce(permissionsStore.searchTemp, 500);
    // Trigger the API call only when the debounced value updates
    useEffect(() => {
        if (debouncedSearchStaff !== permissionsStore.staticParam.search) {
            permissionsStore.staticParam.search = debouncedSearchStaff;
            updateUrlParams(true);
        }
    }, [debouncedSearchStaff]);
  return (
    <div className="box-filter">
      <div className="search">
        <img src="/assets/icon/searchModel.svg" alt="searchModel" />
        <input
          type="text"
          placeholder="Tìm kiếm theo tên hoặc email"
          onChange={(e: any) => {
              permissionsStore.searchTemp = e.target.value
          }}
        />
      </div>
      <div className="action--add" onClick={()=>{permissionsStore.openModalAddEditStaff = true}}>
        <span>
          <img src="/assets/icon/plus.svg" alt="add" />
          Thêm mới
        </span>
      </div>
    </div>
  );
};

export default observer(FilterAdmin);
