import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import './CloseMoneyControl.css';
import { Button, Modal } from "antd";
import { salePointStore } from "../../../../SalePointStore";
import CurrencyType, { CurrencyTypeRef } from "../../../../components/CurrencyType";
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

interface MoneyControlProps {
    posId: string;
}

const CloseMoneyControl: React.FC<MoneyControlProps> = ({ posId }) => {
    const [cashOnOpen, setCashOnOpen] = useState("");
    const currencyTypeRef = useRef<CurrencyTypeRef>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [transferAmount, setTransferAmount] = useState("");
    const [sessionNote, setSessionNote] = useState("");
    const navigate = useNavigate();

    const handleOpenCurrencyType = () => {
        salePointStore.showModalCurrency();
    };

    const handleCurrencyConfirm = (total: number, details: string[]) => {
        const formattedTotal = total.toLocaleString();
        setCashOnOpen(formattedTotal);

        const totalWithoutCommas = total.toString().replace(/,/g, '');

        const metaNoteStart = details.map((detail) => {
            const [quantity, name] = detail.split(" x ");
            return {
                name: `${name.trim()} `,
                quantity: quantity.trim(),
            };
        });

        const note = `Chi tiết tiền mặt:\n${details.join("\n")}`;
        setSessionNote(note);

        salePointStore.staticDataCloseMoney = {
            pos_id: posId,
            price_pos_cash: totalWithoutCommas,
            price_pos_bank: '',
            note_pos_close: [...metaNoteStart, ...details],
        };
    };

    const handleCashOnOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '');
        if (/^\d*$/.test(value)) {
            const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            setCashOnOpen(formattedValue);
            setErrorMessage("");

            salePointStore.staticDataCloseMoney = {
                ...salePointStore.staticDataCloseMoney,
                price_pos_cash: value,
            };
        } else {
            setErrorMessage("Giá trị không hợp lệ! Vui lòng nhập số.");
        }
    };


    const handleTransferAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '');
        if (/^\d*$/.test(value)) {
            const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            setTransferAmount(formattedValue);

            salePointStore.staticDataCloseMoney = {
                ...salePointStore.staticDataCloseMoney,
                price_pos_bank: value,
            };
        }
    };

    const handleCloseTransferAmount = () => {
        setTransferAmount("");
        salePointStore.staticDataCloseMoney.price_pos_bank = ""; // Cập nhật giá trị trong store
    };

    const handleSessionNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const note = e.target.value;
        setSessionNote(note);
        salePointStore.staticDataCloseMoney = {
            ...salePointStore.staticDataCloseMoney,
            note_pos_close: [note],
        };
    };


    const handleCloseSession = async () => {
        let valid = true;
        setErrorMessage("");

        if (cashOnOpen === "") {
            setErrorMessage("Vui lòng nhập số tiền mặt!");
            valid = false;
        } else if (transferAmount === "") {
            setErrorMessage("Vui lòng nhập số tiền chuyển khoản!");
            valid = false;
        } else if (!sessionNote) {
            setErrorMessage("Vui lòng nhập ghi chú!");
            valid = false;
        }

        if (valid && salePointStore.staticDataCloseMoney.pos_id) {
            const totalBankTransfer = transferAmount.replace(/,/g, '');
            salePointStore.staticDataCloseMoney.price_pos_bank = totalBankTransfer;

            await salePointStore.closeCash(salePointStore.staticDataCloseMoney);
            handleModalClose();
            navigate("/danh-sach-pos");
        } else {
            console.error("Vui lòng điền đầy đủ thông tin trước khi đóng phiên!");
        }
    };





    const handleReset = () => {
        setCashOnOpen("");
        setSessionNote("");
        setErrorMessage("");
        if (currencyTypeRef.current) {
            currencyTypeRef.current.resetCurrency();
        }
    };

    const handleModalClose = () => {
        handleReset();
        salePointStore.handleCashClose();
    };


    useEffect(() => {
        if (!salePointStore.staticDataCloseMoney.pos_id) {
            salePointStore.staticDataCloseMoney.pos_id = posId;
        }
    }, [posId]);

    return (
        <Modal
            className="modal-form"
            footer={null}
            title="Đang đóng máy tính tiền"
            open={salePointStore.isModalCash}
            onCancel={handleModalClose}
        >
            <div className="CloseMoneyControl-container">
                <div className="line"></div>
                <table className="table table-borderless">
                    <thead className="header-table">
                    <tr>
                        <th>Phương thức thanh toán</th>
                        <th className="text-header-table">Dự kiến</th>
                        <th>Đã đếm</th>
                        <th className="text-header-table">Chênh lệch</th>
                    </tr>
                    </thead>
                    <tbody className="body-table">
                    <tr>
                        <td className="text-money">Tiền mặt</td>
                        <td className="count-money">0 đ</td>
                        <td className="text-count-money">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Vui lòng nhập"
                                    value={cashOnOpen}
                                    onChange={handleCashOnOpenChange}
                                />
                                <span className="input-group-text" onClick={handleOpenCurrencyType}>
                                    <img src="/assets/icon/money_dolar.svg" alt="Money Icon" />
                                </span>
                            </div>
                            {errorMessage && !cashOnOpen && (
                                <span className="error-message" style={{ color: "red" }}>{errorMessage}</span>
                            )}
                        </td>
                        <td className="count-money" style={{ color: cashOnOpen ? 'green' : 'red' }}>
                            {cashOnOpen ? `${cashOnOpen} ₫` : '-0 đ'}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-money">Chuyển khoản</td>
                        <td className="count-money">0 đ</td>
                        <td className="input-group-money">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Vui lòng nhập"
                                    value={transferAmount}
                                    onChange={handleTransferAmountChange}
                                />
                                <Button
                                    type="text"
                                    icon={<CloseOutlined />}
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={handleCloseTransferAmount}
                                />
                            </div>
                            {errorMessage && !transferAmount && (
                                <span className="error-message" style={{ color: "red" }}>Vui lòng nhập số tiền chuyển khoản!</span>
                            )}
                        </td>
                        <td className="count-money" style={{ color: transferAmount ? 'green' : 'black' }}>
                            {transferAmount ? `${transferAmount} ₫` : '0 đ'}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="form-group">
                    <label>Ghi chú đóng phiên</label>
                    <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Vui lòng nhập"
                        value={sessionNote}
                        onChange={handleSessionNoteChange}
                    />
                    {errorMessage && !sessionNote && (
                        <span className="error-message" style={{ color: "red", fontSize: "16px" }}>Vui lòng nhập ghi chú!</span>
                    )}
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-warning" onClick={handleCloseSession}>Đóng máy tính tiền</button>
                    <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Hủy bỏ</button>
                </div>
            </div>
            <CurrencyType onConfirm={handleCurrencyConfirm} ref={currencyTypeRef} />
        </Modal>
    );
};

export default observer(CloseMoneyControl);
