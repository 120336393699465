import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Form, Modal} from 'antd';
import {paymentPosStore} from "../PaymentPosStore";
import { Input } from 'antd';
import {roomStore} from "../../../room/RoomStore";
import {useNavigate, useParams} from "react-router-dom";
import StorageService from "../../../../common/service/StorageService";
import {values} from "mobx";
import {handleFile} from "../../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../../shared/components/form/UploadFile";
const ModalImageQr = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    useEffect(() => {
        form.setFieldsValue({
            note: paymentPosStore.notePos,
        });
    }, [form,paymentPosStore.notePos]);
    return (
        <>
            <Modal className="modalQr" title="Chuyển khoản" closable={false} open={paymentPosStore.isModalImageQrOpen} footer={null}>
                <Form
                    form={form}
                    name="room-form"
                    onFinish={(values) => {
                        paymentPosStore.fetchSaveOrderPosEcommerce((route) => navigate(route));
                    }}
                >
                    {paymentPosStore.imageQr !== '' ? (
                        <>
                            <div className="form-modal-image">

                                <>
                                    <img src={paymentPosStore.imageQr} alt="Qr"/>
                                </>

                            </div>
                            <UploadFile
                                text={`ảnh chuyển khoản`}
                                file={paymentPosStore.imageBank}
                                onIconRemove={paymentPosStore.handleRemoveImageBank}
                                onFileChange={(e) => handleFile(e, paymentPosStore.handleSetFileImageBank)}
                            />
                            <div className="footer-note">
                                <Button className="btnCloseNote" onClick={() => paymentPosStore.handleCancelImageQr()}>
                                    Hủy bỏ
                                </Button>
                                <Button type="primary" htmlType="submit" className="btnAddNotePay">
                                    Xác nhận
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>Phương thức thanh toán chuyển khoản chưa được kích hoạt</p>
                            <div className="footer-note">
                                <Button className="btnCloseNote" onClick={() => paymentPosStore.handleCancelImageQr()}>
                                    Hủy bỏ
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default observer(ModalImageQr)