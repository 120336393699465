import { observer } from "mobx-react"
import { Button } from "react-bootstrap"
import { useState } from "react";
import { Collapse } from "react-bootstrap"
import { posStore } from "../PosStore";

const LocationFilterTab = ()=>{
    const  [filterOpen, setFilterOpen] = useState(true);
    return(<>
    
        <div className="filter-tab">
            <Button className="filter-collapse-btn" onClick={() => setFilterOpen(!filterOpen)}
                    aria-controls="example-collapse-text" aria-expanded={filterOpen}>
                <span>Địa điểm</span>
                <i className="fas fa-chevron-down"></i>
            </Button>
            {posStore.loadingListStore ? (<></>):
            (<Collapse in={filterOpen}>
                <div className="filter-tab__body">
                    {posStore.listStore.map((item:any, index:number)=>(
                        <div className="filter-tab__option" key={index}>
                            <input checked={posStore.locationFilterValue === item.id} value={item.id} type="radio" name="location_id" id={item.id} onClick={()=>{posStore.handleCheckOnLocationOption(item.id)}}/>
                            <label className="radio-button" htmlFor={item.id}>
                                {item.name}
                            </label>
                        </div>
                    ))}
                </div>
            </Collapse>)}
        </div>
    </>)
}
export default observer(LocationFilterTab)
