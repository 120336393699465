import React, { ReactNode } from 'react';
import Sidebar from '../sidebar/Sidebar';
import "../../styles/LayoutSettings.scss"
interface LayoutSettingsProps {
  children: ReactNode;
}

const LayoutSettings: React.FC<LayoutSettingsProps> = ({ children }) => {
  return (
    <div className='LayoutSettings'>
      <div className='box-sidebar'>
        <Sidebar />
       </div>
       <div className='box-body'>
        {children}
       </div>
    </div>
  );
};

export default LayoutSettings;
