import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {inventoryStore} from "./InventoryStore";
import Loading from "../../../shared/components/loading/Loading";
import {createSearchParams, useNavigate} from "react-router-dom";
import FilterCategoryProduct from "./filterTab/FilterCategoryProduct";
import "./styles/inventory.css";
import FilterBrand from "./filterTab/FilterBrand";
import FilterProductType from "./filterTab/FilterProductType";
import {Button, Input, Space} from "antd";
import ReactPaginate from "react-paginate";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import NoContent from "../../../shared/components/NoContent";

const Inventory = () => {
    const navigate = useNavigate();
    function onSubmitSearch(event: any) {
        const searchKeyword = inventoryStore.staticParamVariant.search;
        event.preventDefault();
        const params: any = {};
        if (searchKeyword !== "") {
            params.keyword = `${searchKeyword}`;
        }
        navigate({
            search: createSearchParams(params).toString(),
        });
        inventoryStore.getAllVariant();
    }

    function onHandleKeyDownSearch(event: any) {
        if (event.key === "Enter") {
            onSubmitSearch(event);
        }
    }


    // function handlePageClick(data: { selected: number }) {
    //     const selectedPage = data.selected;
    //     const param: any = {};
    //     if (inventoryStore.staticParamVariant.search !== "") {
    //         param.keyword = `search=${inventoryStore.staticParamVariant.search}`;
    //     }
    //     param.page = selectedPage + 1;
    //     navigate({
    //         search: createSearchParams(param).toString(),
    //     });
    //     inventoryStore.staticParamVariant.page = selectedPage;
    //     inventoryStore.getAllVariant();
    // }
    const handleChangePage = (e: any) => {
        inventoryStore.staticParamVariant.page = e.selected;
        inventoryStore.getAllVariant();
    };

    useEffect(() => {
        inventoryStore.getLocation().then();
        inventoryStore.getAllVariant().then()
    }, []);

    return (
        <>
            <div className="container">
                <div className="mainContentInventory">
                    <div className="filter">
                        <h1>Tồn kho</h1>
                        <div className="filter__wrapper">
                            <FilterCategoryProduct/>
                            <FilterBrand/>
                            <FilterProductType/>
                        </div>
                    </div>
                    <div className="content-page">
                        <div className="content-page-headers">
                            <div className="content-page-header">
                                <div className="dropdown">
                                    <img src="/assets/icon/store.svg" alt="Store"/>
                                    <div className="dropdown__location">
                                        <SelectCustom
                                            list={inventoryStore.listLocation.filter((item: any) => item.status === 1)}
                                            placeholder={'Chọn địa điểm'}
                                            onChange={(value: any) => {
                                                inventoryStore.staticDataVariant.location_id = value;
                                                inventoryStore.staticDataVariant.store_id = value;
                                                inventoryStore.getAllVariant(true);
                                            }}
                                            selected={() =>
                                                inventoryStore.listLocation.find(
                                                    (item: any) => item.id === inventoryStore.staticDataVariant.location_id
                                                )?.name
                                            }
                                            getLabel={(item: any) => {
                                                return item.name;
                                            }}
                                        />
                                    </div>
                                </div>
                                <form className="header__search" onSubmit={onSubmitSearch}>
                                    <input
                                        id="search"
                                        type="text"
                                        onKeyDown={(event: any) => {
                                            onHandleKeyDownSearch(event);
                                        }}
                                        value={
                                            inventoryStore.staticParamVariant.search
                                                ? inventoryStore.staticParamVariant.search
                                                : ""
                                        }
                                        onChange={(event) =>
                                            (inventoryStore.staticParamVariant.search =
                                                event.target.value)
                                        }
                                        placeholder="Nhập tên sản phẩm"
                                    />
                                    <button type="submit">
                                        <img src="/assets/icon/search.svg" alt=""/>
                                    </button>
                                </form>
                                {/*<Button*/}
                                {/*    icon={<CloseOutlined/>}*/}
                                {/*    style={{ width: '150px', height: '44px' }}*/}
                                {/*    danger*/}
                                {/*    disabled={!inventoryStore.isFiltering}*/}
                                {/*    onClick={() => inventoryStore.handleRemoveFilter()}*/}
                                {/*>*/}
                                {/*    Xoá bộ lọc*/}
                                {/*</Button>*/}
                            </div>
                        </div>
                        <div className={`content-table`}>
                            <div className="table-responsive">
                                <table className="table table-striped" id="table-2">
                                    <thead>
                                    <tr>
                                        <th style={{width: "18%", textAlign: "left",}}>Sản phẩm</th>
                                        <th style={{width: "15%", textAlign: "left",}}>Hình ảnh</th>
                                        <th style={{width: "10%", textAlign: "left",}}>Loại</th>
                                        <th style={{width: "10%", textAlign: "left"}}>Biến thể</th>
                                        <th style={{width: "12%", textAlign: "left"}}>Sku</th>
                                        <th style={{width: "7%", textAlign: "left"}}>Tồn kho</th>
                                        <th style={{width: "15%", textAlign: "left"}}>Hành động</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {inventoryStore.isLoading ? (
                                        <tr>
                                            <td colSpan={7}>
                                                <Loading/>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {inventoryStore.listVariant.length === 0 ? (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <NoContent />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {inventoryStore.listVariant.map(
                                                        (item: any, index: number) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {inventoryStore.listVariant !==
                                                                    undefined ? (
                                                                        <>
                                                                            {item.title}
                                                                        </>
                                                                    ) : (
                                                                        <>(Sản phẩm chưa có tên)</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {inventoryStore.listVariant !== undefined ? (
                                                                        <>
                                                                            <img className="product-thumbnail"
                                                                                 src={item.thumbnail}
                                                                                 alt="product-img"/>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <img
                                                                                className="product-thumbnail"
                                                                                src="/assets/images/no-image.svg"
                                                                                alt="no-image"
                                                                            />
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {inventoryStore.listVariant !== undefined ? (
                                                                        <>
                                                                            {item.is_variant ===
                                                                            0 ? (
                                                                                <>Đơn giản</>
                                                                            ) : (
                                                                                <>Biến thể</>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {inventoryStore.listVariant !== undefined ? (
                                                                        <>
                                                                            {item.is_variant === 0 ? (
                                                                                <>(Không có)</>
                                                                            ) : (
                                                                                <>
                                                                                    {item.attribute_compare ===
                                                                                    "" ? (
                                                                                        <>Sản phẩm chưa được phân loại
                                                                                            biến thể</>
                                                                                    ) : (
                                                                                        <>{item.attribute_compare}</>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {inventoryStore.listVariant !==
                                                                    undefined ? (
                                                                        <>
                                                                            {item.sku === "" ? (
                                                                                <>(Sản phẩm chưa có SKU)</>
                                                                            ) : (
                                                                                <>{item.sku}</>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {Number(item.quantity_inventory) === 0 ? (
                                                                        <span style={{color: "#ffc107"}}>
                                                                        {item.quantity_inventory}
                                                                    </span>
                                                                    ) : (
                                                                        <span style={{color: "#58C35A"}}>
                                                                        {item.quantity_inventory}
                                                                    </span>
                                                                    )}{" "}
                                                                    {inventoryStore.inventoryAmountList[index] === Number(item.quantity_inventory) ? (
                                                                        <></>
                                                                    ) : (
                                                                        <>
                                                                            {inventoryStore.inventoryAmountList[index] < Number(item.quantity_inventory
                                                                            ) ? (
                                                                                <>
                                                                                    <i
                                                                                        className="fa fa-arrow-down"
                                                                                        style={{
                                                                                            color: "rgb(254, 81, 57)",
                                                                                            padding: "0px 5px"
                                                                                        }}
                                                                                    ></i>
                                                                                    <span
                                                                                        style={{color: "rgb(254, 81, 57)",}}
                                                                                    >
                                                                                    {" "}
                                                                                        ({inventoryStore.inventoryAmountList[index] - Number(item.quantity_inventory)})
                                                                                </span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <i
                                                                                        className="fa fa-arrow-up"
                                                                                        style={{
                                                                                            color: "#58C35A",
                                                                                            padding: "0px 5px",
                                                                                        }}
                                                                                    ></i>
                                                                                    <span style={{color: "#58C35A"}}>
                                                                                    (+
                                                                                        {inventoryStore.inventoryAmountList[index]
                                                                                            -
                                                                                            Number(item.quantity_inventory)}
                                                                                        )
                                                                                </span>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div className="action__container">
                                                                        <Space.Compact>
                                                                            <Button onClick={() => {
                                                                                inventoryStore.hanldeMinusingBtn(index);
                                                                            }}>
                                                                                -
                                                                            </Button>
                                                                            <Input
                                                                                type="number"
                                                                                value={inventoryStore.inventoryAmountList[index]}
                                                                                onChange={(event) => {
                                                                                    inventoryStore.handleInputAmount(event.target.value, index);
                                                                                }}
                                                                            />
                                                                            <Button onClick={() => {
                                                                                inventoryStore.handleAddingBtn(index)
                                                                            }}>
                                                                                +
                                                                            </Button>
                                                                        </Space.Compact>
                                                                        <Button
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                inventoryStore.handleSaveAmount(
                                                                                    item.id,
                                                                                    inventoryStore.inventoryAmountList[index]
                                                                                );
                                                                            }}
                                                                        >Lưu
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                        ,)}
                                                </>
                                            )}
                                        </>
                                    )}
                                    </tbody>
                                </table>
                                {inventoryStore.isLoading
                                    ? ""
                                    : Math.ceil(inventoryStore.metadata.total_page) > 1 && (
                                    <div className="pagination__container">
                                        {!inventoryStore.isLoading &&
                                            inventoryStore.listVariant.length > 0 &&
                                            inventoryStore.metadata.total_page > 1 && (
                                                <ReactPaginate
                                                    previousLabel={
                                                        <img src="/assets/icon/fi-rr-angle-small-left.svg" alt="right"/>
                                                    }
                                                    nextLabel={
                                                        <img src="/assets/icon/fi-rr-angle-small-right.svg"
                                                             alt="right"/>
                                                    }
                                                    breakLabel={"..."}
                                                    pageCount={Math.ceil(inventoryStore.metadata.total_page)}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handleChangePage}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    forcePage={inventoryStore.staticParamVariant.page}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default observer(Inventory);
