import { observer } from "mobx-react";
import { Modal, Form, Input } from "antd";
import UploadFile from "../../../../shared/components/form/UploadFile";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import { labelstore } from "../LabelStore";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toastUtils } from "../../../../common/utils/Toastutils";

const { TextArea } = Input;

const CreateLabel = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const params: any = {};
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        labelstore.staticParamsLabel.page = 0;
        labelstore
            .fetchCreate()
            .then((response: any) => {
                form.resetFields();
            })
            .catch((error: any) => {
                console.error("Có lỗi xảy ra:", error);
            });
    };

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Tạo mới nhãn"
                open={labelstore.isModalOpen}
                onOk={labelstore.handeOk}
                onCancel={() => {
                    labelstore.handleCancel();
                }}
                afterClose={() => {
                    labelstore.handleClose();
                }}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={labelstore.staticDataLabel}
                >
                    <div className="item-form">
                        <label className="label-form">
                            Tên nhãn <span>*</span>
                        </label>

                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống tên nhãn",
                                },
                                {
                                    max: 150,
                                    message:
                                        "Tên nhãn quá dài, tối đa 150 kí tự",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập tên nhãn"
                                type="text"
                                value={labelstore.staticDataLabel.name}
                                maxLength={150}
                                onChange={(e) => {
                                    if (e.target.value.length > 149) {
                                        toastUtils.warning(
                                            "Tên nhãn quá dài, tối đa 150 ký tự",
                                            ""
                                        );
                                        return;
                                    }
                                    labelstore.staticDataLabel.name =
                                        e.target.value.trim();
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label-form">Mô tả ngắn</label>

                        <Form.Item name="description">
                            <TextArea
                                rows={4}
                                maxLength={1000}
                                placeholder="Mô tả ngắn"
                                onChange={(e) => {
                                    labelstore.staticDataLabel.description =
                                        e.target.value.trim();
                                }}
                                style={{
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label className="label-form">Trạng thái</label>
                        <SelectStatus
                            onChange={(value: any) => {
                                labelstore.staticDataLabel.status = value;
                            }}
                            selected={() => labelstore.staticDataLabel.status}
                            textOn="Hoạt động"
                            textOff="Vô hiệu hóa"
                        />
                    </div>

                    <UploadFile
                        description={`Hình ảnh nhãn`}
                        file={labelstore.staticDataLabel.image}
                        onIconRemove={labelstore.handleCloseIcon}
                        onFileChange={(e) => {
                            handleFile(e, labelstore.changeFileImage);
                        }}
                    />

                    <div className="btnCreateModal">
                        <button
                            className="cancelModal"
                            type="reset"
                            onClick={() => labelstore.handleCancel()}
                        >
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            disabled={labelstore.isLoadingBtn}
                        >
                            {labelstore.isLoadingBtn
                                ? "Vui lòng đợi..."
                                : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(CreateLabel);
