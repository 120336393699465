import React, { useEffect } from "react";
import "./BoxFilterDiningTables.scss";
import { observer } from "mobx-react";
import { diningTablesStore } from "../../DiningTableStore";
import { useLocation, useNavigate } from "react-router-dom";

const BoxFilterDiningTables = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    diningTablesStore.dataFilterDiningTables.search =
      searchParams.get("search") || "";
  }, [location.search]);

  const updateUrlParams = (isSearchTriggered = false) => {
    const searchParams = new URLSearchParams();

    if (diningTablesStore.dataFilterDiningTables.search) {
      searchParams.set(
        "search",
        diningTablesStore.dataFilterDiningTables.search
      );
    }

    diningTablesStore.staticParamDiningTable.page = 0;

    navigate({ search: searchParams.toString() }, { replace: true });

    if (isSearchTriggered) {
      diningTablesStore.fetchAllDiningTablesList();
    }
  };

  const handleSearch = () => {
    updateUrlParams(true); 
  };

  const handleRemoveFilters = () => {
    diningTablesStore.dataFilterDiningTables.search = "";
    diningTablesStore.dataFilterDiningTables.store_id = null;
    diningTablesStore.dataFilterDiningTables.floor_room_id = null;
    diningTablesStore.storeIdFilter = null
    navigate({ search: "" }, { replace: true });

    diningTablesStore.fetchAllDiningTablesList();
    diningTablesStore.fetchAllfloorRoom("filter")
  };

  return (
    <div className="BoxFilterDiningTables">
      <form
        className="filter-search"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <input
          type="text"
          name="keyword"
          value={diningTablesStore.dataFilterDiningTables.search}
          id="keyword"
          onChange={(e: any) => {
            diningTablesStore.dataFilterDiningTables.search =
              e.currentTarget.value;
          }}
          placeholder="Nhập tên bàn"
        />
        <button type="submit">
          <img src="/assets/icon/search.svg" alt="search" />
        </button>
      </form>

      {(diningTablesStore.dataFilterDiningTables.search ||
        diningTablesStore.dataFilterDiningTables.store_id ||
        diningTablesStore.dataFilterDiningTables.floor_room_id) && (
        <div className="remove-filter" onClick={handleRemoveFilters}>
          <span>Xóa Lọc</span>
          <img src="/assets/icon/icon_close.svg" width={14} alt="delete" />
        </div>
      )}
    </div>
  );
};

export default observer(BoxFilterDiningTables);
