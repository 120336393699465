import { makeAutoObservable, toJS } from "mobx";
import { paymentSettingsService } from "./PaymentSettingsService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

class PaymentSettingsStore {
  dataPayment: any[] = [];
  dataListBank: any[] = [];

  isLoadingMomo: boolean = false;
  isLoadingVnpay: boolean = false;
  isLoadingPos: boolean = false;
  isLoading: boolean = false;

  idPayment: any;

  dataFormMomo: any = {
    partner_code: "",
    access_key: "",
    secret_key: "",
    status: null,
  };

  dataFormVnpay: any = {
    vnp_TmnCode: "",
    vnp_HashSecret: "",
    status: null,
  };

  dataFormPOS: any = {
    bankId: null,
    bank_number: "",
    name: "",
    bank_code: "",
    status: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  async getListPayment() {
    
    if (this.dataPayment.length < 1 ) {
      this.isLoading = true;
    }
    const result = await paymentSettingsService.fetchListPayment();
    this.isLoading = false
    if (result.status === HttpStatusCode.Ok) {
      this.dataPayment = result.body;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async getListBank() {
    const result = await paymentSettingsService.fetchListBank();
    if (result.status === HttpStatusCode.Ok) {
      this.dataListBank = result.data.data;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async updatePayment(type: "Momo" | "VNpay" | "POS") {
    if (type === "Momo") {
      const dataFormat = {
        status: this.dataFormMomo.status,
        data: {
          partner_code: this.dataFormMomo.partner_code,
          access_key: this.dataFormMomo.access_key,
          secret_key: this.dataFormMomo.secret_key,
        },
      };
      this.isLoadingMomo = true;
      const result = await paymentSettingsService.updatePayment(
        this.idPayment,
        dataFormat
      );
      this.isLoadingMomo = false;

      if (result.status === HttpStatusCode.Ok) {
        const updatedArrPayment = this.dataPayment.map((item) =>
          item.id === this.idPayment
            ? {
                ...item,
                ...result.body,
              }
            : item
        );
        this.dataPayment = updatedArrPayment;

        toastUtils.success("Cập nhật thành công!");
      } else {
        toastUtils.error("Đã có lỗi xảy ra", "");
      }

      return;
    } else if (type === "VNpay") {
      const dataFormat = {
        status: this.dataFormVnpay.status,
        data: {
          vnp_TmnCode: this.dataFormVnpay.vnp_TmnCode,
          vnp_HashSecret: this.dataFormVnpay.vnp_HashSecret,
        },
      };

      this.isLoadingVnpay = true;

      const result = await paymentSettingsService.updatePayment(
        this.idPayment,
        dataFormat
      );
      this.isLoadingVnpay = false;

      if (result.status === HttpStatusCode.Ok) {
        const updatedArrPayment = this.dataPayment.map((item) =>
          item.id === this.idPayment
            ? {
                ...item,
                ...result.body,
              }
            : item
        );
        this.dataPayment = updatedArrPayment;

        toastUtils.success("Cập nhật thành công!");
      } else {
        toastUtils.error("Đã có lỗi xảy ra", "");
      }
      return;
    } else if (type === "POS") {
      const dataFormat = {
        status: this.dataFormPOS.status,
        data: {
          bankId: this.dataFormPOS.bankId,
          bank_number: this.dataFormPOS.bank_number,
          name: this.dataFormPOS.name,
          bank_code: this.dataFormPOS.bank_code,
          status: this.dataFormPOS.status,
        },
      };

      this.isLoadingPos = true;

      const result = await paymentSettingsService.updatePayment(
        this.idPayment,
        dataFormat
      );

      this.isLoadingPos = false;

      if (result.status === HttpStatusCode.Ok) {
        const updatedArrPayment = this.dataPayment.map((item) =>
          item.id === this.idPayment
            ? {
                ...item,
                ...result.body,
              }
            : item
        );
        this.dataPayment = updatedArrPayment;

        toastUtils.success("Cập nhật thành công!");
      } else {
        toastUtils.error("Đã có lỗi xảy ra", "");
      }
      return;
    } else {
      return;
    }
  }
}

export const paymentSettingsStore = new PaymentSettingsStore();
