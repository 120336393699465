import { makeAutoObservable, reaction, toJS } from "mobx";
import { storebranchService } from "./StoreBranchService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";
import StorageService from "../../common/service/StorageService";

class StoreBranchStore {
    isLoading = false;
    modalOpen = false;
    modalAddNewStaff = false;
    isModal = true;
    isLoadingBtn = false;
    isSortName: boolean | null = null;
    listStaff: any[] = [];
    listStaffRoles: any[] = [];
    listAllStaffManager: any[] = [];
    listManager: any[] = [];
    listLocation: any[] = [];
    selectedStoreBranchInTable: any[] = [];
    listProvince: any[] = [];
    listDistrict: any[] = [];
    listWard: any[] = [];
    selectedLocation: any[] = [];
    initialItem: any[] = [];
    neededActionStoreBranchValue: string = "";
    dataSelectedStaff: any;

    constructor() {
        makeAutoObservable(this);
        this.initializeParams();

        // Theo dõi và chỉ lưu khi initialItem thay đổi
        reaction(
            () => toJS(this.initialItem[0]), //Phần muốn theo dõi sự thay đổi
            () => {
                this.saveInitialItemToLocal();
            }
        );
    }
    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamsLocation.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        this.staticParamsLocation.status = query.get("status")
            ? String(query.get("status"))
            : "";
        this.staticParamsLocation.search = query.get("search")
            ? String(query.get("search"))
            : "";
    }

    staticParamsStaff: {
        order: string;
        paginate: string;
        status: string;
    } = {
        order: "",
        paginate: "1000",
        status: "",
    };

    staticParamsLocation: {
        image: string;
        order: string;
        paginate: string;
        status: string;
        page: number;
        search: string;
    } = {
        image: "",
        order: "",
        paginate: "10",
        status: "",
        page: 0,
        search: "",
    };

    staticLocation: {
        id: number;
        name: string;
        email: string;
        provinceId: string;
        phone_number: string;
        districtId: string;
        wardId: string;
        address: string;
        manager: string;
        lat: string | null;
        long: string | null;
        status: string;
        thumbnail: string;
        isShippingLocation: string | null;
        is_default: number | null;
        staff: any;
        members: any;
    } = {
        id: 0,
        thumbnail: "",
        name: "",
        email: "",
        provinceId: "",
        phone_number: "",
        districtId: "",
        wardId: "",
        address: "",
        manager: "",
        lat: "",
        long: "",
        status: "1",
        isShippingLocation: "",
        is_default: 0,
        staff: [],
        members: null,
    };

    staticStaff: {
        id: string;
        name: string;
        role_id: number;
        wallet: string | null;
        meta: string | null;
        permissions: string;
        password: string;
        password_confirmation: string;
        phone: string;
        status: string;
        email: string;
        email_verified_at: string;
        roles: any[];
        type: string | null;
        avatar: string | null;
    } = {
        id: "",
        name: "",
        role_id: 0,
        wallet: "",
        meta: "",
        permissions: "",
        phone: "",
        status: "",
        email: "",
        email_verified_at: "",
        roles: [],
        type: "2",
        avatar: "",
        password: "",
        password_confirmation: "",
    };

    inittialValue: {
        id: number;
        name: string;
        email: string;
        provinceId: string;
        phone_number: string;
        districtId: string;
        wardId: string;
        address: string;
        manager: string;
        lat: string | null;
        long: string | null;
        status: string | null;
        thumbnail: string;
        isShippingLocation: string | null;
        is_default: number | null;
    } = {
        id: 0,
        thumbnail: "",
        name: "",
        email: "",
        provinceId: "",
        phone_number: "",
        districtId: "",
        wardId: "",
        address: "",
        manager: "",
        lat: "",
        long: "",
        status: "",
        is_default: 0,
        isShippingLocation: "",
    };

    metadata: {
        current_page: number;
        per_page: number;
        total: number;
        last_page: number;
        next_page_url: null;
        prev_page_url: null;
        from: number;
        to: number;
        has_more_pages: boolean;
    } = {
        current_page: 1,
        per_page: 0,
        total: 1,
        last_page: 1,
        next_page_url: null,
        prev_page_url: null,
        from: 1,
        to: 1,
        has_more_pages: false,
    };

    //Lưu dữ liệu chi tiết của cửa hàng
    setInitialItem(dataItem: any) {
        if (Array.isArray(dataItem?.members)) {
            dataItem.members = dataItem.members.map((member: any) => member.id);
        }
        this.initialItem = [dataItem];
        this.saveInitialItemToLocal();
    }
    //Lưu item từ store vào local
    saveInitialItemToLocal() {
        StorageService.saveArayLS("initialItem", this.initialItem);
    }
    loadInitialItemFromLocal() {
        const data = StorageService.getLocalStore("initialItem");
        if (data) {
            this.initialItem = JSON.parse(data);
        }
    }

    setModal = (modal: boolean) => {
        this.isModal = modal;
    };
    setIsModalOpen = (isOpen: boolean) => {
        this.modalOpen = isOpen;
    };
    setIsAddNewStaff = (isOpen: boolean) => {
        this.modalAddNewStaff = isOpen;
    };
    showModal = () => {
        this.setIsModalOpen(true);
    };
    handleCancel = () => {
        this.setIsModalOpen(false);
        this.setIsAddNewStaff(false);
    };
    handleClose = () => {
        this.setIsModalOpen(false);
    };
    handleOK = () => {
        this.setIsAddNewStaff(false);
    };
    showModalAddNewStaff = () => {
        this.setIsAddNewStaff(true);
    };
    handleCloseAddNewStaff = () => {
        this.setIsAddNewStaff(false);
    };
    handleCancelAddNewStaff = () => {
        this.setIsModalOpen(false);
    };
    changeFileImage = (pathFile: string) => {
        this.staticLocation.thumbnail = pathFile;
        this.initialItem[0].thumbnail = pathFile;
    };
    handleCloseIcon = () => {
        this.staticLocation.thumbnail = "";
        this.inittialValue.thumbnail = "";
    };
    changeFileImageStaff = (pathFile: string) => {
        this.staticStaff.avatar = pathFile;
    };
    handleCloseIconStaff = () => {
        this.staticStaff.avatar = "";
    };
    handleSortOrder = () => {
        if (this.isSortName === null || this.isSortName === false) {
            this.isSortName = true;
            this.staticParamsLocation.order = "DESC";
        } else if (this.isSortName === true) {
            this.isSortName = false;
            this.staticParamsLocation.order = "ASC";
        }
    };
    handleClearStaticLocation = () => {
        this.staticLocation.name = "";
        this.staticLocation.address = "";
        this.staticLocation.lat = "";
        this.staticLocation.long = "";
        this.staticLocation.email = "";
        this.staticLocation.phone_number = "";
        this.staticLocation.districtId = "";
        this.staticLocation.wardId = "";
        this.staticLocation.provinceId = "";
        this.staticLocation.status = "1";
        this.staticLocation.isShippingLocation = "";
        this.staticLocation.is_default = 0;
    };
    handleClearStaffModal = () => {
        this.staticStaff.name = "";
        this.staticStaff.email = "";
        this.staticStaff.password = "";
        this.staticStaff.password_confirmation = "";
        this.staticStaff.avatar = "";
        this.staticStaff.type = "2";
        this.staticStaff.roles = [];
    };
    handleSetInittialValue = (item: any) => {
        this.inittialValue = {
            id: item.id,
            name: item.name,
            address: item.address,
            phone_number: item.phone_number,
            email: item.email,
            thumbnail: item.thumbnail,
            provinceId: item.provinceId,
            districtId: item.districtId,
            wardId: item.wardId,
            lat: item.lat,
            long: item.long,
            status: item.status,
            isShippingLocation: item.isShippingLocation,
            is_default: item.is_default,
            manager: item.manager,
        };
    };
    handleClearInittialValue = () => {
        this.inittialValue.name = "";
        this.inittialValue.email = "";
        this.inittialValue.thumbnail = "";
        this.inittialValue.phone_number = "";
        this.inittialValue.provinceId = "";
        this.inittialValue.wardId = "";
        this.inittialValue.districtId = "";
        this.inittialValue.address = "";
        this.inittialValue.manager = "";
        this.inittialValue.status = "";
        this.inittialValue.is_default = 0;
        this.inittialValue.isShippingLocation = "";
    };

    setDefaultLocation(id: number) {
        this.listLocation.map((locations) => {
            if (locations.id === id) {
                return { ...locations, is_default: 1 };
            }
            return { ...locations, is_default: 0 };
        });
    }

    async fetchStaffRoles() {
        const result = await storebranchService.fetchStoreBranchStaffRoles();
        if (result.status === HttpStatusCode.Ok) {
            this.listStaffRoles = result.body.data;
        }
    }
    async fetchStaff() {
        let { order, paginate, status } = this.staticParamsStaff;
        const params = {
            order: order,
            paginate: paginate,
            status: status,
        };
        const result = await storebranchService.fetchStoreBranchStaff(params);
        if (result.status === HttpStatusCode.Ok) {
            //Lọc loại bỏ superadmin ra
            if (
                result.body.data.filter((item: any) => item.type === 1)
                    .length !== this.listStaff.length
            ) {
                this.listManager = result.body.data.filter(
                    (item: any) => item.type === 1
                );
            }
            this.listStaff = result.body.data.filter(
                (item: any) => item.type === 2
            );
            this.listAllStaffManager = result.body.data;
        } else {
            toastUtils.error("Lỗi nhân viên cửa hàng", "");
        }
    }
    async fetchCreateStaff(callback?: () => void): Promise<void> {
        this.isLoadingBtn = true;
        let {
            name,
            email,
            password,
            phone,
            status,
            roles,
            type,
            avatar,
            password_confirmation,
        } = this.staticStaff;
        const params = {
            name: name,
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            phone: phone,
            status: status,
            roles: roles,
            type: type,
            avatar: avatar,
        };
        const result = await storebranchService.fetchStoreBranchCreateStaff(
            params
        );
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            this.fetchStaff();
            toastUtils.success("Thêm thành công", "");
            this.handleOK();
            this.handleCloseIconStaff();
            this.staticStaff.type = "2";
            if (callback) {
                callback();
            }
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.error("Mật khẩu nhập lại hoặc email chưa đúng", "");
        }
    }

    async fetchLocation(run?: boolean) {
        let { order, status, paginate, page, search } =
            this.staticParamsLocation;
        const params = {
            order: order,
            status: status,
            paginate: paginate,
            page: page + 1,
            search: search,
        };

        if (this.listLocation.length < 1 || run) {
            this.isLoading = true;
        }

        const result = await storebranchService.fetchStoreBranchLocation(
            params
        );
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.data.length !== this.listLocation ||
                JSON.stringify(result.body.data) !==
                    JSON.stringify(this.listLocation)
            ) {
                this.metadata = result.body.pagination;
                this.listLocation = result.body.data;
            }
        }
        this.isLoading = false;
    }
    async fetchCreateLocation(): Promise<void> {
        this.isLoadingBtn = true;
        let {
            name,
            email,
            provinceId,
            phone_number,
            districtId,
            wardId,
            address,
            manager,
            lat,
            long,
            status,
            thumbnail,
            is_default,
            members,
        } = this.staticLocation;
        const params = {
            name: name,
            email: email,
            provinceId: provinceId,
            phone_number: phone_number,
            districtId: districtId,
            wardId: wardId,
            address: address,
            manager: manager,
            lat: lat,
            long: long,
            status: status,
            thumbnail: thumbnail,
            is_default: is_default,
            members: members,
        };

        const result = await storebranchService.fetchStoreBranchLocationCreate(
            params
        );
        if (result.status === HttpStatusCode.Ok) {
            this.handleCloseIcon();
            this.handleClearStaticLocation();
            toastUtils.success("Tạo địa chỉ thành công", "");
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.warning("Địng dạng điện thoại hoặc email chưa đúng", "");
        } else if (result.status === HttpStatusCode.InternalServerError) {
            toastUtils.error(
                "Số lượng cửa hàng đã đạt tới giới hạn của gói",
                ""
            );
        }
        this.isLoadingBtn = false;
    }
    async fetchDeleteLocation() {
        const result = await storebranchService.fetchStoreBranchLocationDelete(
            this.staticLocation.id
        );
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Xóa thành công", "");

            this.listLocation = this.listLocation.filter(
                (item: any) => item.id !== this.staticLocation.id
            );

            if (this.listLocation.length === 0) {
                this.staticParamsLocation.page = 0;
                this.fetchLocation();
            }
        }
    }
    async fetchUpdateLocationInMainPage(): Promise<void> {
        const {
            name,
            email,
            phone_number,
            provinceId,
            districtId,
            wardId,
            address,
            manager,
            lat,
            long,
            status,
            thumbnail,
            isShippingLocation,
            is_default,
            members,
        } = this.initialItem[0];
        const queryParams = {
            name: name,
            email: email,
            phone_number: phone_number,
            provinceId: provinceId,
            districtId: districtId,
            wardId: wardId,
            address: address,
            manager: manager,
            lat: lat,
            long: long,
            status: status,
            thumbnail: thumbnail,
            isShippingLocation: isShippingLocation,
            is_default: is_default,
            members: members.map((item: any) => item.id),
        };

        const result = await storebranchService.fetchStoreBranchLocationUpdate(
            this.initialItem[0].id,
            queryParams
        );
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Cập nhật thành công", "");
        } else if (result.status === HttpStatusCode.InternalServerError) {
            toastUtils.warning("Vui lòng nâng cấp để tạo thêm", "");
        }
        this.isLoadingBtn = false;
    }
    async fetchUpdateLocationInModalPage(): Promise<void> {
        this.isLoadingBtn = true;
        const {
            name,
            email,
            phone_number,
            provinceId,
            districtId,
            wardId,
            address,
            manager,
            lat,
            long,
            status,
            thumbnail,
            isShippingLocation,
            is_default,
            members,
        } = this.initialItem[0];
        const queryParams = {
            name: name,
            email: email,
            phone_number: phone_number,
            provinceId: provinceId,
            districtId: districtId,
            wardId: wardId,
            address: address,
            manager: manager,
            lat: lat,
            long: long,
            status: status,
            thumbnail: thumbnail,
            isShippingLocation: isShippingLocation,
            is_default: is_default,
            members: members,
        };

        const result = await storebranchService.fetchStoreBranchLocationUpdate(
            this.initialItem[0].id,
            queryParams
        );
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Cập nhật thành công", "");
        } else if (result.status === HttpStatusCode.InternalServerError) {
            toastUtils.warning("Vui lòng nâng cấp để tạo thêm", "");
        }
        this.isLoadingBtn = false;
    }

    async handleSwitchStoreBranch(item: any, status: boolean) {
        this.inittialValue.id = item.id;
        this.inittialValue.status = status === true ? "1" : "0";
        this.neededActionStoreBranchValue = "";
        return this.fetchUpdateLocationInMainPage();
    }
    async handleStoreBranchActionFilter() {
        const neededActionLocationList = this.listLocation.filter((item: any) =>
            this.selectedLocation.includes(item.id)
        );
        if (neededActionLocationList.length === 0) {
            toastUtils.warning("Vui lòng chọn chi nhánh", "");
            return;
        }

        switch (this.neededActionStoreBranchValue) {
            case "active":
            case "deactive":
                await Promise.all(
                    neededActionLocationList.map((item) =>
                        this.handleSwitchStoreBranch(
                            item,
                            this.neededActionStoreBranchValue === "active"
                        )
                    )
                );
                await this.fetchLocation();
                break;
            case "delete":
                await Promise.all(
                    neededActionLocationList.map((item) => {
                        this.staticLocation.id = item.id;
                        return this.fetchDeleteLocation();
                    })
                );
                break;
            default:
                toastUtils.error("Vui lòng chọn hành động!", "");
                return;
        }
        this.neededActionStoreBranchValue = "";
    }

    async fetchProvince() {
        const result = await storebranchService.fetchProvinceId();
        if (result.status === HttpStatusCode.Ok) {
            this.listProvince = result.body;
        }
    }
    async fetchDistrict(provinceID: string) {
        const result = await storebranchService.fetchdistrictId(provinceID);
        if (result.status === HttpStatusCode.Ok) {
            this.listDistrict = Object.values(result.body);
        }
    }
    async fetchWard(districtID: string) {
        const result = await storebranchService.fetchWardId(districtID);
        if (result.status === HttpStatusCode.Ok) {
            this.listWard = Object.values(result.body);
        }
    }

    async handleSwitchDefaultLocationPos(
        item: any,
        status: boolean
    ): Promise<void> {
        this.initialItem = [
            {
                id: item.id,
                name: item.name,
                email: item.email,
                phone_number: item.phone_number,
                address: item.address,
                provinceId: item.provinceId,
                districtId: item.districtId,
                wardId: item.wardId,
                status: item.status.toString(),
                lat: item.lat,
                long: item.long,
                thumbnail: item.thumbnail,
                isShippingLocation: item.isShippingLocation,
                is_default: status ? 1 : 0,
                manager: item.manager,
                members: item.members,
            },
        ];

        const updateData = this.listLocation.map((data: any) => {
            return {
                ...data,
                is_default: data.id === item.id ? 1 : 0,
            };
        });

        this.listLocation = updateData;
    }
}
export const storebranchstore = new StoreBranchStore();
