import "./styles/storebranchstyle.scss";
import { observer } from "mobx-react";
import { storebranchstore } from "./StoreBranchStore";
import { Dropdown } from "react-bootstrap";
import Loading from "../../shared/components/loading/Loading";
import NoContent from "../../shared/components/NoContent";
import StatusFilter from "./components/StatusFilter";
import { Switch } from "antd";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { showDelete } from "../../shared/components/showDelete";
import ReactPaginate from "react-paginate";
import { toastUtils } from "../../common/utils/Toastutils";
import StorageService from "../../common/service/StorageService";

const StoreBranchPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [clearFilter, setClearFilter] = useState(false);

    useEffect(() => {
        storebranchstore.fetchLocation();
        storebranchstore.setModal(true);
        storebranchstore.fetchStaff();
    }, []);

    const handleDelete = (item: any) => {
        storebranchstore.staticLocation.id = item.id;
        showDelete.showDeleteConfirm(
            "Xác nhận xóa",
            "Bạn có chắc muốn xóa mục này",
            async () => {
                await storebranchstore.fetchDeleteLocation();
            }
        );
    };

    const handleChangePage = (e: any) => {
        storebranchstore.staticParamsLocation.page = e.selected;
        const searchParams = new URLSearchParams(location.search);

        searchParams.set(
            "page",
            (storebranchstore.staticParamsLocation.page + 1).toString()
        );
        navigate({
            search: searchParams.toString(),
        });
        storebranchstore.fetchLocation();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleUpdate = (item: any) => {
        StorageService.setLocalStore("isModal", false);
        storebranchstore.setInitialItem(item);
        storebranchstore.showModal();
        localStorage.setItem("modalName", "Cập nhập chi nhánh");
        storebranchstore.setModal(false);
        navigate(`/chi-nhanh-cua-hang/${item.id}/sua`);
    };

    // function handleSelect(value: boolean, name: string) {
    //     if (value) {
    //         storebranchstore.selectedLocation = [
    //             ...storebranchstore.selectedLocation,
    //             name,
    //         ];
    //     } else {
    //         storebranchstore.selectedLocation =
    //             storebranchstore.selectedLocation.filter(
    //                 (item) => item !== name
    //             );
    //     }
    // }
    // function selectAllLocations(value: boolean) {
    //     if (value) {
    //         storebranchstore.selectedLocation =
    //             storebranchstore.listLocation.map((item: any) => item.id);
    //     } else {
    //         storebranchstore.selectedLocation = [];
    //     }
    // }

    const handleSearchForm = (e: React.FormEvent) => {
        e.preventDefault();
        handleSearch();
        storebranchstore.fetchLocation();
    };

    const handleSearch = () => {
        updateUrlParams(true);
    };

    const handleSelectUpdateUrl = (isSelect: boolean) => {
        updateUrlParams(isSelect);
    };

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();

        const params = {
            search: storebranchstore.staticParamsLocation.search,
            page: storebranchstore.staticParamsLocation.page.toString(),
            status: storebranchstore.staticParamsLocation.status,
        };

        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({ search: searchParams.toString() }, { replace: true });

        if (isSearch) {
            storebranchstore.fetchLocation();
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        storebranchstore.staticParamsLocation.search =
            searchParams.get("search") || "";
        storebranchstore.staticParamsLocation.page = page || 0;
    }, [location.search]);

    console.log("check page", storebranchstore.listManager);

    return (
        <>
            <div className="container">
                <div
                    className="mainContentStoreBranch"
                    style={{
                        background: "none",
                    }}
                >
                    <div className="header">
                        <div className="header__title">Chi nhánh cửa hàng</div>
                        <form
                            className="header__search"
                            onSubmit={handleSearchForm}
                        >
                            <input
                                id="search"
                                type="text"
                                placeholder="Nhập tên cửa hàng"
                                onChange={(e) => {
                                    storebranchstore.staticParamsLocation.search =
                                        e.target.value;
                                }}
                                value={
                                    storebranchstore.staticParamsLocation
                                        .search || ""
                                }
                            />
                            <button type="submit">
                                <img src="/assets/icon/search.svg" alt="" />
                            </button>
                        </form>
                        <button
                            className="btn-clear-filter"
                            onClick={() => {
                                storebranchstore.staticParamsLocation.status =
                                    "";
                                storebranchstore.staticParamsLocation.search =
                                    "";
                                storebranchstore.staticParamsLocation.page = 0;
                                navigate({
                                    search: createSearchParams({}).toString(),
                                });
                                storebranchstore.fetchLocation();
                                setClearFilter(true);
                                //Clear lại trạng thái filter để sử dụng tiếp
                                setTimeout(() => setClearFilter(false), 0.001);
                            }}
                        >
                            <span>Xóa lọc</span>
                        </button>
                        <button
                            className="btn-add-modal"
                            onClick={() => {
                                if (storebranchstore.metadata.total === 10) {
                                    toastUtils.error(
                                        "Vượt quá số lượng cửa hàng, vui lòng nâng cấp để sử dụng",
                                        ""
                                    );
                                } else {
                                    storebranchstore.showModal();
                                    StorageService.setLocalStore(
                                        "modalName",
                                        "Thêm mới chi nhánh"
                                    );
                                    StorageService.setLocalStore(
                                        "isModal",
                                        true
                                    );
                                    storebranchstore.isModal = true;
                                    navigate(`/chi-nhanh-cua-hang/them-moi`);
                                }
                            }}
                            style={{
                                paddingLeft: storebranchstore.metadata.total
                                    ? "15px"
                                    : "12px",
                                paddingRight: storebranchstore.metadata.total
                                    ? "15px"
                                    : "12px",
                            }}
                        >
                            <span
                                style={{
                                    paddingLeft:
                                        storebranchstore.metadata.total === 10
                                            ? "0"
                                            : "26px",
                                }}
                            >
                                {storebranchstore.metadata.total === 10 ? (
                                    <i
                                        className="fas fa-lock"
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    ></i>
                                ) : (
                                    <img
                                        src="/assets/icon/plus.svg"
                                        alt="sorting"
                                    />
                                )}
                                Thêm mới
                            </span>
                        </button>
                    </div>
                    <div className="body-page">
                        <div className="filter-tab-wrapper">
                            <StatusFilter clearFilter={clearFilter} />
                        </div>
                        <div className="content-table">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "30%" }}>
                                                Tên cửa hàng
                                                <span
                                                    style={{
                                                        paddingLeft: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        storebranchstore.handleSortOrder();
                                                        storebranchstore.fetchLocation();
                                                    }}
                                                >
                                                    <img
                                                        src="/assets/icon/sorting.svg"
                                                        alt=""
                                                        style={{
                                                            width: "14px",
                                                        }}
                                                    />
                                                </span>
                                            </th>
                                            <th style={{ width: "10%" }}>
                                                Địa điểm mặc định
                                            </th>
                                            <th
                                                style={{
                                                    width: "28%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Nhân viên
                                            </th>
                                            <th
                                                style={{
                                                    width: "20%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Trạng thái
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Thao tác
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {storebranchstore.isLoading ? (
                                            <tr>
                                                <td colSpan={6}>
                                                    <Loading />
                                                </td>
                                            </tr>
                                        ) : storebranchstore.listLocation
                                              .length === 0 ? (
                                            <tr>
                                                <td colSpan={6}>
                                                    <NoContent />
                                                </td>
                                            </tr>
                                        ) : (
                                            storebranchstore.listLocation.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="item d-flex align-items-center">
                                                                    <div className="image">
                                                                        {item.thumbnail !==
                                                                        null ? (
                                                                            <img
                                                                                src={
                                                                                    item.thumbnail
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="/assets/icon/store.svg"
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </div>

                                                                    <div className="info d-flex flex-column align-items-start">
                                                                        <p className="mb-0">
                                                                            <strong>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                        <span>
                                                                            {
                                                                                item.address
                                                                            }
                                                                        </span>
                                                                        <ul className="d-flex align-items-center">
                                                                            <li className="d-flex align-items-center gap-1">
                                                                                <img
                                                                                    src="/assets/icon/phoneCustomer.svg"
                                                                                    alt="img-default"
                                                                                />
                                                                                {
                                                                                    item.phone_number
                                                                                }
                                                                            </li>
                                                                            <li className="d-flex align-items-center gap-1">
                                                                                <img
                                                                                    src="/assets/icon/emaiCustomer.svg"
                                                                                    alt="img-default"
                                                                                />
                                                                                {
                                                                                    item.email
                                                                                }
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <Switch
                                                                    size="small"
                                                                    checked={
                                                                        item.is_default ===
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    disabled={
                                                                        item.is_default ===
                                                                        1
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        storebranchstore
                                                                            .handleSwitchDefaultLocationPos(
                                                                                item,
                                                                                e
                                                                            )
                                                                            .then(
                                                                                () => {
                                                                                    storebranchstore.fetchUpdateLocationInMainPage();
                                                                                }
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    console.error(
                                                                                        "Có lỗi xảy ra:",
                                                                                        error.message
                                                                                    );
                                                                                }
                                                                            );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div className="imageStaff">
                                                                    {storebranchstore.listAllStaffManager
                                                                        .filter(
                                                                            (
                                                                                staff: any
                                                                            ) =>
                                                                                staff.id ===
                                                                                item.manager
                                                                        )
                                                                        .map(
                                                                            (
                                                                                staff: any
                                                                            ) => (
                                                                                <div
                                                                                    className="avatar"
                                                                                    key={
                                                                                        staff.id
                                                                                    }
                                                                                >
                                                                                    <Tooltip
                                                                                        title={`${staff.name}`}
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                staff.avatar ||
                                                                                                "/assets/icon/icon_user.svg"
                                                                                            }
                                                                                            alt={
                                                                                                staff.name
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    {item.members
                                                                        .slice(
                                                                            0,
                                                                            1
                                                                        )
                                                                        .map(
                                                                            (
                                                                                member: any,
                                                                                index: number
                                                                            ) => {
                                                                                return (
                                                                                    <div className="avatar">
                                                                                        <Tooltip
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            title={`${member.name}`}
                                                                                        >
                                                                                            <img
                                                                                                src={
                                                                                                    member.avatar ||
                                                                                                    "/assets/icon/icon_user.svg"
                                                                                                }
                                                                                                alt={
                                                                                                    member.name
                                                                                                }
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    {item
                                                                        .members
                                                                        .length >
                                                                    1 ? (
                                                                        <div className="avatar">
                                                                            <Tooltip
                                                                                title={item.members
                                                                                    .slice(
                                                                                        1
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: any,
                                                                                            index: number
                                                                                        ) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                item?.avatar
                                                                                                                    ? item?.avatar
                                                                                                                    : "/assets/icon/icon_user.svg"
                                                                                                            }
                                                                                                            alt=""
                                                                                                            style={{
                                                                                                                width: "20px",
                                                                                                                height: "20px",
                                                                                                                borderRadius:
                                                                                                                    "50%",
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                            >
                                                                                <div className="hiddenmember d-flex align-items-center justify-content-center">
                                                                                    <span>
                                                                                        {
                                                                                            "+"
                                                                                        }
                                                                                        {
                                                                                            item.members?.slice(
                                                                                                1
                                                                                            )
                                                                                                .length
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {item.status ===
                                                                1 ? (
                                                                    <span className="success-status">
                                                                        Hoạt
                                                                        động
                                                                    </span>
                                                                ) : (
                                                                    <span className="disable-status">
                                                                        Vô hiệu
                                                                        hóa
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <Dropdown className="action-table">
                                                                    <Dropdown.Toggle
                                                                        variant="primary"
                                                                        id="dropdown-basic1"
                                                                    >
                                                                        Thao tác
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            href="javascript:void
                                                                            (0)"
                                                                            onClick={() => {
                                                                                handleUpdate(
                                                                                    item
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                            Sửa
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            href="javascript:void(0)"
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    item
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                item.is_default ===
                                                                                1
                                                                            }
                                                                            style={{
                                                                                opacity:
                                                                                    item.is_default ===
                                                                                    1
                                                                                        ? "0.4"
                                                                                        : "1",
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                            Xóa
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        )}
                                    </tbody>
                                </table>
                                {storebranchstore.metadata.last_page > 1 ? (
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-left.svg"
                                                alt="right"
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-right.svg"
                                                alt="right"
                                            />
                                        }
                                        breakLabel={"..."}
                                        pageCount={
                                            storebranchstore.metadata.last_page
                                        }
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        onPageChange={handleChangePage}
                                        forcePage={
                                            // storebranchstore
                                            //     .staticParamsLocation.page > 1
                                            //     ? storebranchstore
                                            //           .staticParamsLocation
                                            //           .page - 1
                                            //     : 0
                                            storebranchstore
                                                .staticParamsLocation.page
                                        }
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(StoreBranchPage);
