import { makeAutoObservable } from "mobx";
import { detailMachinePosService } from "./DetailMachinePosService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

interface StaticParamDetailMachinePos {
    offset: number | null;
    page: number;
    per_page: number;
}

export interface ImetaData{
    total?: number | null,
    page?: number,
    total_page?: number | null,
    size?: number | null,
    pos_name?: string,
}

interface IdataFilterDetailMachinePos{
    search?: string,
    status?:number | string | null,
    user_id?: number | string | null,
    start_date?: string | null,
    end_date?: string | null
}

interface IdataStatus{
    open?: number,
    close?: number
}

class DetailMachinePosStore {

    isLoading: boolean = false;
    dataDetailsMachinePos : any[] = []
    dataAllStaff: any[] = []

    staticDetailMachinePos: StaticParamDetailMachinePos = {
        offset: 0,
        page: 1,
        per_page: 10,
    };

    dataFilterDetailMachinePos: IdataFilterDetailMachinePos = {
        search: "",
        status: null,
        user_id: null,
        start_date: null,
        end_date: null
    }

    metadata: ImetaData = {
        total: null,
        page: 1,
        total_page: null,
        size: null,
        pos_name: ""
    };

    dataStatus: IdataStatus = {
        open: 1,
        close: 0
    }

    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }

    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.dataFilterDetailMachinePos.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticDetailMachinePos.page = query.get("page")
            ? Number(query.get("page")) - 1 
            : 0;
        this.dataFilterDetailMachinePos.user_id = query.get("user_id")
            ? Number(query.get("user_id"))
            : null;
        this.dataFilterDetailMachinePos.status = query.get("status")
            ? Number(query.get("status"))
            : null;
        this.dataFilterDetailMachinePos.start_date = query.get("start_date")
            ? query.get("start_date")
            : null;   
        this.dataFilterDetailMachinePos.end_date = query.get("end_date")
            ? query.get("end_date")
            : null;    
    }


    async fetchAllDetailsSessionMachine(idMachinePos: any) {
        let { offset, page, per_page} = this.staticDetailMachinePos;
        let {search,
            status,
            user_id,
            start_date,
            end_date } = this.dataFilterDetailMachinePos;

        const convertStatus = status === 1 ? 0 : status === 2 ? 1 : null
        const paramsTagProduct = {
            offset: offset,
            page: page + 1,
            per_page: per_page,
            search: search,
            status: convertStatus,
            user_id: user_id,
            start_date: start_date,
            end_date: end_date
        };
        this.isLoading = true;
        const result = await detailMachinePosService.fetchDetailsMachinePos(idMachinePos, paramsTagProduct);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataDetailsMachinePos = result.body.data;
            this.metadata = result.body.metadata;
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async fetchAllStaffList () {
        const result = await detailMachinePosService.fetchAllStaff();

        if (result.status === HttpStatusCode.Ok) {
            this.dataAllStaff = result.body.data;
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

}

export const detailMachinePosStore = new DetailMachinePosStore();