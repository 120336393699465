import {makeAutoObservable} from "mobx";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {permissionsService} from "./PermissionsService";
interface StaticParam {
    page_number: number | 0;
    per_page: number;
    search: string | undefined;
}
class PermissionsStore {
    isLoading: boolean = false
    isSave: boolean = false
    searchTemp: string = '';
    dataListRole: any[] = []
    dataListRoleDetail: any[] = []
    dataListPermission: any[] = []
    dataDetailPermissionRole: any
    dataDetailListPermissionRole: any[] = []
    openModalAddEditStaff: boolean = false;
    metadata: any = {
        total: null,
        page: 1,
        total_page: null,
        size: null,
    };
    staticDataStore: {
        name: string;
        permissions: [];

    } = {
        name: '',
        permissions: [],

    };

    staticDataStoreEdit: {
        name: string;
        permissions: any;

    } = {
        name: '',
        permissions: [],

    };
    constructor() {

        makeAutoObservable(this)
    }
    staticParam: StaticParam = {
        page_number: 0,
        per_page: 20,
        search: "",
    };
    async fetchListRole(run?:boolean) {
        if(this.dataListRole.length === 0 || run){
            this.isLoading = true
        }
        const params = {
            page_number: this.staticParam.page_number,
            per_page: this.staticParam.per_page,
            search: this.staticParam.search
        }
        const result = await permissionsService.fetchListRole(params);
        this.isLoading = false
        if (result.status === HttpStatusCode.Ok) {
            this.dataListRole = result.body.data;
            this.metadata = {
                total: result.body.metadata.total,
                page: result.body.metadata.page,
                total_page: result.body.metadata.total_page,
                size: result.body.metadata.size,
            };
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchListPermissions() {
        this.dataListRoleDetail = []
        const result = await permissionsService.fetchListPermissions();
        if (result.status === HttpStatusCode.Ok) {
            this.dataListPermission = result.body;
            result.body.map((item:any) => {
                item.permissions.map((itemChild:any) => {
                    this.dataListRoleDetail.push(itemChild)
                })
            });
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchPermissionByRole(id:any,navigate: (route: string) => void) {
        const result = await permissionsService.fetchPermissionByRole(id);
        this.isLoading = false
        if (result.status === HttpStatusCode.Ok) {
            this.dataDetailPermissionRole = result.body
            result.body.permissions.map((item:any) => {
                item.permissions.map((itemChild:any) => {
                    this.dataDetailListPermissionRole.push(itemChild.id)
                })
            })
        } else {
            toastUtils.error("Không tồn tại nhóm quyền này", "");
            navigate(`/cai-dat/quyen`)
        }

    }
    async fetchStoreRole(form: any) {
        if(this.isSave === false){
            this.isSave = true
            const params = {
                name: this.staticDataStore.name,
                permissions: this.staticDataStore.permissions,
            }
            const result = await permissionsService.fetchStoreRole(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Thêm mới thành công", "");
                form.resetFields();
                permissionsStore.openModalAddEditStaff = false;
                this.dataListRole.unshift(result.body); // Thêm data mới vào đầu mảng
                if (this.staticParam.per_page < this.dataListRole.length){
                    this.dataListRole.pop(); // Xóa phần tử cuối cùng ra khỏi mảng
                }
            } else {
                if(result.body.message.exception === 'role_name_already_exist'){
                    toastUtils.warning("Tên quyền đã tồn tại");
                }else{
                    toastUtils.error("Đã có lỗi xảy ra", "");
                }
            }
        }
    }
    async fetchUpdateRole(id: any) {
        if(this.isSave === false){
            this.isSave = true
            const params = {
                name: this.staticDataStoreEdit.name,
                permissions: this.staticDataStoreEdit.permissions,
            }
            const result = await permissionsService.fetchUpdateRole(params,id);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Lưu thành công", "");
            } else {
                if(result.body.message.name === 'the_name_has_already_been_taken'){
                    toastUtils.warning("Tên quyền đã tồn tại");
                }else{
                    toastUtils.error("Đã có lỗi xảy ra", "");
                }
            }
        }
    }
    async fetchDeleteRole(idRole:number) {
        const result = await permissionsService.fetchDeleteRole(idRole);
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Xóa thành công", "");
            this.dataListRole = this.dataListRole.filter((item:any) => item.id !== idRole)
            if(this.dataListRole.length === 0){
                this.staticParam.page_number = 1
                this.fetchListRole(true);
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
}
export const permissionsStore = new PermissionsStore()