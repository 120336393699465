import React from "react";
import { observer } from "mobx-react";
import {Form, Input, InputNumber} from "antd";
import {productStore} from "../../ProductStore";
import {checkPhoneNumber, handleKeyPress} from "../../../../../common/utils/Utils";
const PriceAndSku = () => {
    return (
        <>
            <div className="session-create">
                <div className="session-create-left">
                    <div className="session-create-left-content">
                        <p className="title">Giá và mã sản phẩm</p>
                        <p className="description">Nhập giá sản phẩm và các thông tin cần thiết</p>
                    </div>
                </div>
                <div className="session-create-right">
                    <div className="session-create-content">
                        <div className="grid-2 gap-20">
                            {/*<div className="item-form">*/}
                            {/*    <Form.Item name="price_origin" label="Giá bán"*/}
                            {/*               rules={[{required: true, message: 'Không được để trống!'}]}>*/}
                            {/*        <InputNumber*/}
                            {/*            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                            {/*            parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}*/}
                            {/*            onChange={(value) => {*/}
                            {/*                productStore.staticDataProduct.price_origin = `${value}`;*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </Form.Item>*/}
                            {/*</div>*/}
                            {/*<div className="item-form">*/}
                            {/*    <Form.Item name="price_compare_origin" label="Giá gốc"*/}
                            {/*               rules={[{required: true, message: 'Không được để trống!'}]}>*/}
                            {/*        <InputNumber*/}
                            {/*            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                            {/*            parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}*/}
                            {/*            onChange={(value) => {*/}
                            {/*                productStore.staticDataProduct.price_compare_origin = `${value}`;*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </Form.Item>*/}
                            {/*</div>*/}
                            <div className="item-form">
                                <Form.Item
                                    name="price_origin"
                                    label="Giá bán"
                                    rules={[
                                        {required: true, message: 'Không được để trống!'},
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                const priceCompareOrigin = getFieldValue('price_compare_origin');
                                                if (value && priceCompareOrigin && value > priceCompareOrigin) {
                                                    return Promise.reject(new Error('Giá bán không được lớn hơn giá gốc!'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        onKeyPress={handleKeyPress}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                        onChange={(value) => {
                                            productStore.staticDataProduct.price_origin = `${value}`;
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="price_compare_origin"

                                    label="Giá gốc"
                                    rules={[
                                        {required: true, message: 'Không được để trống!'},
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                const priceOrigin = getFieldValue('price_origin');
                                                if (value && priceOrigin && priceOrigin > value) {
                                                    return Promise.reject(new Error('Giá gốc không được nhỏ hơn giá bán!'));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        onKeyPress={handleKeyPress}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                        onChange={(value) => {
                                            productStore.staticDataProduct.price_compare_origin = `${value}`;
                                        }}
                                    />
                                </Form.Item>
                            </div>

                        </div>
                        <div className="grid-2 gap-20">
                            <div className="item-form">
                                <Form.Item name="sku_origin" label="Mã SKU"
                                           rules={[{required: true, message: 'Không được để trống!'},{min: 4, message: "Sku phải từ 4 ký tự trở lên"}]}>
                                    <Input onChange={(event) => {
                                        productStore.staticDataProduct.sku_origin = event.target.value;
                                    }}/>
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item name="unit" label="Đơn vị tính"
                                           rules={[{required: true, message: 'Không được để trống!'}]}>
                                    <Input onChange={(event) => {
                                        productStore.staticDataProduct.unit = event.target.value;
                                    }}/>
                                </Form.Item>

                            </div>
                        </div>
                        <div className="grid-2 gap-20">
                            <div className="item-form">
                                <Form.Item name="weight_origin" label="Cân nặng (gram)"
                                           rules={[{required: true, message: 'Không được để trống!'}]}>
                                    <Input
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const filteredValue = value.replace(/[^0-9]/g, '');
                                            productStore.staticDataProduct.weight_origin = parseInt(filteredValue);
                                        }}
                                        onKeyPress={handleKeyPress}
                                        type="number"
                                        placeholder=""
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item name="height_origin" label="Chiều cao"
                                           rules={[{required: true, message: 'Không được để trống!'}]}>
                                    <Input onChange={(event) => {
                                        productStore.staticDataProduct.height_origin = parseInt(event.target.value);
                                    }}
                                        type="number"
                                        onKeyPress={handleKeyPress}
                                        placeholder=""
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="grid-2 gap-20">
                            <div className="item-form">
                                <Form.Item name="length_origin" label="Chiều dài (cm)" rules={[{required: true, message: 'Không được để trống!'}]}>
                                    <Input
                                        onChange={(event) => {
                                            productStore.staticDataProduct.length_origin = parseInt(event.target.value);
                                        }}
                                        type="number"
                                        onKeyPress={handleKeyPress}
                                        placeholder=""
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item name="width_origin" label="Chiều rộng (cm)" rules={[{required: true, message: 'Không được để trống!'}]}>
                                    <Input
                                        onChange={(event) => {
                                            productStore.staticDataProduct.width_origin = parseInt(event.target.value);
                                        }}
                                        type="number"
                                        onKeyPress={handleKeyPress}
                                        placeholder=""
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(PriceAndSku);
