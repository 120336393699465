import { useNavigate, useParams } from "react-router-dom";
import { detailOrderPosStore } from "./DetailOrderPosStore";
import { Col, Row } from "antd";
import DetailOrderPosPage from "./DetailOrderPosPage";
import { useEffect } from "react";
import { utils } from "../../../common/utils/Utils";
import { observer } from "mobx-react";

const ViewDetailOrderPosPage = () => {
    const { id, idViewData } = useParams<{ id: string; idViewData: string }>();
    const navigate = useNavigate();
    useEffect(() => {
        detailOrderPosStore.getStaff();
        detailOrderPosStore.detaiOrderPos();
        detailOrderPosStore.staticParamsOrderPos.per_page = 10000;
        // detailOrderPosStore.getViewDetailOrderPos().then(() => {
        //     console.log(detailOrderPosStore.staticOrderPos);
        // });
        return () => {
            detailOrderPosStore.staticParamsOrderPos.per_page = 10;
        };
    }, []);

    useEffect(() => {
        if (idViewData) {
            detailOrderPosStore.staticOrderPos.idViewDetail = idViewData;
            detailOrderPosStore.getViewDetailOrderPos(); // Gọi API lấy chi tiết đơn hàng
        }
    }, [idViewData]);

    const totalAmount =
        detailOrderPosStore.listViewDetailOrderPos[0]?.transaction_pos_item.reduce(
            (acc: any, curr: any) => {
                return acc + curr.amount;
            },
            0
        );

    console.log(
        "check store getview",
        detailOrderPosStore.listViewDetailOrderPos
    );

    return (
        <>
            <div className="ViewDetailOrderPosContainer">
                <p className="title">POS</p>
                <Row>
                    <Col className="leftViewDetail" span={14}>
                        <DetailOrderPosPage />
                    </Col>
                    <Col className="rightViewDetail" span={10}>
                        {detailOrderPosStore.isLoading ? (
                            <p>Đang tải...</p>
                        ) : (
                            detailOrderPosStore.listViewDetailOrderPos[0]?.transaction_pos_item.map(
                                (item: any, index: number) => {
                                    return (
                                        <>
                                            <div
                                                className="itemViewOrderPos d-flex align-items-center justify-content-between"
                                                key={index}
                                            >
                                                <div className="leftItem">
                                                    <p className="name mb-0">
                                                        {
                                                            item.product_info
                                                                ?.name
                                                        }
                                                        {" - "}
                                                        {
                                                            item.product_info
                                                                ?.attribute_compare
                                                        }
                                                    </p>
                                                    <p className="detail">
                                                        <span className="countItem">
                                                            {item.qty}{" "}
                                                        </span>
                                                        {
                                                            item.product_info
                                                                ?.unit
                                                        }
                                                        {" x "}
                                                        <span className="priceItem">
                                                            {utils.formatCurrency(
                                                                item.amount
                                                            )}
                                                        </span>
                                                        {"/"}
                                                        {
                                                            item.product_info
                                                                ?.unit
                                                        }
                                                    </p>
                                                </div>
                                                <div className="rightItem">
                                                    <span className="price">
                                                        {utils.formatCurrency(
                                                            item.amount
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                            )
                        )}
                    </Col>
                </Row>
            </div>
            <p className="totalAmount mb-0">
                <span className="VAT">(Giá đã bao gồm VAT)</span>{" "}
                <span className="total">
                    Tổng: {utils.formatCurrency(totalAmount)}
                </span>
            </p>
        </>
    );
};

export default observer(ViewDetailOrderPosPage);
