import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { productStore } from "../ProductStore";
import { Link, useParams } from "react-router-dom";
import "../createProduct/styles/create_product.css";
import { Button, Form, Input, InputNumber, Space } from "antd";
import EditProductMenu from "./components/EditProductMenu";
import TextareaEditor from "../../../../shared/components/form/TextareaEditor";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import SelectMultipleAnt from "../../../../shared/components/form/SelectMultipleAnt";
import { dashboardStore } from "../../../dashboard/DashboardStore";
import { categoryProductStore } from "../../categoryProduct/CategoryProductStore";
import SelectStatusNumber from "../../../../shared/components/form/SelectStatusNumber";
const { TextArea } = Input;
const EditProductGeneralPage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "general";
    useEffect(() => {
        productStore.fetchGetGeneralProduct().then();
        productStore.fetchListFeature().then();
        productStore.fetchListCategory().then();
        productStore.fetchListBrand().then();
        productStore.fetchListTags().then();
        dashboardStore.fetchDataStore().then();
    }, []);
    // console.log(productStore.dataProductGeneral)
    useEffect(() => {
        form.setFieldsValue({
            title: productStore.dataProductGeneral?.title,
            slug: productStore.dataProductGeneral?.slug,
            excerpt: productStore.dataProductGeneral?.excerpt?.value,
            categories: productStore.dataProductGeneral?.categories_news,
            tags: productStore.dataProductGeneral?.tags_news,
            status: productStore.dataProductGeneral?.status,
            show_price: productStore.dataProductGeneral?.show_price,
            brand_id: productStore.dataProductGeneral?.brand_id,
            feature_id: productStore.dataProductGeneral?.feature_id,
            long_description: productStore.dataProductGeneral?.long_description,
        });
    }, [productStore.dataProductGeneral, form]);

    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            productStore.fetchUpdateGeneralProduct(id,values)
                        }}
                        style={{ maxWidth: 600 }}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="general"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="item-form">
                                        <Form.Item
                                            name="title"
                                            label="Tên sản phẩm"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                                {max: 255, message: 'Tên sản phẩm không được quá 255 ký tự'}
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="slug"
                                            label="Slug"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                                {max: 255, message: 'Slug không được quá 500 ký tự'}
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="item-form item-form-textarea">
                                        <Form.Item
                                            name="excerpt"
                                            label="Mô tả ngắn"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                                {max: 500, message: 'Mô tả không được quá 500 ký tự'}
                                            ]}
                                        >
                                            <TextArea rows={4}/>
                                        </Form.Item>
                                    </div>
                                    <Form.Item name="long_description">
                                        <TextareaEditor content={productStore.dataProductGeneral?.long_description}
                                                        onChange={(value: any) => productStore.dataProductGeneral.long_description = value}
                                                        textLabel={`Mô tả chi tiết`}/>
                                    </Form.Item>
                                    <div className="item-form">
                                        <Form.Item name="categories" label="Danh mục sản phẩm"
                                                   rules={[{required: true, message: 'Không được để trống!'}]}>
                                            <SelectMultipleAnt
                                                list={productStore.listCategory}
                                                defaultValue={productStore.dataProductGeneral?.categories_news}
                                                onChange={(value: any) => {
                                                    productStore.dataProductGeneral.categories_news = value;
                                                }}
                                                placeholder={`-- Chọn --`}
                                                getLabel={(item: any) => item.name}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item name="brand_id" label="Thương hiệu"
                                                   rules={[{required: true, message: 'Không được để trống!'}]}>
                                            <SelectCustom
                                                list={productStore.listBrand}
                                                placeholder={`-- Chọn --`}
                                                onChange={(value: any) => productStore.dataProductGeneral.brand_id = value}
                                                selected={() => {
                                                    const brandId = productStore.dataProductGeneral?.brand_id;
                                                    return brandId || undefined;
                                                }}
                                                getLabel={(item: any) => item.name}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item name="tags" label="Thẻ sản phẩm">
                                            <SelectMultipleAnt
                                                list={productStore.listTags}
                                                defaultValue={productStore.dataProductGeneral?.tags_news}
                                                onChange={(value: any) => productStore.dataProductGeneral.tags_news = value}
                                                placeholder={`-- Chọn --`}
                                                getLabel={(item: any) => item.name}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item name="feature_id" label="Chọn loại nổi bật"
                                                   rules={[{required: true, message: 'Không được để trống!'}]}>
                                            <SelectCustom
                                                list={productStore.listFeature}
                                                placeholder={`-- Chọn --`}
                                                onChange={(value: any) => productStore.dataProductGeneral.feature_id = value}
                                                selected={() => {
                                                    const featureId = productStore.dataProductGeneral?.feature_id;
                                                    return featureId || undefined;
                                                }}
                                                getLabel={(item: any) => item.name}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="grid-2 gap-20">
                                        <div className="item-form">
                                            <Form.Item name="status" label="Trạng thái">
                                                <SelectStatusNumber selected={() => productStore.dataProductGeneral?.status}
                                                              onChange={(value: any) => productStore.dataProductGeneral.status = value}/>
                                            </Form.Item>
                                        </div>
                                        <div className="item-form">
                                            <label htmlFor="show_price">Hiện giá: (Tắt hiện giá sản phẩm sẽ hiện
                                                là: <span>Liên hệ</span>)</label>
                                            <Form.Item name="show_price">
                                                <SelectStatusNumber textOn={'Hiện'} textOff={'Ẩn'} selected={() => productStore.dataProductGeneral?.show_price}
                                                              onChange={(value: any) => productStore.dataProductGeneral.show_price = value}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Hoàn tất
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
export default observer(EditProductGeneralPage);
