import { observer } from "mobx-react";
import dayjs from "dayjs";
import viVN from "antd/es/locale/vi_VN";
import { ConfigProvider } from "antd";
import "dayjs/locale/vi"; // Locale cho Day.js
import SelectCustom from "../../../shared/components/form/SelectCustom";
import BoxFilterSessionDetails from "../components/BoxFilterSessionDetails";
import LayoutSessionDetails from "../components/LayoutSessionDetails";
import "./styles/DetailOrderPos.scss";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { useEffect, useState } from "react";
import { detailOrderPosStore } from "./DetailOrderPosStore";
import {
    createSearchParams,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import { utils } from "../../../common/utils/Utils";
import ReactPaginate from "react-paginate";
import { storebranchstore } from "../../storebranch/StoreBranchStore";
const { RangePicker } = DatePicker;

const DetailOrderPosPage = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const startDate = dayjs().subtract(3, "month").format("DD-MM-YYYY");
    const endDate = dayjs().endOf("day").format("DD-MM-YYYY");

    detailOrderPosStore.staticOrderPos.id = `${id}`;
    const navigate = useNavigate();

    useEffect(() => {
        detailOrderPosStore.staticParamsOrderPos.start_date = startDate;
        detailOrderPosStore.staticParamsOrderPos.end_date = endDate;
        detailOrderPosStore.getStaff();
        detailOrderPosStore.detaiOrderPos();
    }, []);

    // Tính toán giá trị tháng đầu tiên của 3 tháng trước
    const getDefaultPickerValue = () => {
        const start = dayjs().subtract(3, "month");
        const end = dayjs().endOf("day"); // Ngày hiện tại

        return [start, end];
    };

    const handleChangeDate: RangePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        const start: string = dateString[0];
        const end: string = dateString[1];
        detailOrderPosStore.staticParamsOrderPos.start_date = start;
        detailOrderPosStore.staticParamsOrderPos.end_date = end;
        handleSelectChange(true);
        detailOrderPosStore.detaiOrderPos();
    };

    const disabledDate = (current: any) => {
        // Lấy ngày hôm nay và ngày 3 tháng trước
        const today = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);

        // Disable các ngày sau hôm nay và trước 3 tháng trước
        return current && (current > today || current < threeMonthsAgo);
    };

    const paymentStatus = [
        {
            id: "1",
            value: "Thành công",
            label: "Thành công",
            name: "Thành công",
        },
        { id: "2", value: "Nợ", label: "Nợ", name: "Nợ" },
    ];

    const orderType = [
        {
            id: "1",
            value: "Đơn thường",
            label: "Đơn thường",
            name: "Đơn thường",
        },
        {
            id: "2",
            value: "Hoàn tiền",
            label: "Hoàn tiền",
            name: "Hoàn tiền",
        },
        {
            id: "3",
            value: "Công nợ",
            label: "Công nợ",
            name: "Công nợ",
        },
    ];

    const handleChangePage = (value: any) => {
        detailOrderPosStore.staticParamsOrderPos.page_number = value.selected;
        detailOrderPosStore.detaiOrderPos();
    };

    //Sắp xếp lại danh sách, đưa mục click lên đầu
    const handleViewClick = (item: any) => {
        detailOrderPosStore.isSelectedItem = item; // Cập nhật mục được chọn
    };
    const sortedList = detailOrderPosStore.isSelectedItem
        ? [
              detailOrderPosStore.isSelectedItem,
              ...detailOrderPosStore.listOrderPos.filter(
                  (item: any) =>
                      item.id !== detailOrderPosStore.isSelectedItem.id
              ),
          ]
        : detailOrderPosStore.listOrderPos;

    const handleSearch = () => {
        updateUrlParams(true);
    };

    const handleSelectChange = (isSearch = true) => {
        updateUrlParams(isSearch);
    };

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();

        const params = {
            search: detailOrderPosStore.staticParamsOrderPos.search,
            start_date: detailOrderPosStore.staticParamsOrderPos.start_date,
            end_date: detailOrderPosStore.staticParamsOrderPos.end_date,
            user_id: detailOrderPosStore.staticParamsOrderPos.user_id,
            order_type: detailOrderPosStore.staticParamsOrderPos.order_type,
            payment_status:
                detailOrderPosStore.staticParamsOrderPos.payment_status,
        };

        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({ search: searchParams.toString() }, { replace: true });

        if (isSearch) {
            storebranchstore.fetchLocation();
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        detailOrderPosStore.staticParamsOrderPos.search =
            searchParams.get("search") || "";
        detailOrderPosStore.staticParamsOrderPos.start_date =
            searchParams.get("start_date") || startDate;
        detailOrderPosStore.staticParamsOrderPos.end_date =
            searchParams.get("end_date") || endDate;
        detailOrderPosStore.staticParamsOrderPos.user_id =
            searchParams.get("user_id") || "";
        detailOrderPosStore.staticParamsOrderPos.order_type =
            searchParams.get("order_type") || "";
        detailOrderPosStore.staticParamsOrderPos.payment_status =
            searchParams.get("payment_statis") || "";
    }, [location.search]);

    return (
        <>
            <LayoutSessionDetails
                name={`Chi tiết đơn hàng ${detailOrderPosStore.metadata.pos_name}`}
                onClickBack={() => navigate("/danh-sach-pos")}
            >
                <div className="DetailOrderPosContainer">
                    <BoxFilterSessionDetails
                        handleRemoveFilter={() => {
                            if (
                                detailOrderPosStore.staticParamsOrderPos.search
                            ) {
                                detailOrderPosStore.staticParamsOrderPos.search =
                                    "";
                                navigate({
                                    search: createSearchParams({}).toString(),
                                });
                            }
                            detailOrderPosStore.clearFilter();
                            detailOrderPosStore.detaiOrderPos();
                        }}
                    >
                        <form
                            className="filter-search"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSearch();
                                detailOrderPosStore.detaiOrderPos();
                            }}
                        >
                            <input
                                type="text"
                                name="keyword"
                                id="keyword"
                                value={
                                    detailOrderPosStore.staticParamsOrderPos
                                        .search || ""
                                }
                                onChange={(e) => {
                                    detailOrderPosStore.staticParamsOrderPos.search =
                                        e.target.value;
                                }}
                                placeholder="Tìm kiếm theo tên hoặc mã đơn"
                                title="Tìm kiếm theo tên hoặc mã đơn"
                            />
                            <button type="submit">
                                <img
                                    src="/assets/icon/search.svg"
                                    alt="search"
                                />
                            </button>
                        </form>

                        <div className="item-selected d-flex align-items-center">
                            <ConfigProvider locale={viVN}>
                                <RangePicker
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    format="DD-MM-YYYY"
                                    defaultValue={
                                        getDefaultPickerValue() as [
                                            dayjs.Dayjs,
                                            dayjs.Dayjs
                                        ]
                                    }
                                    value={[
                                        detailOrderPosStore.staticParamsOrderPos
                                            .start_date
                                            ? dayjs(
                                                  detailOrderPosStore
                                                      .staticParamsOrderPos
                                                      .start_date,
                                                  "DD-MM-YYYY"
                                              )
                                            : null,
                                        detailOrderPosStore.staticParamsOrderPos
                                            .end_date
                                            ? dayjs(
                                                  detailOrderPosStore
                                                      .staticParamsOrderPos
                                                      .end_date,
                                                  "DD-MM-YYYY"
                                              )
                                            : null,
                                    ]}
                                    disabledDate={disabledDate}
                                    onChange={handleChangeDate}
                                />
                            </ConfigProvider>
                        </div>

                        <div className="item-selected">
                            <SelectCustom
                                list={detailOrderPosStore.listStaff}
                                placeholder="Nhân Viên"
                                showSearch
                                allowClear
                                onChange={(value: any) => {
                                    detailOrderPosStore.staticParamsOrderPos.user_id =
                                        value;
                                    handleSelectChange(true);
                                    detailOrderPosStore.detaiOrderPos();
                                }}
                                selected={() =>
                                    detailOrderPosStore.listStaff.find(
                                        (item: any) =>
                                            item.id.toString() ===
                                            detailOrderPosStore
                                                .staticParamsOrderPos.user_id
                                    )?.name
                                }
                                getLabel={(item: any) => item.name}
                            />
                        </div>

                        <div className="item-selected">
                            <SelectCustom
                                list={orderType}
                                placeholder="Loại đơn"
                                showSearch
                                allowClear
                                onChange={(value: any) => {
                                    console.log("check value", value);
                                    detailOrderPosStore.staticParamsOrderPos.order_type =
                                        value;
                                    handleSelectChange(true);
                                    detailOrderPosStore.detaiOrderPos();
                                }}
                                selected={() =>
                                    orderType.find(
                                        (item: any) =>
                                            item.id ===
                                            detailOrderPosStore
                                                .staticParamsOrderPos.order_type
                                    )?.name
                                }
                                getLabel={(item: any) => item.name}
                            />
                        </div>

                        <div className="item-selected">
                            <SelectCustom
                                list={paymentStatus}
                                placeholder="Trạng thái"
                                showSearch
                                allowClear
                                onChange={(value: any) => {
                                    detailOrderPosStore.staticParamsOrderPos.payment_status =
                                        value;
                                    handleSelectChange(true);
                                    detailOrderPosStore.detaiOrderPos();
                                }}
                                selected={() =>
                                    paymentStatus.find(
                                        (item: any) =>
                                            item.id ===
                                            detailOrderPosStore
                                                .staticParamsOrderPos
                                                .payment_status
                                    )?.name
                                }
                                getLabel={(item: any) => item.name}
                            />
                        </div>
                    </BoxFilterSessionDetails>

                    <div className="contentListPosOrder">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                width: "20%",
                                            }}
                                        >
                                            <span>Đơn hàng</span>
                                        </th>
                                        <th
                                            style={{
                                                width: "15%",
                                            }}
                                        >
                                            <span>Thanh toán</span>
                                        </th>
                                        <th style={{ width: "10%" }}>Ngày</th>
                                        <th style={{ width: "15%" }}>
                                            Nhân viên
                                        </th>
                                        <th
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            Loại đơn
                                        </th>
                                        <th
                                            style={{
                                                width: "10%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Trạng thái đơn
                                        </th>
                                        <th
                                            style={{
                                                width: "10%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {detailOrderPosStore.isLoading ? (
                                        <tr>
                                            <td colSpan={7}>
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : detailOrderPosStore.listOrderPos
                                          .length === 0 ? (
                                        <tr>
                                            <td colSpan={7}>
                                                <NoContent />
                                            </td>
                                        </tr>
                                    ) : (
                                        sortedList.map(
                                            (item: any, index: number) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                backgroundColor:
                                                                    item.id ===
                                                                    detailOrderPosStore
                                                                        .isSelectedItem
                                                                        ?.id
                                                                        ? "#f4f6f9"
                                                                        : "white",
                                                            }}
                                                        >
                                                            <td>
                                                                <p className="pl-0">
                                                                    {
                                                                        item.transaction_code
                                                                    }
                                                                </p>
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            "14px",
                                                                        color: "#BABABA",
                                                                    }}
                                                                >
                                                                    {
                                                                        item
                                                                            .customer_info
                                                                            ?.name
                                                                    }
                                                                </span>
                                                                <p>
                                                                    <strong>
                                                                        Tổng
                                                                        đơn:
                                                                    </strong>{" "}
                                                                    <span
                                                                        style={{
                                                                            color: "#00BD40",
                                                                        }}
                                                                    >
                                                                        {utils.formatCurrency(
                                                                            item.amount_total
                                                                        )}
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    style={{
                                                                        color: "#00BD40",
                                                                    }}
                                                                >
                                                                    {utils.formatCurrency(
                                                                        item.cash_amount
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {new Date(
                                                                    item.created_at
                                                                ).toLocaleDateString(
                                                                    "en-GB"
                                                                )}{" "}
                                                                {new Date(
                                                                    item.created_at
                                                                ).toLocaleTimeString(
                                                                    "en-GB"
                                                                )}
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {
                                                                        detailOrderPosStore.listStaff.find(
                                                                            (
                                                                                staff: any
                                                                            ) =>
                                                                                staff.id ===
                                                                                item.user_id
                                                                        )?.name
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {item.transaction_type ===
                                                                3 ? (
                                                                    <span className="type greenColor d-flex align-item-center justify-content-center">
                                                                        Công nợ
                                                                    </span>
                                                                ) : (
                                                                    <span className="type blueColor d-flex align-item-center justify-content-center">
                                                                        Đơn
                                                                        thường
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {item.transaction_type ===
                                                                    1 ||
                                                                item.transaction_type ===
                                                                    3 ? (
                                                                    <span className="type blueColor">
                                                                        Thành
                                                                        công
                                                                    </span>
                                                                ) : (
                                                                    <span className="type redColor">
                                                                        Nợ
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <span
                                                                    className="type btn-see-more blueColor"
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/pos/${id}/chi-tiet-don-hang/${item.transaction_code}/${item.id}`
                                                                        );
                                                                        handleViewClick(
                                                                            item
                                                                        );
                                                                        detailOrderPosStore.staticOrderPos.idViewDetail =
                                                                            item.id;
                                                                        detailOrderPosStore.staticParamsOrderPos.per_page = 1000;
                                                                        detailOrderPosStore.getViewDetailOrderPos();
                                                                    }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    Xem
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            }
                                        )
                                    )}
                                </tbody>
                            </table>
                            {detailOrderPosStore.metadata.total_page > 1 ? (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                        detailOrderPosStore.metadata.total_page
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    onPageChange={handleChangePage}
                                    forcePage={
                                        detailOrderPosStore.staticParamsOrderPos
                                            .page_number
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </LayoutSessionDetails>
        </>
    );
};

export default observer(DetailOrderPosPage);
