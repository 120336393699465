import React, { useEffect } from "react";
import "./AddEditStaff.scss";
import { Form, Input, Modal } from "antd";
import { adminStore, EType, TFormStaff } from "../../../AdminStore";
import { observer } from "mobx-react";
import SelectCustom from "../../../../../../shared/components/form/SelectCustom";
import UploadFile from "../../../../../../shared/components/form/UploadFile";
import { handleFile } from "../../../../../../common/helpers/UploadFileHelper";

const AddEditStaff = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: adminStore.dataFormStaff.name,
      id: adminStore.dataFormStaff.id,
      email: adminStore.dataFormStaff.email,
      phone: adminStore.dataFormStaff.phone,
      avatar: adminStore.dataFormStaff.avatar,
    });
  }, [form, adminStore.dataFormStaff]);

  return (
    <Modal
      title={`${
        adminStore.typeModal === "add"
          ? "Thêm nhân viên"
          : "Thay đổi thông tin nhân viên"
      }`}
      open={adminStore.openModalAddEditStaff}
      footer={null}
      onCancel={() => {
        adminStore.openModalAddEditStaff = adminStore.isLoadingBtn;
        if (adminStore.typeModal === "edit" && !adminStore.isLoadingBtn) {
          adminStore.resetFormStaff();
          form.resetFields();
        }
      }}
      wrapClassName={`modalAddEditStaff ${
        adminStore.isLoadingBtn ? "disabled-modal" : ""
      }`}
    >
      <Form
        form={form}
        onFinish={async (values: any) => {
          adminStore.dataFormStaff.name = values.name;
          adminStore.dataFormStaff.phone = values.phone;
          adminStore.dataFormStaff.email = values.email;
          adminStore.dataFormStaff.password = values.password;
          adminStore.dataFormStaff.password_confirmation =
            values.password_confirmation;

          adminStore.typeModal === "add"
            ? await adminStore.createStaff({ form })
            : await adminStore.updateStaff({ form });
        }}
      >
        <Form.Item<TFormStaff>
          className="item-form"
          label="Tên nhân viên"
          name="name"
          rules={[
            { required: true, message: "Vui lòng nhập vào trường này" },
            { max: 255, message: "Tối đa 255 ký tự" },
          ]}
        >
          <Input placeholder="Vui lòng nhập" />
        </Form.Item>
        <div className="divider-col2">
          <Form.Item<TFormStaff>
            className="item-form"
            label="Số điện thoại"
            name="phone"
            rules={[
              {
                pattern: /^[0-9]+$/,
                message: "Vui lòng chỉ nhập số!",
              },
              {
                message: "Vui lòng nhập số!",
              },
              {
                min: 10,
                message: "Số điện thoại phải có ít nhất 10 số!",
              },
              { required: true, message: "Vui lòng nhập vào trường này" },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </Form.Item>

          <Form.Item<TFormStaff>
            className="item-form"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Định dạng email không hợp lệ!",
              },
              { required: true, message: "Vui lòng nhập vào trường này" },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </Form.Item>
        </div>

        {adminStore.typeModal === "add" && (
          <>
            <div className="divider-col2">
              <Form.Item<TFormStaff>
                className="item-form"
                label="Mật khẩu"
                name="password"
                rules={[
                  { required: true, message: "Vui lòng nhập vào trường này" },
                  {
                    min: 8,
                    message: "Mật khẩu phải có ít nhất 8 ký tự",
                  },
                  {
                    max: 30,
                    message: "Mật khẩu không được vượt quá 30 ký tự",
                  },
                ]}
              >
                <Input.Password placeholder="Vui lòng nhập" />
              </Form.Item>
              <Form.Item<TFormStaff>
                className="item-form"
                label="Xác nhận Mật khẩu"
                name="password_confirmation"
                rules={[
                  { required: true, message: "Vui lòng nhập vào trường này" },
                  {
                    min: 8,
                    message: "Mật khẩu xác nhận phải có ít nhất 8 ký tự",
                  },
                  {
                    max: 30,
                    message: "Mật khẩu xác nhận không được vượt quá 30 ký tự",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Mật khẩu xác nhận không khớp!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Vui lòng nhập" />
              </Form.Item>
            </div>
            <Form.Item<TFormStaff>
              className="item-form"
              label="Phân quyền"
              name="roles"
              rules={[{ required: true, message: "Vui lòng chọn quyền" }]}
            >
              <SelectCustom
                list={adminStore.dataListRole && adminStore.dataListRole}
                placeholder="Vui lòng chọn"
                showSearch
                onChange={(value: any) => {
                  adminStore.dataFormStaff.roles = value;
                }}
                selected={() => {
                  return adminStore.dataListRole.find(
                    (item: any) => item.id === adminStore.dataFormStaff.roles
                  )?.id;
                }}
                getLabel={(item: any) => item.name}
              ></SelectCustom>
            </Form.Item>
          </>
        )}

        <Form.Item<TFormStaff>
          className="item-form"
          label="Vai trò của cửa hàng"
          name="type"
        >
          <SelectCustom
            list={[
              { id: EType.admin, name: "Quản lý" },
              { id: EType.staff, name: "Nhân viên" },
            ]}
            placeholder="Vui lòng chọn"
            showSearch
            onChange={(value: any) => {
              adminStore.dataFormStaff.type = value;
            }}
            selected={() => {
              return adminStore.dataFormStaff.type || EType.staff;
            }}
            getLabel={(item: any) => item.name}
          ></SelectCustom>
        </Form.Item>

        <Form.Item<TFormStaff>
          className="item-form"
          label="Ảnh đại diện"
          name="avatar"
        >
          <UploadFile
            onIconRemove={adminStore.handleCloseIcon}
            file={adminStore.dataFormStaff.avatar}
            onFileChange={(e) => {
              handleFile(e, adminStore.changeFileImage);
            }}
          />
        </Form.Item>
        <div className="btnCreateModal">
          <button
            className="cancelModal"
            type="button"
            onClick={() => {
              adminStore.openModalAddEditStaff = false;
            }}
          >
            Hủy bỏ
          </button>
          <button
            className={`submitForm`}
            disabled={adminStore.isLoadingBtn}
            type="submit"
          >
            {adminStore.typeModal === "add" && !adminStore.isLoadingBtn
              ? "Thêm mới"
              : adminStore.typeModal === "edit" && !adminStore.isLoadingBtn
              ? "Cập nhật"
              : "Vui lòng chờ..."}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default observer(AddEditStaff);
