import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { roomStore } from "../RoomStore";

const LocationFilterTab = () => {
    const [filterOpen, setFilterOpen] = useState(true);

    useEffect(() => {
        roomStore.locationFilterValue = -1;
        roomStore.staticParamRoom.store_id = null;
    }, []);

    return (
        <>
            <div className="filter-tab">
                <Button className="filter-collapse-btn" onClick={() => setFilterOpen(!filterOpen)}
                        aria-controls="example-collapse-text" aria-expanded={filterOpen}>
                    <span>Địa điểm</span>
                    <i className="fas fa-chevron-down"></i>
                </Button>
                {roomStore.loadingDataAll === true ? (<></>) : (
                    <Collapse in={filterOpen}>
                        <div className="filter-tab__body">
                            {roomStore.listStore.map((item: any, index: number) => (
                                <div className="filter-tab__option" key={index}>
                                    <input
                                        checked={roomStore.locationFilterValue === item.id}
                                        value={item.id}
                                        type="radio"
                                        name="location_id"
                                        id={item.id}
                                        onClick={() => {
                                            roomStore.handleCheckOnLocationOption(item.id);
                                        }}
                                    />
                                    <label className="radio-button"
                                           style={{
                                               overflow: "hidden",
                                               textOverflow: "ellipsis",
                                               whiteSpace: "nowrap",
                                           }}
                                           htmlFor={item.id}>
                                        {item.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Collapse>
                )}
            </div>
        </>
    );
}

export default observer(LocationFilterTab);
