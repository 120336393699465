import { observer } from "mobx-react"
import { posStore } from "../PosStore"
import { Form, Input, Modal } from "antd"
import { useEffect } from "react";
import SelectStatus from "../../../shared/components/form/SelectStatus";
import { toastUtils } from "../../../common/utils/Toastutils";
import SelectStore from "./SelectStore";

const ActionModal = ()=>{
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            name: posStore.staticDataPos.name,
            location: posStore.staticDataPos.store_id,
            status: posStore.staticDataPos.status,
        });
    }, [
        posStore.staticDataPos.name,
        posStore.staticDataPos.store_id,
        posStore.staticDataPos.status,
    ]);
    return(<>
        <Modal className="modal-form" title={posStore.modalName} footer={null} open={posStore.modalOpen} onCancel={() => {posStore.modalOpen = false }}>
            <Form form={form}
            name="pos-form"
            onFinish={(values)=>{
                posStore.staticDataPos.name = values.name;
                posStore.staticDataPos.store_id = values.location;
                posStore.staticDataPos.status = values.status;
                posStore.isCreating === true ? posStore.createPos() : posStore.modifyPos();
            }}
            initialValues={{name: posStore.staticDataPos.name,
                location: posStore.staticDataPos.store_id,
                status: posStore.staticDataPos.status,
                }}>
                <Form.Item
                        className="item-form"
                        label="Tên máy POS"
                        name="name"
                        rules={[{ required: true, message: 'Tên máy POS không được để trống' }]}
                    >
                        <Input placeholder="Vui lòng nhập" onChange={(event)=>posStore.staticDataPos.name = event.target.value}/>
                </Form.Item>
                <Form.Item
                        className="item-form"
                        label="Địa Điểm"
                        name="location"
                        rules={[{ required: true, message: 'Địa điểm đặt máy POS không được để trống' }]}
                    >
                        <SelectStore
                            onChange={(value) => {
                                posStore.staticDataPos.store_id = String(value);
                            }}
                            selected={() =>
                                posStore.staticDataPos.store_id
                                ? posStore.staticDataPos.store_id
                                : undefined
                            }
                            />
                </Form.Item>
                <Form.Item
                        className="item-form"
                        label="Trạng thái"
                        name="status"
                        rules={[{ required: true, message: 'Trạng thái máy POS không được để trống' }]}
                    >
                        <SelectStatus
                            onChange={(event)=>{posStore.staticDataPos.status = event}}
                            textOn="Hoạt động" textOff="Ngưng hoạt động"
                            selected={()=>(posStore.staticDataPos.status ? (posStore.staticDataPos.status === '1' ? 'Hoạt động' :  'Ngưng hoạt động') : '1')}/>
                </Form.Item>
                <div className="btnCreateModal">
                    <button className="cancelModal" type="button" onClick={() => { posStore.modalOpen = false;  }}>Đóng
                    </button>
                    <button className="submitForm" type="submit" form="pos-form" >{posStore.waitingText == '' ? 'Lưu' : posStore.waitingText}</button>
                </div>
            </Form>
        </Modal>
    </>)
}
export default observer(ActionModal)
