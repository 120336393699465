import React, { useEffect } from "react";
import { Form, Input, InputNumber, Modal } from "antd";
import { diningTablesStore } from "../../DiningTableStore";
import SelectCustom from "../../../../../shared/components/form/SelectCustom";
import { observer } from "mobx-react";
import "./AddEditDiningTable.scss"
import { toJS } from "mobx";

type FieldType = {
  name?: string;
  quantity_people?: number;
  ordering?: number;
  store_id?: number;
  floor_room_id?: number;
};
const AddEditDiningTable = () => {

  const [form] = Form.useForm();

  useEffect(()=>{
    diningTablesStore.fetchAllLocation();
  },[])

  useEffect(()=>{
      diningTablesStore.fetchAllfloorRoom("modal");
  },[diningTablesStore.formDataDiningTables.store_id])


  const dataFloorRoom =
    diningTablesStore.dataFloorRoom?.length > 0 ?
    diningTablesStore.dataFloorRoom?.map((item: any) => ({
      id: item.id,
      name: `${item.parent ? `${item?.parent?.name} / `: ""}${item?.name}`,
    })) : [];

  const dataLocation =
    diningTablesStore.dataLocation?.length > 0
      ? diningTablesStore.dataLocation?.map((item: any) => ({
          id: item.id,
          name: item.name,
        }))
      : [];


    useEffect(()=>{
      form.setFieldsValue({
        name: diningTablesStore.formDataDiningTables.name,
        quantity_people: diningTablesStore.formDataDiningTables.quantity_people,
        ordering: diningTablesStore.formDataDiningTables.ordering,
        store_id: diningTablesStore.formDataDiningTables.store_id,
        floor_room_id: diningTablesStore.formDataDiningTables.floor_room_id,
      })
    },[form,diningTablesStore.formDataDiningTables])

  return (
    <div className="modalAddEditDiningTable">
      <Modal
        title={`${
          diningTablesStore.typeModal === "add"
            ? "Thêm mới bàn"
            : "Chỉnh sửa bàn"
        }`}
        open={diningTablesStore.openModal}
        footer={null}
        onCancel={() => {
          diningTablesStore.openModal = diningTablesStore.holdModal;
          diningTablesStore.typeModal === "edit" &&
            diningTablesStore.clearFormDiningTables();
        }}
        wrapClassName={`modalAddEditDiningTable ${diningTablesStore.isLoadingBtn ? "disable" : ""} `}
      >
        <Form
          form={form}
          onFinish={(values: any) => {
            diningTablesStore.formDataDiningTables.name = values.name;
            diningTablesStore.formDataDiningTables.quantity_people = Number(
              values.quantity_people
            );
            diningTablesStore.formDataDiningTables.ordering = Number(
              values.ordering
            );
            // diningTablesStore.formDataDiningTables.store_id = values.store_id;
            // diningTablesStore.formDataDiningTables.floor_room_id =
            //   values.floor_room_id;

            diningTablesStore.typeModal === "add"
              ? diningTablesStore.createDiningTable({ form })
              : diningTablesStore.updateDiningTable(values);
          }}
        >
          <Form.Item<FieldType>
            className="item-form"
            label="Tên"
            name="name"
            rules={[
              { required: true, message: "Vui lòng nhập vào trường này" },
              { max: 255, message: "Tối đa 255 ký tự" },
            ]}
          >
            <Input placeholder="Vui lòng nhập" />
          </Form.Item>

          <Form.Item<FieldType>
            className="item-form"
            label="Số lượng người/ bàn"
            name="quantity_people"
            rules={[
              { required: true, message: "Vui lòng nhập vào trường này" },
              {
                validator: (_, value) =>
                  value > 0
                    ? Promise.resolve()
                    : Promise.reject(new Error("Số lượng phải lớn hơn 0")),
              },
            ]}
          >
            <Input type="number" placeholder="Vui lòng nhập" />
          </Form.Item>

          <Form.Item<FieldType>
            className="item-form"
            label="Thứ tự hiển thị"
            name="ordering"
            rules={[
              { required: true, message: "Vui lòng nhập vào trường này" },
              {
                validator: (_, value) =>
                  value > 0
                    ? Promise.resolve()
                    : Promise.reject(new Error("Thứ tự phải lớn hơn 0")),
              },
            ]}
          >
            <Input type="number" placeholder="Vui lòng nhập" />
          </Form.Item>

          <Form.Item<FieldType>
            className="item-form"
            label="Địa Điểm"
            name="store_id"
            rules={[
              {
                required: true,
                message: "Địa điểm không được để trống",
              },
            ]}
          >
            <SelectCustom
              list={dataLocation}
              placeholder="Vui lòng chọn"
              showSearch
              onChange={(value: any, name?: any) => {
                diningTablesStore.formDataDiningTables.store_id = value;
                diningTablesStore.formDataDiningTables.nameStore = name.label;
                diningTablesStore.formDataDiningTables.floor_room_id = null;
                diningTablesStore.formDataDiningTables.nameFloorRoom = "";
              }}
              selected={() => {
                return diningTablesStore.formDataDiningTables.store_id || null;
              }}
              getLabel={(item: any) => item.name}
            ></SelectCustom>
          </Form.Item>

          <div>
            <Form.Item<FieldType>
              className="item-form"
              label="Thuộc tầng/ phòng"
              name="floor_room_id"
              rules={[
                {
                  required: true,
                  message: "Tầng / Phòng không được để trống",
                },
              ]}
            >
              <SelectCustom
                list={dataFloorRoom}
                placeholder="Vui lòng chọn"
                showSearch
                disabled={
                  diningTablesStore.formDataDiningTables.store_id && !diningTablesStore.isLoadingRoom ? false : true
                }
                onChange={(value: any,name?: any) =>{
                  diningTablesStore.formDataDiningTables.floor_room_id = value
                  diningTablesStore.formDataDiningTables.nameFloorRoom = name.label;
                }
                }
                selected={() => {
                  return (
                    diningTablesStore.formDataDiningTables.floor_room_id || null
                  );
                }}
                getLabel={(item: any) => item.name}
              ></SelectCustom>
            </Form.Item>
          </div>

          <div className="btnCreateModal">
            <button
              className="cancelModal"
              type="button"
              onClick={() => {
                diningTablesStore.openModal = false;
                diningTablesStore.holdModal = false
              }}
            >
              Hủy bỏ
            </button>
            <button
              className={`submitForm`}
              disabled={diningTablesStore.isLoadingBtn}
              type="submit"
            >
              {diningTablesStore.typeModal === "add" &&
              !diningTablesStore.isLoadingBtn
                ? "Thêm mới"
                : diningTablesStore.typeModal === "edit" &&
                  !diningTablesStore.isLoadingBtn
                ? "Cập nhật"
                : "Vui lòng chờ..."}
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(AddEditDiningTable);
