import { postLoginRequest } from "../../common/helpers/RequestHelper";

class LoginService {
    apiSubidirectory = "api/v1/oauth/create-token";
    apiVerifyDomain = `${process.env.REACT_APP_API_DOMAIN}/api/v1/domain/verify`;

    public verifyDomain(domain_name: string) {
        const params = { domain_name };
        return postLoginRequest(this.apiVerifyDomain, params);
    }

    public createToken(username: string, password: string, endpoint: string) {
        const params = { username, password };
        return postLoginRequest(endpoint + this.apiSubidirectory, params);
    }
}

export const loginService = new LoginService();
