import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {createSearchParams, Link, useNavigate, useParams} from "react-router-dom";
import {customerDebtStore} from "../listCustomerDebt/CustomerDebtStore";
import {customerDebtDetalStore} from "./CustomerDebtDetalStore";
import HeaderProductInPos from "../../productInPos/components/headerProductInPos/HeaderProductInPos";
import Loading from "../../../../shared/components/loading/Loading";
import {utils} from "../../../../common/utils/Utils";
import StorageService from "../../../../common/service/StorageService";
import NoContent from "../../../../shared/components/NoContent";
import ReactPaginate from "react-paginate";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import {DatePicker} from "antd";
import {paymentSuccessStore} from "../../paymentSuccess/PaymentSuccessStore";
import {toastUtils} from "../../../../common/utils/Toastutils";
const { RangePicker } = DatePicker;
const CustomerDebtDetailPage = () => {
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();
    const { customerId } = useParams<{ customerId: string }>();
    const handleChangeDate: RangePickerProps["onChange"] = (date, dateString) => {
        const start: string = dateString[0];
        const end: string = dateString[1];
        if(start && end){
            customerDebtDetalStore.staticParam.start_date = start;
            customerDebtDetalStore.staticParam.end_date = end;
            customerDebtDetalStore.fetchDetailCustomerDebt(true)
        }else{
            toastUtils.warning('Bạn phải chọn ngày')
        }

    };
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        // if (customerDebtStore.staticParam.keyword !== "")
        //     params.keyword =
        //         customerDebtStore.staticParam.keyword;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        customerDebtDetalStore.staticParam.page_number = selectedPage;
        customerDebtDetalStore.fetchDetailCustomerDebt(true);
    };

    useEffect(() => {
        customerDebtDetalStore.postId = postId
        customerDebtDetalStore.customerId = customerId
        customerDebtDetalStore.fetchDetailCustomerDebt().then()
    }, [postId]);
    return(
        <>
            <div className="customer-debt-page">
                <HeaderProductInPos/>
                <div className="session2">
                    <Link to={`/pos/${postId}/cong-no`}>
                        <span>
                            <img src="/assets/icon/prev.svg" alt="Quay lại"/>
                            Quay lại
                        </span>
                    </Link>
                    <p>Chi tiết công nợ</p>
                </div>
                <div className="session3 session3-debt-detail">
                    <div className="seachDebt">
                        <p className="price_debt">Tiền nợ hiện tại:  {utils.formatCurrency(customerDebtDetalStore.metadata?.amount_debt_total ? customerDebtDetalStore.metadata.amount_debt_total : 0)}</p>
                        <div className="form-search-debt">
                            <div className="form-search-debt-item">
                                <select className="transaction_type" onChange={(values:any) => {
                                    customerDebtDetalStore.staticParam.transaction_type = values.target.value
                                    customerDebtDetalStore.fetchDetailCustomerDebt(true)
                                }}>
                                    <option value="">Trạng thái</option>
                                    <option value="2">Nợ</option>
                                    <option value="3">Đã trả</option>
                                </select>
                            </div>
                            <div className="form-search-debt-item">
                                <div className="form-fitter form-fitter-2">
                                    <RangePicker
                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        format="DD-MM-YYYY"
                                        value={[
                                            customerDebtDetalStore.staticParam.start_date
                                                ? dayjs(
                                                    customerDebtDetalStore.staticParam.start_date,
                                                    "DD-MM-YYYY"
                                                )
                                                : null,
                                            customerDebtDetalStore.staticParam.end_date
                                                ? dayjs(
                                                    customerDebtDetalStore.staticParam.end_date,
                                                    "DD-MM-YYYY"
                                                )
                                                : null,
                                        ]}
                                        defaultValue={[customerDebtDetalStore.staticParam.start_date,customerDebtDetalStore.staticParam.end_date] as [dayjs.Dayjs, dayjs.Dayjs]} // Chỉ định kiểu cho TypeScript
                                        onChange={handleChangeDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentSession">
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "40%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Hóa đơn
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Số tiền trả/nợ
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Điểm bán hàng
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Nhân viên
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "center",
                                        }}
                                    >
                                        Trạng thái
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {customerDebtDetalStore.isLoading ? (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{textAlign: "center"}}
                                        >
                                            <Loading/>
                                        </td>
                                    </tr>
                                ) : customerDebtDetalStore.listCustomerDebtDetail
                                    .length > 0 ? (
                                    customerDebtDetalStore.listCustomerDebtDetail.map(
                                        (item: any, index: number) => (
                                            <>

                                                <tr className="tr-debt">
                                                    <td>
                                                        <p className="codeTrans">{item.pos_session_name}</p>
                                                        <p className="amountTotal">Tổng giá trị đơn hàng: {item.amount_total ? utils.formatCurrency(item.amount_total) : utils.formatCurrency(0)}</p>
                                                        <p className="time-debt">{new Date(
                                                            item.created_at
                                                        ).toLocaleString(
                                                            "en-GB"
                                                        )}</p>
                                                    </td>
                                                    <td>
                                                        <span className="text">
                                                            {item.transaction_type === 2 ? (
                                                                <>
                                                                    {utils.formatCurrency(item.amount_total -  item.bank_amount - item.cash_amount  )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {utils.formatCurrency( item.bank_amount + item.cash_amount  )}
                                                                </>
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="text">{item.location_store_name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="text">{item.user_name}</span>
                                                    </td>
                                                    <td style={{
                                                        textAlign: "center",
                                                    }}>
                                                        {item.transaction_type === 2 ? (
                                                            <>
                                                                <span className="debt">Nợ</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="pay_debt">Đã trả</span>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{textAlign: "center"}}
                                        >
                                            <NoContent/>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            {customerDebtDetalStore.isLoading
                                ? ""
                                : Math.ceil(
                                customerDebtDetalStore.metadata.total_page
                            ) > 1 && (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                        customerDebtDetalStore.metadata
                                            .total_page
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={
                                        customerDebtDetalStore.staticParam.page_number
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(CustomerDebtDetailPage)