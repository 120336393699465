import { observer } from "mobx-react";
import { roomStore } from "../RoomStore";
import { Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import SelectCustom from "../../../shared/components/form/SelectCustom";

const EditModal = () => {
    const [formDetail] = Form.useForm();

    useEffect(() => {
        roomStore.getRoomAllFloor().then();
    }, []);

    useEffect(() => {
        if (roomStore.isCreating) {
            formDetail.resetFields();
            formDetail.setFieldsValue({
                ordering: 1,
                name: roomStore.staticDataRoom.name,
                location_id: roomStore.staticDataRoom.store_id,
                parent_id: roomStore.staticDataRoom.parent_id,
            });
        } else if (roomStore.dataDetail) {
            formDetail.setFieldsValue({
                id: roomStore.dataDetail?.id,
                name: roomStore.dataDetail?.name,
                location_id: roomStore.dataDetail?.location_id,
                ordering: roomStore.dataDetail?.ordering,
                parent_id: roomStore.dataDetail?.parent_id,
            });
        }
    }, [formDetail, roomStore.isCreating, roomStore.dataDetail]);
    return (
        <>
            <Modal
                className="modal-form"
                title={roomStore.modalName}
                footer={null}
                open={roomStore.modalOpen}
                onCancel={() => {
                    roomStore.modalOpen = false;
                    formDetail.resetFields();
                }}
                afterClose={() => {
                    roomStore.handleClearContent();
                    formDetail.resetFields();
                }}
            >
                <Form
                    form={formDetail}
                    name="room-form"
                    onFinish={(values) => {
                        roomStore.staticDataRoom.id = roomStore.dataDetail?.id;
                        roomStore.staticDataRoom.name = values.name;
                        roomStore.staticDataRoom.store_id = values.location_id;
                        roomStore.staticDataRoom.ordering = values.ordering;
                        roomStore.staticDataRoom.parent_id = values.parent_id;
                        // roomStore.isLoadingBtn = true;
                        if (roomStore.isCreating) {
                            roomStore.addRoom(formDetail).then(() => {
                                formDetail.resetFields();
                            }).catch(err => console.log(err));
                        } else {
                            roomStore.editRoom().finally(() => {
                                formDetail.resetFields();
                            });
                        }
                    }}
                >
                    <Form.Item
                        className="item-form"
                        label="Tên phòng/tầng"
                        name="name"
                        rules={[{ required: true, message: "Tên phòng/tầng không được để trống" }]}
                    >
                        <Input placeholder="Vui lòng nhập"
                               maxLength={255}
                        />
                    </Form.Item>

                    <Form.Item
                        className="item-form"
                        label="Thứ tự hiển thị"
                        name="ordering"
                        rules={[{ required: true, message: "Thứ tự hiển thị không được để trống" }]}
                    >
                        <Input placeholder="Vui lòng nhập"
                               type="number"
                        />
                    </Form.Item>

                    <Form.Item
                        className="item-form"
                        label="Địa Điểm"
                        name="location_id"
                        rules={[{ required: true, message: "Địa điểm không được để trống" }]}
                    >
                        {roomStore.dataDetail ? (
                            <SelectCustom
                                list={roomStore.listStore}
                                placeholder={`-- Chọn --`}
                                onChange={(value: any) => roomStore.dataDetail.location_id = value}
                                selected={() => {
                                    const storeId = roomStore?.dataDetail?.location_id;
                                    return storeId || undefined;
                                }}
                                getLabel={(item: any) => item.name}
                            />
                        ) : (
                            <SelectCustom
                                list={roomStore.listStore}
                                placeholder={`-- Chọn --`}
                                onChange={(value: any) => roomStore.staticDataRoom.store_id = value}
                                selected={() => {
                                    const storeId = roomStore?.staticDataRoom?.store_id;
                                    return storeId || undefined;
                                }}
                                getLabel={(item: any) => item.name}
                            />
                        )}
                    </Form.Item>

                    <div className="item-form">
                        <label className="label-form">Danh mục cha</label>
                        <Form.Item name="parent_id">
                            {roomStore.dataDetail ? (
                                <SelectCustom
                                    list={roomStore.listRoomAll.filter(
                                        (item) => item.location_id === roomStore.dataDetail.location_id
                                    )}
                                    placeholder={`-- Chọn --`}
                                    onChange={(value: any) => (roomStore.dataDetail.parent_id = value)}
                                    selected={() => {
                                        const parentId = roomStore.dataDetail?.parent_id;
                                        return parentId || undefined;
                                    }}
                                    getLabel={(item: any) => item.name}
                                />
                            ) : (
                                roomStore.staticDataRoom.store_id != "" ? (
                                    <SelectCustom
                                        list={roomStore.listRoomAll.filter(
                                            (item) => item.location_id === roomStore.staticDataRoom.store_id
                                        )}
                                        placeholder={`-- Chọn --`}
                                        onChange={(value: any) => (roomStore.staticDataRoom.parent_id = value)}
                                        selected={() => {
                                            const parentId = roomStore.staticDataRoom?.parent_id;
                                            return parentId || undefined;
                                        }}
                                        getLabel={(item: any) => item.name}
                                    />
                                ) : (
                                    <SelectCustom
                                        list={[]}
                                        placeholder={`-- Chọn --`}
                                        onChange={(value: any) => (roomStore.staticDataRoom.parent_id = value)}
                                        selected={() => {
                                            const parentId = roomStore.staticDataRoom?.parent_id;
                                            return parentId || undefined;
                                        }}
                                        getLabel={(item: any) => item.name}
                                    />
                                )
                            )}
                        </Form.Item>
                    </div>

                    <div className="btnCreateModal">
                        <button
                            className="cancelModal"
                            type="button"
                            onClick={() => {
                                roomStore.modalOpen = false;
                                formDetail.resetFields(); // Reset form khi bấm Đóng
                            }}
                        >
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            form="room-form"
                            disabled={roomStore.isLoadingBtn}
                        >
                            {roomStore.isLoadingBtn ? "Vui lòng đợi" : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(EditModal);
