import React from 'react';
import "../../styles/Sidebar.scss"
import { useNavigate } from 'react-router-dom';

const navLink = [
  {
    id: 1,
    name: "Quản trị viên",
    icon: "/assets/icon/settings/admin.svg",
    url: "/cai-dat/quan-tri-vien",
    slug: "quan-tri-vien",
  },
  {
    id: 2,
    name: "Quyền Hạn",
    icon: "/assets/icon/settings/crown.svg",
    url: "/cai-dat/quyen",
    slug : "quyen"
  },
  {
    id: 3,
    name: "Thanh Toán",
    icon: "/assets/icon/settings/cardpayment.svg",
    url: "/cai-dat/thanh-toan",
    slug: "thanh-toan"
  },
  {
    id: 4,
    name: "Địa điểm",
    icon: "/assets/icon/settings/map.svg",
    url: "/chi-nhanh-cua-hang",
    slug: "chi-nhanh-cua-hang"
  },
  {
    id: 5,
    name: "Máy POS",
    icon: "/assets/icon/settings/bag.svg",
    url: "/pos/may-pos",
    slug: "may-pos"
  },
  {
    id: 6,
    name: "Tệp tin",
    icon: "/assets/icon/settings/folder.svg",
    url: "/cai-dat/tep-tin",
    slug: "tep-tin"
  },
];

const Sidebar = () => {
    const navigate = useNavigate()
      // Lấy đường dẫn hiện tại từ URL
  const pathname = window.location.pathname;

  // Tách đường dẫn bằng dấu gạch chéo và lấy phần tử cuối cùng
  const lastPath = pathname.substring(pathname.lastIndexOf('/') + 1);
  
    return (
      <div className="SidebarSettings">
        <div className="SidebarSettings-body">
          {navLink.length > 0 &&
            navLink.map((item) => (
              <div
                key={item.id}
                className={`SidebarSettings-item ${item.slug === lastPath ? "active" : ""}`}
                onClick={() => {
                  navigate(item.url);
                }}
              >
                <img src={item.icon} alt="img" />
                <h1>{item.name}</h1>
              </div>
            ))}
        </div>
      </div>
    );
};

export default Sidebar;