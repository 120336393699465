import React, {useState} from "react";
import {observer} from "mobx-react";
import {productStore} from "../../ProductStore";
import {Button, Form, Input, InputNumber, Modal, Select} from "antd";
import {toastUtils} from "../../../../../common/utils/Toastutils";
import SelectMultipleAnt from "../../../../../shared/components/form/SelectMultipleAnt";
import Loading from "../../../../../shared/components/loading/Loading";
import {handleFile, handleFileVariant} from "../../../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../../../shared/components/form/UploadFile";
import {utils} from "../../../../../common/utils/Utils";

const VariantCreateProduct = () => {
    const pushAttributeProduct = () => {
        let listIdAttributeProduct = productStore.arrayAttributeProduct.map((item) => item.id) // Laays
        let items = productStore.lisAttribute.filter(item => !listIdAttributeProduct.includes(item.id))
        productStore.arrayAttributeProduct = [...productStore.arrayAttributeProduct, {
            options: [...items.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            })],
            ...items[0]
        }]
        productStore.arrayAttributeProduct.map((item) => {
            let listIdsOther = [...productStore.arrayAttributeProduct.filter(i => i.id !== item.id).map(product => product.id)]
            item.options = item.options.filter((option : any) => !listIdsOther.includes(option.value))
            return item
        })
    };

    const changeAttributeProduct = () => {
        productStore.arrayAttributeProduct = productStore.arrayAttributeProduct.map((item) => {
            let listIdsOther = [...productStore.arrayAttributeProduct.filter(i => i.id !== item.id).map(product => product.id)]
            item.options = [...productStore.lisAttribute].filter(i => !listIdsOther.includes(i.id)).map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            return item
        })
    }
    const removeAttributeProduct = (id: number,indexRemove:number) => {
        productStore.arrayAttributeProduct = [...productStore.arrayAttributeProduct.filter(i => i.id !== id)]
        productStore.staticDataGenerateAttributes.attribute_ids = productStore.staticDataGenerateAttributes.attribute_ids.map((item: any, index: number) => {
            if(indexRemove !== index){
                return item;
            }
        })

        let removeItem = productStore.lisAttribute.find((i:any) => i.id === id)
        productStore.arrayAttributeProduct.map((item) => {
            let listIdsOther = [...productStore.arrayAttributeProduct.filter(i => i.id !== item.id).map(product => product.id)]
            item.options = [...item.options.filter((option : any) => !listIdsOther.includes(option.value)),
                {
                    value: removeItem.id,
                    label: removeItem.name,
                    ...removeItem
                }
            ]
            return item
        })
    };
    return (
        <>
            <div className="session-create session-create-variant">
                <div className="session-create-left">
                    <div className="session-create-left-content">
                        <p className="title">Thông tin biến thể sản phẩm</p>
                        <p className="description">Thêm biến thể sản phẩm và thông tin cần thiết từ đây</p>
                    </div>
                </div>
                <div className="session-create-right">
                    <div className="session-create-content">
                        <div className="grid-1-2-3 gap-20">
                            {productStore.arrayAttributeProduct.map((item: any, index: number) => {
                                return <>
                                    <div className="item-form">
                                        {/*<Form.Item name={`variant${index}`} label="Biến thể" rules={[{required: true, message: 'Không được để trống!'}]}>*/}
                                        <label>Biến thể</label>
                                            <Select
                                                showSearch
                                                placeholder=""
                                                onChange={(value) => {
                                                    item.id = value
                                                    productStore.arrayAttributeProduct[index].attributes = productStore.lisAttribute.find(i => i.id === value)?.attributes
                                                    productStore.staticDataGenerateAttributes.attribute_ids[index] = []
                                                    changeAttributeProduct();
                                                    productStore.fetchGenerateAttributes();
                                                }}
                                                value={item.id}
                                                options={item.options}
                                            />
                                            {/*</Form.Item>*/}
                                        </div>
                                        <div className="item-form">
                                            <Form.Item name={`attributes${index}`} id={`attributes${index}`}
                                                       label="Thuộc tính con"
                                                       rules={[{required: true, message: 'Không được để trống!'}]}>
                                                <SelectMultipleAnt
                                                    list={productStore.arrayAttributeProduct[index].attributes}
                                                    onChange={(value: any) => {
                                                        productStore.staticDataGenerateAttributes.attribute_ids[index] = value;
                                                        productStore.fetchGenerateAttributes();
                                                    }}
                                                    defaultValue={productStore.staticDataGenerateAttributes.attribute_ids[index] || []}
                                                    placeholder={`-- Chọn --`}
                                                    getLabel={(item: any) => item.name}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Button
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    if (productStore.arrayAttributeProduct.length === 1) {
                                                        toastUtils.warning('Không thể xóa biến thể cuối cùng', '');
                                                    } else {
                                                        removeAttributeProduct(item.id,index);
                                                        productStore.fetchGenerateAttributes();
                                                    }
                                                }}
                                            >
                                                Xóa
                                            </Button>
                                        </div>
                                    </>
                                }
                            )}
                        </div>
                        <div className="add-variant">
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (productStore.lisAttribute.length <= productStore.arrayAttributeProduct.length) {
                                        toastUtils.warning('Vui lòng thêm biến thể', '');
                                    } else {
                                        pushAttributeProduct();
                                    }
                                }}
                            >
                                Thêm biến thể
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="session-create session-create-variant">
                <div className="session-create-left">

                </div>
                <div className="session-create-right session-create-variant-right">
                    <div className="session-create-content table-responsive">
                        <table className="table table-striped" id="table-2">
                            <thead>
                            <tr>
                                <th style={{width: '22.5%', textAlign: 'left'}}>Thuộc tính</th>
                                <th style={{width: '22.5%', textAlign: 'left'}}>Thông tin</th>
                                <th style={{width: '40%', textAlign: 'left'}}>Hình ảnh</th>
                                <th style={{width: '15%', textAlign: 'left'}}>Hành động</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productStore.isLoadingGenerateAttributes ? (
                                <tr>
                                    <td colSpan={4} style={{textAlign: 'center'}}>
                                        <Loading/>
                                    </td>
                                </tr>
                            ) : (
                                productStore?.listGenerateAttributes?.map((item: any, index: number) => (
                                    <>
                                        <tr>
                                        <td>
                                            <p>{item.attribute_name}</p>
                                        </td>
                                        <td>
                                            <p>Giá: {utils.formatCurrency(item.price_original)} </p>
                                            <p>Mã: {`${item.sku_original}-${index}`}</p>
                                        </td>
                                        <td>
                                            <div className="info">
                                                <div className="image image-attributes">
                                                    <UploadFile
                                                        file={item.thumbnail}
                                                        onIconRemove={() => productStore.listGenerateAttributes[index].thumbnail = ''}
                                                        onFileChange={(e) => handleFileVariant(e,index,productStore.changeFileVariant)}
                                                    />
                                                </div>
                                                <div className="title-code">
                                                    <div className="title-code-content">
                                                        <span>Kích thước 800x800</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button className="modal-form editAttributes"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        productStore.handleEditClick({ ...item, index });
                                                    }}>
                                                <span>
                                                    Chỉnh sửa
                                                </span>
                                            </button>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    </>
                                ))
                            )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(VariantCreateProduct);
