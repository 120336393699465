import React from "react";
import "./addPermissions.scss";
import { Form, Input, Modal } from "antd";
import { observer } from "mobx-react";
import {permissionsStore} from "../../PermissionsStore";
import SelectMultipleAnt from "../../../../../shared/components/form/SelectMultipleAnt";

type FieldType = {
  name?: string;
  email?: string;
  phone?: string;
  roles?: number;
  type?: string;
  avatar?: string;
  password?: string;
  password_confirmation?: string;
};

const AddPermissions = () => {
  const [form] = Form.useForm();

  return (
    <Modal
      title={`Thêm nhân viên`}
      open={permissionsStore.openModalAddEditStaff}
      footer={null}
      onCancel={() => {
        permissionsStore.openModalAddEditStaff = false;
      }}
      wrapClassName={`modalAddEditStaff`}
    >
      <Form
          form={form}
          onFinish={(values: any) => {
            console.log(values.permissions)
            permissionsStore.staticDataStore.name = values.name
            permissionsStore.staticDataStore.permissions = values.permissions
            permissionsStore.fetchStoreRole(form)
          }}
      >
        <Form.Item<FieldType>
            className="item-form"
            label="Tên nhóm quyền"
            name="name"
            rules={[{required: true, message: "Vui lòng nhập"},{max:200, message: "Tên không được quá 200 ký tự"}]}
        >
          <Input placeholder="Vui lòng nhập"/>
        </Form.Item>
        <div className="item-form">
          <Form.Item name="permissions" label="Quyền hạn"
                     rules={[{required: true, message: 'Vui lòng chọn!'}]}>
            <SelectMultipleAnt
                list={permissionsStore.dataListRoleDetail}
                placeholder={`-- Chọn --`}
                getLabel={(item: any) => item.trans_name}
            />
          </Form.Item>
        </div>
        <div className="btnCreateModal">
          <button
              className="cancelModal"
              type="button"
              onClick={() => {
                permissionsStore.openModalAddEditStaff = false;
              }}
          >
            Hủy bỏ
          </button>
          <button
              className={`submitForm`}
              type="submit"
          >
            {permissionsStore.isSave ? 'Đang lưu' : 'Thêm mới'}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default observer(AddPermissions);
