import React, { useEffect, useState } from "react";
import "./styles/DiningTables.scss";
import BoxFilterDiningTables from "./components/boxFilterDiningTables/BoxFilterDiningTables";
import { Dropdown } from "react-bootstrap";
import { observer } from "mobx-react";
import { diningTablesStore } from "./DiningTableStore";
import AddEditDiningTable from "./components/addEditDiningTable/AddEditDiningTable";
import { showDelete } from "../../../shared/components/showDelete";
import Loading from "../../../shared/components/loading/Loading";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import NoContent from "../../../shared/components/NoContent";
import BoxFilterDiningTablesNew from "./components/boxFilterDiningTables/BoxFilterDiningTablesNew";
import { toJS } from "mobx";
const DiningTablesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    diningTablesStore.fetchAllDiningTablesList().then();
  }, []);

  const showDeleteConfirm = (id: number) => {
    showDelete.showDeleteConfirm(
      "Xác nhận xóa",
      "Bạn có chắc muốn xóa mục này",
      async () => {
        await diningTablesStore.deleteDiningTable(id);
      }
    );
  };

  const handleChangePage = (e: any) => {
    diningTablesStore.staticParamDiningTable.page = e.selected;
    const searchParams = new URLSearchParams(location.search);

    searchParams.set(
      "page",
      (diningTablesStore.staticParamDiningTable.page + 1).toString()
    );
    navigate({
      search: searchParams.toString(),
    });

    diningTablesStore.fetchAllDiningTablesList();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`diningTable-container ${
        diningTablesStore.isLoading ? "disabledScreen" : ""
      }`}
    >
      <div className="diningTable-filter">
        <h1 className="title">Bàn Ăn</h1>

        <BoxFilterDiningTablesNew />
      </div>
      <div className="diningTable-content">
        <div className="diningTable-action d-flex align-items-center justify-content-between">
          <div className="diningTable-boxFilter">
            <BoxFilterDiningTables />
          </div>

          <div
            className="diningTable-action--add"
            onClick={() => {
              diningTablesStore.openModal = true;
              diningTablesStore.typeModal = "add";
              diningTablesStore.storeId = null;
            }}
          >
            <span>
              Thêm mới
              <img src="/assets/icon/plus.svg" alt="add" />
            </span>
          </div>
        </div>
        <div className="table-container">
          <div className="table-responsive">
            <table className="table table-striped" id="table-2">
              <thead>
                <tr>
                  <th
                    style={{
                      width: "30%",
                      textAlign: "left",
                    }}
                  >
                    Tên
                  </th>
                  <th
                    style={{
                      width: "23%",
                      textAlign: "left",
                    }}
                  >
                    Phòng / Tầng
                  </th>
                  <th
                    style={{
                      width: "25%",
                      textAlign: "left",
                    }}
                  >
                    Địa điểm
                  </th>
                  <th
                    style={{
                      width: "10%",
                      textAlign: "left",
                    }}
                  >
                    Thứ tự
                  </th>
                  <th
                    style={{
                      width: "12%",
                      textAlign: "left",
                    }}
                  >
                    Thao tác
                  </th>
                </tr>
              </thead>
              <tbody>
                {diningTablesStore.isLoading ? (
                  <tr>
                    <td colSpan={6} style={{ textAlign: "center" }}>
                      <Loading />
                    </td>
                  </tr>
                ) : diningTablesStore.dataDiningTables.length <= 0 ? (
                  <tr>
                    <td colSpan={6}>
                      {" "}
                      <NoContent />{" "}
                    </td>
                  </tr>
                ) : (
                  diningTablesStore.dataDiningTables?.length > 0 &&
                  diningTablesStore.dataDiningTables.map((item: any) => (
                    <tr key={item.id}>
                      <td>
                        <div className="d-flex flex-column">
                          <p className="text-truncate tableName" title={item.name} >
                            {item.name}
                          </p>
                          <p>{item.quantity_people} người/bàn</p>
                        </div>
                      </td>
                      <td>
                        {" "}
                        {item.floor_room?.id && item.floor_room_parent?.id
                          ? `${item.floor_room?.name} /`
                          : item.floor_room?.id && !item.floor_room_parent?.id
                          ? `${item.floor_room?.name}`
                          : ""}{" "}
                        {item.floor_room_parent?.id
                          ? `${item.floor_room_parent?.name}`
                          : ""}
                      </td>
                      <td>{item.store?.name}</td>
                      <td>{item.ordering}</td>
                      <td>
                        <Dropdown className="action-table">
                          <Dropdown.Toggle variant="success">
                            Thao tác
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                diningTablesStore.openModal = true;
                                diningTablesStore.typeModal = "edit";
                                diningTablesStore.storeId = null;
                                diningTablesStore.idDiningTable = item.id;

                                diningTablesStore.formDataDiningTables = {
                                  ...item,
                                  store_id: item?.store?.id,
                                  nameStore: item.store?.name,
                                  nameFloorRoom: item.floor_room?.name,
                                };
                              }}
                            >
                              <i className="fa fa-edit"></i>
                              Sửa
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                showDeleteConfirm(item.id);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                              Xóa
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {diningTablesStore.metadata.total_page > 1 && (
          <ReactPaginate
            previousLabel={
              <img src="/assets/icon/fi-rr-angle-small-left.svg" alt="right" />
            }
            nextLabel={
              <img src="/assets/icon/fi-rr-angle-small-right.svg" alt="right" />
            }
            breakLabel={"..."}
            pageCount={diningTablesStore.metadata.total_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            containerClassName={"pagination"}
            activeClassName={"active"}
            onPageChange={handleChangePage}
            forcePage={diningTablesStore.staticParamDiningTable.page}
          />
        )}
      </div>

      <AddEditDiningTable />
    </div>
  );
};

export default observer(DiningTablesPage);
