import React, {useEffect} from "react";
import { observer } from "mobx-react";
import './styles/index.css'
import HeaderProductInPos from "../../productInPos/components/headerProductInPos/HeaderProductInPos";
import {createSearchParams, Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {customerDebtStore} from "./CustomerDebtStore";
import Loading from "../../../../shared/components/loading/Loading";
import NoContent from "../../../../shared/components/NoContent";
import ReactPaginate from "react-paginate";
import {utils} from "../../../../common/utils/Utils";
import StorageService from "../../../../common/service/StorageService";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const CustomerDebtPage = () => {
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();
    useEffect(() => {
        customerDebtStore.postId = postId
        customerDebtStore.fetchListCustomerDebt().then()
    }, [postId]);

    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (customerDebtStore.staticParam.keyword !== "")
            params.keyword =
                customerDebtStore.staticParam.keyword;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        customerDebtStore.staticParam.page = selectedPage;
        customerDebtStore.fetchListCustomerDebt(true);
    };
    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const searchKeyword = (e.target as HTMLFormElement).keyword.value;
        const params: any = {};
        if (searchKeyword !== "") params.keyword = searchKeyword;
        navigate({
            search: createSearchParams(params).toString(),
        });
        customerDebtStore.staticParam.page = 0;
        customerDebtStore.fetchListCustomerDebt(true);
    };
    return (
        <>
            <div className="customer-debt-page">
                <HeaderProductInPos/>
                <div className="session2">
                    <Link to={`/pos/${postId}/san-pham`}>
                        <span>
                            <img src="/assets/icon/prev.svg" alt="Quay lại"/>
                            Quay lại
                        </span>
                    </Link>
                    <p>Danh sách công nợ</p>
                </div>
                <div className="session3">
                    <div className="seachDebt">
                        <form onSubmit={handleSearch}>
                            <div className="form-search-debt">
                                <input type="text" placeholder="Tìm kiếm theo số điện thoại" name="keyword" onChange={(e:any) => customerDebtStore.staticParam.keyword = e.currentTarget.value} value={customerDebtStore.staticParam.keyword} />
                            </div>
                            <div className="btnSubmit">
                                <button type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                         fill="none">
                                        <path
                                            d="M12.8801 20.1601C8.8481 20.1601 5.6001 16.9121 5.6001 12.8801C5.6001 8.8481 8.8481 5.6001 12.8801 5.6001C16.9121 5.6001 20.1601 8.8481 20.1601 12.8801C20.1601 16.9121 16.9121 20.1601 12.8801 20.1601ZM12.8801 6.7201C9.4641 6.7201 6.7201 9.4641 6.7201 12.8801C6.7201 16.2961 9.4641 19.0401 12.8801 19.0401C16.2961 19.0401 19.0401 16.2961 19.0401 12.8801C19.0401 9.4641 16.2961 6.7201 12.8801 6.7201Z"
                                            fill="white"></path>
                                        <path
                                            d="M18.3021 17.5093L23.3309 22.5381L22.5391 23.3299L17.5103 18.3011L18.3021 17.5093Z"
                                            fill="white"></path>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="contentSession">
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "25%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Thông tin khách hàng
                                    </th>
                                    <th
                                        style={{
                                            width: "20%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Số tiền nợ
                                    </th>
                                    <th
                                        style={{
                                            width: "20%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Điểm bán hàng
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Trạng thái công nợ
                                    </th>
                                    <th
                                        style={{
                                            width: "20%",
                                            textAlign: "left",
                                        }}
                                    >
                                        
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {customerDebtStore.isLoading ? (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{textAlign: "center"}}
                                        >
                                            <Loading/>
                                        </td>
                                    </tr>
                                ) : customerDebtStore.listCustomerDebt
                                    .length > 0 ? (
                                    customerDebtStore.listCustomerDebt.map(
                                        (item: any, index: number) => (
                                            <tr className="tr-debt">
                                                <td onClick={() => {
                                                    navigate(`/pos/${postId}/chi-tiet-cong-no/${item.id}`)
                                                }}>
                                                    <p className="phone-debt">{item.phone}</p>
                                                    <p className="name-debt">{item.name}</p>
                                                </td>
                                                <td onClick={() => {
                                                    navigate(`/pos/${postId}/chi-tiet-cong-no/${item.id}`)
                                                }}>
                                                    <span className="text">{item.amount_debt ? utils.formatCurrency(item.amount_debt) : utils.formatCurrency(0)}</span>
                                                </td>
                                                <td onClick={() => {
                                                    navigate(`/pos/${postId}/chi-tiet-cong-no/${item.id}`)
                                                }}>
                                                    <span className="text">{item.store?.name}</span>
                                                </td>
                                                <td onClick={() => {
                                                    navigate(`/pos/${postId}/chi-tiet-cong-no/${item.id}`)
                                                }}>
                                                    <span className="debt">Nợ</span>
                                                </td>
                                                <td  style={{
                                                    textAlign: "center",
                                                }}>
                                                    <span
                                                        onClick={() => {
                                                            StorageService.setObjectStore(`customerDebt${postId}`,item)
                                                            navigate(`/pos/${postId}/thanh-toan-cong-no`)
                                                        }}
                                                        className="payment-debt">Thanh toán</span>
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            style={{textAlign: "center"}}
                                        >
                                            <NoContent/>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            {customerDebtStore.isLoading
                                ? ""
                                : Math.ceil(
                                customerDebtStore.metadata.total_page
                            ) > 1 && (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                        customerDebtStore.metadata
                                            .total_page
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={
                                        customerDebtStore.staticParam.page
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default observer(CustomerDebtPage)