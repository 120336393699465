import { observer } from "mobx-react";
import { orderStore } from "./OrderStore";
import { useEffect, useState } from "react";
import "./styles/order.css";
import { Button } from "react-bootstrap";
import type { RadioChangeEvent } from "antd";
import { DatePicker, Radio, ConfigProvider } from "antd";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import Loading from "../../shared/components/loading/Loading";
import NoContent from "../../shared/components/NoContent";
import { RangePickerProps } from "antd/es/date-picker";
import ReactPaginate from "react-paginate";
import { utils } from "../../common/utils/Utils";
import dayjs from "dayjs"; // Thư viện xử lý ngày tháng
import viVN from "antd/locale/vi_VN"; // Ngôn ngữ tiếng Việt
import "dayjs/locale/vi"; // Cài đặt ngôn ngữ cho dayjs
dayjs.locale("vi");

const Order = () => {
    useEffect(() => {
        // orderStore.staticParamOrder.startDate =
        //     localStorage.getItem("startDate");
        // orderStore.staticParamOrder.endDate = localStorage.getItem("endDate");
        // orderStore.staticParamOrder.payment_method = paymentMethod;
        // orderStore.staticParamOrder.payment_status = paymentStatus;
        // orderStore.staticParamOrder.order_type = orderMethod;
        // orderStore.staticParamOrder.order_status = orderStatus;
        // orderStore.staticParamOrder.page = parseInt(pageOrder);
        orderStore.fetctAllListOder();
        //Khi component unmount thi clear local
        // return () => {
        //     localStorage.setItem("startDate", "");
        //     localStorage.setItem("endDate", "");
        //     localStorage.setItem("LoaiThanhToan", "");
        //     localStorage.setItem("TTThanhToan", "");
        //     localStorage.setItem("LoaiDon", "");
        //     localStorage.setItem("TTDon", "");
        //     localStorage.setItem("Trang", "");
        //     localStorage.setItem("TimKiem", "");
        // };
    }, []);

    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const plainOptionsOrdermethod = ["Tiền mặt", "Chuyển khoản"];
    const plainOptionsInfoPaymentMethod = [
        "Hoàn trả",
        "Hủy bỏ",
        "Đang xử lý",
        "Hoàn thành",
        "Thất bại",
    ];
    const plainOptionsOderType = ["POS", "WEBSITE"];
    const plainOptionsInfoOderType = [
        "Hoàn thành",
        "Hủy bỏ",
        "Đang xử lý",
        "Hoàn trả",
    ];
    const startDate = localStorage.getItem("startDate")
        ? dayjs(localStorage.getItem("startDate"), "DD-MM-YYYY")
        : null;
    const endDate = localStorage.getItem("endDate")
        ? dayjs(localStorage.getItem("endDate"), "DD-MM-YYYY")
        : null;
    const orderMethod = localStorage.getItem("LoaiDon") || "";
    const paymentMethod = localStorage.getItem("LoaiThanhToan") || "";
    const paymentStatus = localStorage.getItem("TTThanhToan") || "";
    const orderStatus = localStorage.getItem("TTDon") || "";
    const searchOrder = localStorage.getItem("TimKiem") || "";
    const pageOrder = localStorage.getItem("Trang") || "";

    const handleSearchOrder = (e: React.FormEvent) => {
        e.preventDefault();
        let currentParams = new URLSearchParams(window.location.search);
        if (orderStore.staticParamOrder.search !== "") {
            currentParams.set("search", orderStore.staticParamOrder.search);
        } else {
            currentParams.delete("search");
        }
        localStorage.setItem("currentUrl", currentParams.toString());
        // Điều hướng tới URL đã cập nhật
        navigate({
            search: currentParams.toString(),
        });
        orderStore.fetctAllListOder();
    };

    const handleChangeDate: RangePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        const start: string = dateString[0];
        const end: string = dateString[1];
        localStorage.setItem("startDate", start);
        localStorage.setItem("endDate", end);
        orderStore.staticParamOrder.startDate = start;
        orderStore.staticParamOrder.endDate = end;

        let currentParams = new URLSearchParams(window.location.search);
        if (
            orderStore.staticParamOrder.startDate &&
            orderStore.staticParamOrder.endDate !== ""
        ) {
            currentParams.set(
                "startDate",
                orderStore.staticParamOrder.startDate
            );
            currentParams.set("endDate", orderStore.staticParamOrder.endDate);
        } else {
            currentParams.delete("startDate");
            currentParams.delete("endDate");
        }
        localStorage.setItem("currentUrl", currentParams.toString());
        // Điều hướng tới URL đã cập nhật
        navigate({
            search: currentParams.toString(),
        });
        orderStore.fetctAllListOder();
    };

    const handleClearFilter = () => {
        window.scroll(0, 0);
        //* Clear params khi ma xoa loc
        orderStore.handeClose();
        navigate({
            search: createSearchParams({}).toString(),
        });
        orderStore.handeClose();
        localStorage.setItem("startDate", "");
        localStorage.setItem("endDate", "");
        localStorage.setItem("LoaiThanhToan", "");
        localStorage.setItem("TTThanhToan", "");
        localStorage.setItem("LoaiDon", "");
        localStorage.setItem("TTDon", "");
        localStorage.setItem("currentUrl", "");
        localStorage.setItem("TimKiem", "");
        localStorage.setItem("Trang", "");
        orderStore.fetctAllListOder();
    };

    const handleChangePage = (e: any) => {
        orderStore.staticParamOrder.page = e.selected;
        localStorage.setItem(
            "Trang",
            orderStore.staticParamOrder.page.toString()
        );
        let currentParams = new URLSearchParams(
            localStorage.getItem("currentUrl") || ""
        );

        if (orderStore.staticParamOrder.page !== 0) {
            currentParams.set(
                "page",
                (orderStore.staticParamOrder.page + 1).toString()
            );
        } else {
            currentParams.delete("page");
        }
        localStorage.setItem("currentUrl", currentParams.toString());

        navigate({
            search: currentParams.toString(),
        });

        orderStore.fetctAllListOder();
    };

    const handleSeeDetail = (item: any) => {
        navigate(`/don-hang/${item.id}`);
        orderStore.staticOrder.payment_status = `${item.payment_status}`;
        orderStore.staticOrder.order_status = `${item.status_id}`;
    };

    return (
        <>
            <div className="container">
                <div className="mainContentOrder d-flex">
                    <div className="filter">
                        <h1>Đơn hàng</h1>

                        <div className="filterWrapper">
                            <div className="filterOrder">
                                <div
                                    className="filterOrder-datePicker d-flex flex-column"
                                    style={{
                                        gap: "8px",
                                    }}
                                >
                                    <label>Chọn thời gian</label>
                                    <ConfigProvider locale={viVN}>
                                        <RangePicker
                                            placeholder={[
                                                "Bắt đầu",
                                                "Kết thúc",
                                            ]}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            format="DD-MM-YYYY"
                                            defaultValue={[startDate, endDate]}
                                            onChange={handleChangeDate}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>Loại thanh toán</label>
                                    <Radio.Group
                                        options={plainOptionsOrdermethod}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            const selectedIndex =
                                                plainOptionsOrdermethod.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex === 0) {
                                                orderStore.staticParamOrder.payment_method =
                                                    "COD";
                                                localStorage.setItem(
                                                    "LoaiThanhToan",
                                                    "COD"
                                                );
                                            } else if (selectedIndex === 1) {
                                                orderStore.staticParamOrder.payment_method =
                                                    "BANK_CASH";
                                                localStorage.setItem(
                                                    "LoaiThanhToan",
                                                    "BANK_CASH"
                                                );
                                            }

                                            let currentParams =
                                                new URLSearchParams(
                                                    window.location.search
                                                );
                                            if (
                                                orderStore.staticParamOrder
                                                    .payment_method !== ""
                                            ) {
                                                currentParams.set(
                                                    "payment_method",
                                                    orderStore.staticParamOrder
                                                        .payment_method
                                                );
                                            } else {
                                                currentParams.delete(
                                                    "payment_method"
                                                );
                                            }

                                            localStorage.setItem(
                                                "currentUrl",
                                                currentParams.toString()
                                            );

                                            navigate({
                                                search: currentParams.toString(),
                                            });

                                            orderStore.fetctAllListOder();
                                        }}
                                        value={
                                            !paymentMethod
                                                ? ""
                                                : paymentMethod === "COD"
                                                ? "Tiền mặt"
                                                : "Chuyển khoản"
                                        }
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>TT Thanh toán</label>
                                    <Radio.Group
                                        options={plainOptionsInfoPaymentMethod}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            const selectedIndex =
                                                plainOptionsInfoPaymentMethod.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex === 0) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "REFUND";
                                                localStorage.setItem(
                                                    "TTThanhToan",
                                                    "REFUND"
                                                );
                                            } else if (selectedIndex === 1) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "CANCEL";
                                                localStorage.setItem(
                                                    "TTThanhToan",
                                                    "CANCEL"
                                                );
                                            } else if (selectedIndex === 2) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "PENDING";
                                                localStorage.setItem(
                                                    "TTThanhToan",
                                                    "PENDING"
                                                );
                                            } else if (selectedIndex === 3) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "SUCCESS";
                                                localStorage.setItem(
                                                    "TTThanhToan",
                                                    "SUCCESS"
                                                );
                                            } else if (selectedIndex === 4) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "FAILED";
                                                localStorage.setItem(
                                                    "TTThanhToan",
                                                    "FAILED"
                                                );
                                            }

                                            let currentParams =
                                                new URLSearchParams(
                                                    window.location.search
                                                );
                                            if (
                                                orderStore.staticParamOrder
                                                    .payment_status !== ""
                                            ) {
                                                currentParams.set(
                                                    "payment_status",
                                                    orderStore.staticParamOrder
                                                        .payment_status
                                                );
                                            } else {
                                                currentParams.delete(
                                                    "payment_status"
                                                );
                                            }

                                            localStorage.setItem(
                                                "currentUrl",
                                                currentParams.toString()
                                            );

                                            navigate({
                                                search: currentParams.toString(),
                                            });

                                            orderStore.fetctAllListOder();
                                        }}
                                        value={
                                            !paymentStatus
                                                ? ""
                                                : paymentStatus === "CANCEL"
                                                ? "Hủy bỏ"
                                                : paymentStatus === "REFUND"
                                                ? "Hoàn trả"
                                                : paymentStatus === "PENDING"
                                                ? "Đang xử lý"
                                                : paymentStatus === "SUCCESS"
                                                ? "Hoàn thành"
                                                : paymentStatus === "FAILED"
                                                ? "Thất bại"
                                                : ""
                                        }
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>Loại đơn</label>
                                    <Radio.Group
                                        options={plainOptionsOderType}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            orderStore.staticParamOrder.order_type =
                                                value;
                                            localStorage.setItem(
                                                "LoaiDon",
                                                value
                                            );

                                            let currentParams =
                                                new URLSearchParams(
                                                    window.location.search
                                                );
                                            if (
                                                orderStore.staticParamOrder
                                                    .order_type !== ""
                                            ) {
                                                currentParams.set(
                                                    "order_type",
                                                    orderStore.staticParamOrder
                                                        .order_type
                                                );
                                            } else {
                                                currentParams.delete(
                                                    "order_type"
                                                );
                                            }

                                            localStorage.setItem(
                                                "currentUrl",
                                                currentParams.toString()
                                            );

                                            navigate({
                                                search: currentParams.toString(),
                                            });

                                            orderStore.fetctAllListOder();
                                        }}
                                        value={orderMethod}
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>TT đơn</label>
                                    <Radio.Group
                                        options={plainOptionsInfoOderType}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            const selectedIndex =
                                                plainOptionsInfoOderType.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex + 1 === 1) {
                                                orderStore.staticParamOrder.order_status =
                                                    "SUCCESS";
                                                localStorage.setItem(
                                                    "TTDon",
                                                    "SUCCESS"
                                                );
                                            } else if (
                                                selectedIndex + 1 ===
                                                2
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "CANCEL";
                                                localStorage.setItem(
                                                    "TTDon",
                                                    "CANCEL"
                                                );
                                            } else if (
                                                selectedIndex + 1 ===
                                                3
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "PENDING";
                                                localStorage.setItem(
                                                    "TTDon",
                                                    "PENDING"
                                                );
                                            } else if (
                                                selectedIndex + 1 ===
                                                4
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "REFUND";
                                                localStorage.setItem(
                                                    "TTDon",
                                                    "REFUND"
                                                );
                                            }

                                            let currentParams =
                                                new URLSearchParams(
                                                    window.location.search
                                                );
                                            if (
                                                orderStore.staticParamOrder
                                                    .order_status !== ""
                                            ) {
                                                currentParams.set(
                                                    "order_status",
                                                    orderStore.staticParamOrder
                                                        .order_status
                                                );
                                            } else {
                                                currentParams.delete(
                                                    "order_status"
                                                );
                                            }

                                            localStorage.setItem(
                                                "currentUrl",
                                                currentParams.toString()
                                            );

                                            navigate({
                                                search: currentParams.toString(),
                                            });

                                            orderStore.fetctAllListOder();
                                        }}
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                        value={
                                            !orderStatus
                                                ? ""
                                                : orderStatus === "SUCCESS"
                                                ? "Hoàn thành"
                                                : orderStatus === "CANCEL"
                                                ? "Hủy bỏ"
                                                : orderStatus === "PENDING"
                                                ? "Đang xử lý"
                                                : orderStatus === "REFUND"
                                                ? "Hoàn trả"
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                className="btnClearFilter"
                                onClick={() => handleClearFilter()}
                            >
                                Xóa lọc <span>X</span>
                            </button>
                        </div>
                    </div>
                    <div className="content d-flex flex-column">
                        <div className="contentSearch">
                            <div className="content-page-header-left">
                                <form
                                    className="content-page-header-search d-flex align-items-center"
                                    onSubmit={() => handleSearchOrder}
                                >
                                    <input
                                        type="text"
                                        name="keyword"
                                        id="keyword"
                                        placeholder="Nhập mã sản phẩm, số điện thoại"
                                        onChange={(e) => {
                                            orderStore.staticParamOrder.search =
                                                e.target.value;
                                            localStorage.setItem(
                                                "TimKiem",
                                                e.target.value
                                            );
                                        }}
                                        defaultValue={searchOrder}
                                    />
                                    <button type="submit">
                                        <img
                                            src="/assets/icon/search.svg"
                                            alt="search"
                                        />
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="contentListOrder">
                            <div className="table-responsive">
                                <table
                                    className="table table-striped"
                                    id="table-2"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: "20%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Đơn hàng
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Ngày
                                                <span
                                                    style={{
                                                        paddingLeft: "4px",
                                                    }}
                                                    onClick={() => {
                                                        orderStore.handleSortDateTime();
                                                        orderStore.fetctAllListOder();
                                                    }}
                                                >
                                                    <img
                                                        src="/assets/icon/sorting.svg"
                                                        alt=""
                                                        style={{
                                                            width: "14px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </span>
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Loại thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                TT thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Loại đơn
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                TT đơn
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Thao tác
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderStore.isLoading ? (
                                            <tr>
                                                <td colSpan={8}>
                                                    <Loading />
                                                </td>
                                            </tr>
                                        ) : orderStore.dataListOrder.length ===
                                          0 ? (
                                            <tr>
                                                <td colSpan={8}>
                                                    <NoContent />
                                                </td>
                                            </tr>
                                        ) : (
                                            orderStore.dataListOrder.map(
                                                (item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <p className="text">
                                                                        <span
                                                                            style={{
                                                                                paddingLeft:
                                                                                    "0px",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            <Link
                                                                                to={`/don-hang/${item.id}`}
                                                                            >
                                                                                {
                                                                                    item.transaction_id
                                                                                }
                                                                            </Link>
                                                                        </span>
                                                                    </p>
                                                                    <p className="text">
                                                                        <span
                                                                            style={{
                                                                                color: "#6777EF",
                                                                                paddingLeft:
                                                                                    "0px",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {item
                                                                                .customer
                                                                                .name ===
                                                                            "guest"
                                                                                ? "Khách lẻ"
                                                                                : item
                                                                                      .customer
                                                                                      .name}
                                                                        </span>
                                                                        -
                                                                        <span
                                                                            style={{
                                                                                color: "#6777EF",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {item
                                                                                .customer
                                                                                ?.phone ||
                                                                                "Chưa có số điện thoại"}
                                                                        </span>
                                                                    </p>
                                                                    <p className="text">
                                                                        <strong>
                                                                            Tổng
                                                                            đơn:
                                                                        </strong>
                                                                        <span
                                                                            className="price"
                                                                            style={{
                                                                                fontSize:
                                                                                    "14px",
                                                                            }}
                                                                        >
                                                                            {utils.formatCurrency(
                                                                                item.total
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className="price"
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        {utils.formatCurrency(
                                                                            item.total -
                                                                                item.amount_after_payment
                                                                        )}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}{" "}
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleTimeString(
                                                                        "en-GB"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <span className="ordermethod">
                                                                        {item.order_method ===
                                                                        "COD"
                                                                            ? "Tiền mặt"
                                                                            : "Chuyển khoản"}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.payment_status ===
                                                                    0 ? (
                                                                        <span className="infoPaymentmethod-refund">
                                                                            Hoàn
                                                                            trả
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      1 ? (
                                                                        <span className="infoPaymentmethod-cancel">
                                                                            Hủy
                                                                            bỏ
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      2 ? (
                                                                        <span className="infoPaymentmethod">
                                                                            Đang
                                                                            xử
                                                                            lý
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      3 ? (
                                                                        <span className="infoPaymentmethod-success">
                                                                            Hoàn
                                                                            thành
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      4 ? (
                                                                        <span className="infoPaymentmethod-failed">
                                                                            Thất
                                                                            bại
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.source_type ===
                                                                    "website" ? (
                                                                        <span className="orderType orderType-web">
                                                                            Website
                                                                        </span>
                                                                    ) : (
                                                                        <span className="orderType orderType-pos">
                                                                            Pos
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.status_id ===
                                                                    1 ? (
                                                                        <span className="infoOrdertype-success">
                                                                            Hoàn
                                                                            thành
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      2 ? (
                                                                        <span className="infoOrdertype-cancel">
                                                                            Hủy
                                                                            bỏ
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      3 ? (
                                                                        <span className="infoOrdertype-pending">
                                                                            Đang
                                                                            xử
                                                                            lý
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      4 ? (
                                                                        <span className="infoOrdertype-refund">
                                                                            Hoàn
                                                                            trả
                                                                        </span>
                                                                    ) : (
                                                                        "Chưa có dữ liệu"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="seeDetail"
                                                                        onClick={() =>
                                                                            handleSeeDetail(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        Xem ngay
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            )
                                        )}
                                    </tbody>
                                </table>
                                {orderStore.isLoading
                                    ? ""
                                    : Math.ceil(
                                          orderStore.metadata.total_page
                                      ) > 1 && (
                                          <ReactPaginate
                                              previousLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-left.svg"
                                                      alt="right"
                                                  />
                                              }
                                              nextLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-right.svg"
                                                      alt="right"
                                                  />
                                              }
                                              breakLabel={"..."}
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={3}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              pageCount={Math.ceil(
                                                  orderStore.metadata.total_page
                                              )}
                                              onPageChange={handleChangePage}
                                              forcePage={orderStore.staticParamOrder.page}
                                          />
                                      )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Order);
