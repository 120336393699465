import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { storebranchstore } from "../StoreBranchStore";
import { useLocation, useNavigate } from "react-router-dom";

const StatusFilter = ({ clearFilter }: { clearFilter: boolean }) => {
    const location = useLocation();
    const [filterOpen, setFilterOpen] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const navigate = useNavigate();

    if (clearFilter && selectedStatus !== "") {
        setSelectedStatus("");
    }

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();

        const params = {
            search: storebranchstore.staticParamsLocation.search,
            page: storebranchstore.staticParamsLocation.page.toString(),
            status: storebranchstore.staticParamsLocation.status,
        };

        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({ search: searchParams.toString() }, { replace: true });

        if (isSearch) {
            storebranchstore.fetchLocation();
        }
    };

    const handleSelectChange = (isSearch = true) => {
        updateUrlParams(isSearch);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        storebranchstore.staticParamsLocation.status =
            searchParams.get("status") || "";
        storebranchstore.staticParamsLocation.search =
            searchParams.get("search") || "";
        storebranchstore.staticParamsLocation.page = page || 0;
    }, [location.search]);

    return (
        <>
            <div className="filter-tab">
                <Button
                    className="filter-collapse-btn"
                    onClick={() => setFilterOpen(!filterOpen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={filterOpen}
                >
                    <span>Trạng thái</span>
                    <i className="fas fa-chevron-down"></i>
                </Button>

                <Collapse in={filterOpen}>
                    <div className="filter-tab__body">
                        <div className="filter-tab__option">
                            <input
                                type="radio"
                                id="statusActive"
                                name="storebranch-action"
                                value="active"
                                checked={
                                    storebranchstore.staticParamsLocation
                                        .status === "active"
                                }
                                onChange={() => {
                                    storebranchstore.staticParamsLocation.status =
                                        "active";
                                    handleSelectChange(true);
                                    storebranchstore.fetchLocation();
                                }}
                            />
                            <label
                                className="radio-button"
                                htmlFor={"statusActive"}
                            >
                                Hoạt động
                            </label>
                        </div>
                        <div className="filter-tab__option">
                            <input
                                type="radio"
                                id="statusDeactive"
                                name="storebranch-action"
                                value="deactive"
                                checked={
                                    storebranchstore.staticParamsLocation
                                        .status === "deactive"
                                }
                                onChange={() => {
                                    storebranchstore.staticParamsLocation.status =
                                        "deactive";
                                    handleSelectChange(true);
                                    storebranchstore.fetchLocation();
                                }}
                            />
                            <label
                                className="radio-button"
                                htmlFor={"statusDeactive"}
                            >
                                Ngừng hoạt động
                            </label>
                        </div>
                    </div>
                </Collapse>
            </div>
        </>
    );
};

export default observer(StatusFilter);
