import React, { useEffect, useRef, useState } from "react";
import { Divider, Modal } from "antd";
import "./ModalDetailProduct.scss";
import { utils } from "../../../../../../common/utils/Utils";
import { observer } from "mobx-react";
import { productInPosCartStore } from "../../../ProductInPosCartStore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "../../../../../../shared/components/loading/Loading";
import { useParams } from "react-router-dom";
import StorageService from "../../../../../../common/service/StorageService";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import Slider from "react-slick";

const ModalDetailProduct = () => {
  const [activeVariants, setActiveVariants] = useState<{
    [key: number]: number;
  }>({});

  const [nav1, setNav1] = useState<Slider | null>(null);
  const [nav2, setNav2] = useState<Slider | null>(null);

  let sliderRef1 = useRef<Slider | null>(null);
  let sliderRef2 = useRef<Slider | null>(null);

  const { postId } = useParams();
  productInPosCartStore.postId = postId;

  useEffect(() => {
    if (
      productInPosCartStore.dataImageFull.length > 0 &&
      sliderRef1.current &&
      sliderRef2.current
    ) {
      setNav1(sliderRef1.current);
      setNav2(sliderRef2.current);
    }
  }, [productInPosCartStore.dataImageFull]);

  const handleSliderSync = (index: number) => {
    if (sliderRef2.current) {
      sliderRef2.current.slickGoTo(index);
    }
  };

  useEffect(() => {
    if (productInPosCartStore.dataDetailProduct.id) {
      productInPosCartStore.getListVariant();
      productInPosCartStore.getProductByType("image");
    }
  }, [productInPosCartStore.dataDetailProduct.id]);

  useEffect(() => {
    if (productInPosCartStore.dataDetailProduct.id) {
      const fetchData = async () => {
        await productInPosCartStore.getProductByType("variant"); // mới đầu vào gọi để lấy full biến thể nó đang có

        const initialActiveVariants: { [key: number]: number } = {};
        const initialActiveVariantsName: { [key: number]: string } = {};

        if (productInPosCartStore.dataVariant) {
          productInPosCartStore.dataVariant.forEach((variant: any) => {
            const foundVariant = variant.childs.find((child: any) => {
              return productInPosCartStore?.dataDetailProduct?.attribute_ids?.includes(
                child.id.toString()
              );
            });

            if (foundVariant) {
              initialActiveVariants[variant.parent.id] = foundVariant.id;
              initialActiveVariantsName[variant.parent.id] = foundVariant.name;
            }
          });

          setActiveVariants(initialActiveVariants);
        }
      };

      fetchData();
    }
  }, [productInPosCartStore.dataDetailProduct.id]);

  useEffect(() => {
    const detailVariantId = selectedVariant?.id;

    if (
      productInPosCartStore.dataImageFull.length > 0 &&
      productInPosCartStore.arrVariantChoose.length > 0 &&
      detailVariantId
    ) {
      // Lọc những ảnh có id hợp lệ và tìm ảnh phù hợp
      const validImages = productInPosCartStore.dataImageFull.filter(
        (item: any) => item.id !== null
      );
      const selectedImage = validImages.find(
        (item: any) => item.id === detailVariantId
      );

      if (selectedImage) {
        const imageIndex =
          productInPosCartStore.dataImageFull.indexOf(selectedImage);

        if (sliderRef1.current && sliderRef2.current && imageIndex !== -1) {
          sliderRef1?.current?.slickGoTo(imageIndex);
          sliderRef2?.current?.slickGoTo(imageIndex);
        }
      } else {
        console.warn("Không tìm thấy ảnh khớp với variant được chọn.");
      }
    }
  }, [
    productInPosCartStore.arrVariantChoose,
    productInPosCartStore.dataImageFull,
  ]);

  useEffect(() => {
    const valuesAsStrings = Object.values(activeVariants).map((value) =>
      value.toString()
    );
    productInPosCartStore.arrVariantChoose = valuesAsStrings;
  }, [activeVariants]);

  const handleDecrease = () => {
    if (productInPosCartStore.dataFormAddCart.quantity > 1) {
      productInPosCartStore.dataFormAddCart.quantity -= 1;
    }
  };

  const handleIncrease = () => {
    productInPosCartStore.dataFormAddCart.quantity += 1;
  };

  const handleChange = (e: any) => {
    const value = Number(e.target.value);
    productInPosCartStore.dataFormAddCart.quantity = value; // Cập nhật trực tiếp trong store
  };

  const handleChooseVariant = (parentId: number, childId: number) => {
    setActiveVariants((prevState) => ({
      ...prevState,
      [parentId]: childId, // Cập nhật trạng thái active cho parent tương ứng
    }));
  };

  useEffect(() => {
    const storedCart = StorageService.getArrayFromLS(`cartPos${postId}`);
    if (storedCart) {
      productInPosCartStore.dataListProInCart = [...storedCart];
    }
  }, [postId]);

  useEffect(() => {
    // khi là biến thể đơn giản set về {} để show lên giá
    if (productInPosCartStore.isSimplePro) {
      setActiveVariants({});
    }
  }, [productInPosCartStore.isSimplePro]);

  const selectedAttributes = Object.values(activeVariants);
  const selectedVariant = productInPosCartStore.dataFullVariant.find(
    (variant) =>
      JSON.stringify(variant.attribute_ids.slice().sort()) ===
      JSON.stringify(selectedAttributes.slice().sort()) // chuyển về so sánh kiểu chuỗi
  );

  return (
    <Modal
      title="Thông tin sản phẩm"
      footer={null}
      wrapClassName="ModalDetailProduct"
      open={productInPosCartStore.isOpenModalDetailProduct}
      onCancel={() => {
        productInPosCartStore.isOpenModalDetailProduct = false;
        // setActiveVariants({})
        productInPosCartStore.arrVariantChoose = [];
        productInPosCartStore.dataFormAddCart.quantity = 1;
      }}
    >
      <>
        {productInPosCartStore.isLoading ? (
          <div className="loadingPreviewProduct">
            <Loading></Loading>
          </div>
        ) : (
          <div className="ModalDetailProduct-container">
            <div className="boxImage">
              <div>
                {productInPosCartStore.dataImageFull.length > 0 && (
                  <Slider
                    arrows={false}
                    asNavFor={(nav2 as any) || undefined}
                    ref={(slider) => (sliderRef1.current = slider)}
                    afterChange={handleSliderSync}
                  >
                    {productInPosCartStore.dataImageFull.map(
                      (item: any, index: number) => (
                        <div key={index} className="big-Image">
                          <img src={item?.thumbnail} alt="img" />
                        </div>
                      )
                    )}
                  </Slider>
                )}
              </div>
              <div className="boxImage-thumbnail">
                {productInPosCartStore.dataImageFull.length > 0 && (
                  <Slider
                    asNavFor={(nav1 as any) || undefined}
                    ref={(slider) => (sliderRef2.current = slider)}
                    slidesToShow={4}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    beforeChange={(current, next) => {
                      sliderRef1.current?.slickGoTo(next);
                    }}
                  >
                    {productInPosCartStore.dataImageFull.map(
                      (item: any, index: number) => (
                        <div key={index} className="thumnail-Image">
                          <img src={item?.thumbnail} alt="img" />
                        </div>
                      )
                    )}
                  </Slider>
                )}
              </div>
            </div>
            <div className="productInfor">
              <h4
                style={{ maxWidth: "428px" }}
                title={productInPosCartStore.dataDetailProduct.title}
                className="multiline-ellipsis"
              >
                {productInPosCartStore.dataDetailProduct.title}
              </h4>

              {/* {selectedVariant?.price_compare && selectedVariant?.price ? ( */}
              <div className="productInfor-price">
                <span>
                  {utils.formatCurrency(selectedVariant?.price_compare)}
                </span>
                <h1>{utils.formatCurrency(selectedVariant?.price)}</h1>
              </div>
              {/* ) : (
                ""
              )} */}

              <div className="productInfor-quantity">
                <span onClick={handleDecrease}>
                  <i className="fas fa-minus"></i>
                </span>
                <input
                  type="number"
                  min={1}
                  value={productInPosCartStore.dataFormAddCart.quantity || 1}
                  onChange={handleChange}
                />
                <span onClick={handleIncrease}>
                  <i className="fas fa-plus"></i>
                </span>
              </div>
              {productInPosCartStore.dataDetailProduct.is_variation
                ? productInPosCartStore.dataVariant.length > 0 &&
                  productInPosCartStore.dataVariant.map((item: any) => (
                    <div key={item?.parent?.id}>
                      <div className="productInfor-variant">
                        <div className="productInfor-variant__title">
                          <h4>{item?.parent?.name}</h4>
                        </div>
                        <div className="productInfor-variant__body">
                          {item.childs.length > 0 &&
                            item.childs.map((child: any) => (
                              <div
                                onClick={() => {
                                  handleChooseVariant(item.parent.id, child.id);
                                }}
                                className={`productInfor-variant__content ${
                                  activeVariants[item.parent.id] === child.id
                                    ? "active"
                                    : ""
                                }`}
                                key={child.id}
                              >
                                {child.name}
                              </div>
                            ))}
                        </div>
                      </div>
                      <Divider
                        style={{
                          backgroundColor: "#ECF2F7",
                          height: "1px",
                          width: "100%",
                          margin: 0,
                        }}
                      ></Divider>
                    </div>
                  ))
                : ""}

              <div
                className={`productInfor-addCart ${
                  !selectedVariant ? "disableAddtoCart" : ""
                }`}
                onClick={() => {
                  const newCartItem = {
                    ...productInPosCartStore.dataFormAddCart,
                    variant: {
                      ...productInPosCartStore.dataFormAddCart.variant,
                      id: selectedVariant.id || null,
                      name: selectedVariant.attribute_compare,
                    },
                    productId: selectedVariant.product_id,
                    discount: selectedVariant.discount || 0,
                    productName: productInPosCartStore.dataDetailProduct.title,
                    price: selectedVariant.price,
                    unit: selectedVariant.unit,
                    sku: selectedVariant.sku,
                  };

                  const currentCart =
                    StorageService.getArrayFromLS(`cartPos${postId}`) || [];

                  const filterProduct = currentCart.find(
                    (item: any) =>
                      item.productId === newCartItem.productId &&
                      item.variant.id === newCartItem.variant.id
                  );
                  if (filterProduct) {
                    filterProduct.quantity =
                      Number(filterProduct.quantity) + newCartItem.quantity;
                    productInPosCartStore.flagActiveVariant = true; // mục đích chỉ để active highlight productVariant trong cart
                    productInPosCartStore.activeVariantProduct =
                      selectedVariant.id;
                  } else {
                    productInPosCartStore.flagActiveVariant = false;
                    currentCart.unshift(newCartItem);
                  }

                  // const existingItemIndex = currentCart.findIndex(
                  //   (item) => item.productId === newCartItem.productId && item.variant.id === newCartItem.variant.id
                  // );

                  // if (existingItemIndex > -1) {
                  //   // nếu đã tồn tại thì chỉ cộng số lượng
                  //   currentCart[existingItemIndex].quantity += newCartItem.quantity;
                  // } else {
                  //   // thêm vào mảng mới
                  //   currentCart.unshift(newCartItem);
                  // }
                  productInPosCartStore.dataListProInCart = currentCart;

                  StorageService.saveArayLS(`cartPos${postId}`, currentCart);
                  toastUtils.success("Thêm vào giỏ hàng thành công", "");
                }}
              >
                <i className="fas fa-shopping-cart"></i>
                <p>Thêm vào giỏ hàng</p>
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default observer(ModalDetailProduct);
