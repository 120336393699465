import { makeAutoObservable } from "mobx";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { infoService } from "./InfoService";
import StorageService from "../../../common/service/StorageService";
const storageService = new StorageService();

class InfoStore {
    isLoadingUpdateInfo: boolean = false;
    isLoadingGetInfo: boolean = false;
    isLoadingChangePassword: boolean = false;
    isAvatar: any = "";
    listDataUser: any = [];
    dataUser: any = {
        name: "",
        email: "",
        avatar: "",
    };
    dataChangePassword: {
        oldpassword: string;
        password: string;
        confirm: string;
    } = {
        oldpassword: "",
        password: "",
        confirm: "",
    };
    listBanner: string[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    async getBanner() {}
    async fetchGetInfoUser() {
        this.isLoadingGetInfo = true;
        const result = await infoService.fetchGetInfoUser();
        this.isLoadingGetInfo = false;
        if (result.status === HttpStatusCode.Ok) {
            if (JSON.stringify(result.body.data) !== this.dataUser) {
                this.dataUser = result.body.data;
            }
            if (JSON.stringify(result.body.data) !== this.listDataUser) {
                this.listDataUser = [result.body.data];
                storageService.setNameUser(result.body.data.name);
                storageService.setAvatarUser(result.body.data.avatar);
            }
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchChangeInfoUser() {
        this.isLoadingUpdateInfo = true;
        const params = {
            name: this.dataUser.name,
            email: this.dataUser.email,
            avatar: this.dataUser.avatar,
        };
        const result = await infoService.fetchChangeInfoUser(params);
        this.isLoadingUpdateInfo = false;
        if (result.status === HttpStatusCode.Ok) {
            // storageService.setNameUser(this.dataUser.name);
            // storageService.setAvatarUser(this.dataUser.avatar);
            // Lưu thông tin thông báo vào local
            // localStorage.setItem("toastMessage", "Cập nhật thành công");
            // window.location.href = "/thong-tin-tai-khoan";
            this.fetchGetInfoUser();
            toastUtils.success("Cập nhật thành công", "");
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchChangePassword(onSuccessCallback: () => void) {
        this.isLoadingChangePassword = true;
        let { oldpassword, password, confirm } = this.dataChangePassword;
        const params = {
            old_password: oldpassword,
            new_password: password,
            confirm_new_password: confirm,
        };
        const result = await infoService.fetchChangePassword(params);
        this.isLoadingChangePassword = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thay đổi mật khẩu thành công", "");
            this.dataChangePassword.oldpassword = "";
            this.dataChangePassword.password = "";
            this.dataChangePassword.confirm = "";
            onSuccessCallback();
        } else {
            if (
                result.body.message.password === "the_old_password_is_incorrect"
            ) {
                toastUtils.error("Mật khẩu cũ không đúng", "");
            } else {
                toastUtils.error("Đã có lưu xảy ra", "");
            }
        }
    }
    changeFileAvatar = (pathFile: string) => {
        this.dataUser.avatar = pathFile;
    };
    handleCloseAvatar = () => {
        this.dataUser.avatar = "";
        // this.listDataUser[0].avatar = "";
    };
}

export const infoStore = new InfoStore();
