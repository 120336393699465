import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link, useParams} from "react-router-dom";
import {Button, Form, Input, Space} from "antd";
import {productStore} from "../ProductStore";
import EditProductMenu from "./components/EditProductMenu";
import UploadFile from "../../../../shared/components/form/UploadFile";
import {handleFile, handleFileMultiple} from "../../../../common/helpers/UploadFileHelper";
import UploadFileMultiple from "../../../../shared/components/form/UploadFileMultiple";
import {toastUtils} from "../../../../common/utils/Toastutils";

const EditProductImagePage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "image";
    useEffect(() => {
        productStore.fetchGetImageProduct().then();
    }, []);
    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            if(productStore.staticUpdateImage.preview == ''){
                                toastUtils.warning("Hình ảnh không được để trống", "");
                                return false
                            }
                            if(productStore.staticUpdateImage.galleries.length == 0){
                                toastUtils.warning("Hình ảnh bộ sưu tập không được để trống", "");
                                return false
                            }
                            productStore.fetchUpdateImageProduct()
                        }}
                        style={{maxWidth: 600}}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="image"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <UploadFile
                                        text={`hình ảnh`}
                                        description={`Hình ảnh sản phẩm (kích thước 800 x 800)`}
                                        file={productStore.staticUpdateImage.preview}
                                        onIconRemove={productStore.handleCloseEditProductPreview}
                                        onFileChange={(e) => handleFile(e, productStore.changeFileEditProductPreview)}
                                    />

                                    <UploadFileMultiple
                                        text={`hình ảnh bộ sưu tập`}
                                        description={`Hình ảnh bộ sưu tập (kích thước 800 x 800)`}
                                        file={productStore.staticUpdateImage.galleries}
                                        onIconRemove={(index) => productStore.handleCloseMultiEditProductImages(index)}
                                        onFileChange={(e) => handleFileMultiple(e, productStore.changeFileMultiEditProductImages)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Hoàn tất
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
export default observer(EditProductImagePage);