import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link, useParams} from "react-router-dom";
import {Button, Form, Input, Space} from "antd";
import {productStore} from "../ProductStore";
import EditProductMenu from "./components/EditProductMenu";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import {values} from "mobx";
import {handleKeyPress} from "../../../../common/utils/Utils";
import {toastUtils} from "../../../../common/utils/Toastutils";
const EditProductQrcodePage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "qrcode";
    useEffect(() => {
        productStore.fetchGetQrcodeProduct().then();
    }, []);
    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            if(values.height > 500){
                                toastUtils.warning('Kích thước chiều cao không được quá 500')
                                return false
                            }
                            if(values.width > 500){
                                toastUtils.warning('Kích thước chiều rộng không được quá 500')
                                return false
                            }
                            productStore.fetchUpdateQrcodeProduct();
                        }}
                        style={{maxWidth: 600}}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="qrcode"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="grid-3-1 gap-20">
                                        <div>
                                            <div className="item-form" style={{marginBottom: '20px'}}>
                                                <Form.Item
                                                    name="height"
                                                    label="Chiều cao hình ảnh QR code"

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Không được để trống!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="item-form">
                                                <Form.Item
                                                    name="width"
                                                    label="Chiều rộng hình ảnh QR code"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Không được để trống!",
                                                        },
                                                    ]}
                                                >
                                                    <Input    onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-form item-form-qr-code">
                                            <div className="image-barcode">
                                                <img
                                                    src={productStore.dataProductQrcode ? productStore.dataProductQrcode : '/assets/images/empty_img.png'}
                                                    alt="qrcode"/>

                                            </div>
                                        </div>
                                    </div>

                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                Tạo mới
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        </>
    )
}
export default observer(EditProductQrcodePage)