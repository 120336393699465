import { observer } from "mobx-react"
import { posStore } from "./PosStore";
import Loading from "../../shared/components/loading/Loading";
import { Dropdown } from "react-bootstrap";
import NoContent from "../../shared/components/NoContent";
import { showDelete } from "../../shared/components/showDelete";
import React, { useEffect, useState } from "react";
import { Select, Switch } from "antd";
import "./styles/pos.css"
import StatusFilterTab from "./components/StatusFilterTab";
import LocationFilterTab from "./components/LocationFilterTab";
import { createSearchParams, useNavigate } from "react-router-dom";
import ActionModal from "./components/ActionModal";
import ActionFilterTab from "./components/ActionFilterTab";
import { toastUtils } from "../../common/utils/Toastutils";
import {categoryProductStore} from "../products/categoryProduct/CategoryProductStore";
import ReactPaginate from "react-paginate";

const Pos = ()=>{
    // const [posStore.selectedPosInTable, posStore.selectedPosInTable] = useState<any[]>([])
    const navigate = useNavigate();
    function onSubmitSearch(event:any){
        const searchKeyword = posStore.staticParamPos.search;
        event.preventDefault();
        const params: any = {}
        if(searchKeyword !== ''){
            params.keyword = `${searchKeyword}`;
            console.log(params)
        }
        navigate({
            search: createSearchParams(params).toString()
        })
        posStore.getPosWithRequirement(true)
    }
    function handleSelect(value: boolean, name: string) {
        if (value) {
            posStore.selectedPosInTable = ([...posStore.selectedPosInTable, name]);
        } else {
            posStore.selectedPosInTable = (posStore.selectedPosInTable.filter((item) => item !== name));
        }
    }
    function selectAllTargetInTable(value: boolean){
        if (value) {
            posStore.selectedPosInTable = (posStore.listPos.map((item: any) => item.id));
        } else {
            posStore.selectedPosInTable = ([]);
        }
    }
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (posStore.staticParamPos.search !== "")
            params.keyword =
                posStore.staticParamPos.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        posStore.staticParamPos.page = selectedPage;
        posStore.getPosWithRequirement(true);
    };
    function onHandleKeyDown(event:any){
        if(event.key === 'Enter'){
            onSubmitSearch(event)
        }
    }
    useEffect(()=>{
        posStore.getStoreAll().then()
        posStore.getPosWithRequirement().then()
    },[])
    return(
        <div className="container">
            <div className="mainContentPos">
                <div className="header">
                    <div className="header__title">Danh sách máy pos</div>
                    <form className="header__search" onSubmit={onSubmitSearch}>
                        <input id="search" type="text" onKeyDown={(event: any) => {
                            onHandleKeyDown(event)
                        }} value={posStore.staticParamPos.search ? posStore.staticParamPos.search : ''}
                               onChange={(event) => posStore.staticParamPos.search = event.target.value}
                               placeholder="Nhập tên máy pos"/>
                        <button type="submit"><img src="/assets/icon/search.svg" alt=""/></button>
                    </form>
                    <button className="btn-add-modal" onClick={() => {
                        posStore.modalOpen = true;
                        posStore.isCreating = true;
                        posStore.handleClearContent();
                        posStore.modalName = "Thêm mới máy Pos"
                    }}>
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting"/>
                        </span>
                    </button>
                </div>
                <div className="body-page">
                    <div className="filter-tab-wrapper">
                        {/*<ActionFilterTab/>*/}
                        <StatusFilterTab/>
                        <LocationFilterTab/>
                    </div>
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                <tr>
                                    <th style={{width: '5%', textAlign: 'center'}}>
                                        <input type="checkbox" onChange={(e) => {
                                            selectAllTargetInTable(e.target.checked);
                                        }} checked={posStore.selectedPosInTable.length === posStore.listPos.length}/>
                                    </th>
                                    <th style={{width: '30%'}}>Tên máy pos</th>
                                    <th style={{width: '30%'}}>Địa điểm</th>
                                    <th style={{width: '8%'}}>Trạng thái</th>
                                    <th style={{width: '20%'}}>Ngày tạo</th>
                                    <th style={{width: "15%"}}>Thao tác</th>
                                </tr>
                                </thead>
                                <tbody>

                                    {posStore.loadingDataTable === true ? (<tr>
                                            <td colSpan={6}><Loading/></td>
                                        </tr>) :
                                        (<>
                                            {posStore.listPos.length === 0 ? (<tr>
                                                <td colSpan={5}><NoContent/></td>
                                            </tr>) : (<>
                                                {posStore.listPos.map(
                                                    (item: any, index: number) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td style={{textAlign: 'end'}}>
                                                                    <div style={{textAlign: 'center'}}>
                                                                        <input type="checkbox" name="ids[]"
                                                                               value={item.id}
                                                                               checked={posStore.selectedPosInTable.includes(item.id)}
                                                                               onChange={(e: any) => handleSelect(e.target.checked, item.id)}/>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span title={item.name}>{item.name}</span>
                                                                </td>
                                                                <td>{posStore.listStore.find((store) => store.id == item.location_id)?.name}</td>
                                                                <td>
                                                                    <Switch size="small"
                                                                            defaultChecked={item.status === 1 ? true : false}
                                                                            onChange={(event) => {
                                                                                posStore.handleSwitchStatusPos(item, event);
                                                                                item.status = (event === true ? 1 : 0)
                                                                            }}/>
                                                                </td>
                                                                <td>
                                                                    <p>{new Date(item.created_at).toLocaleDateString('en-GB')}</p>
                                                                </td>
                                                                <td>
                                                                    <Dropdown align={"start"}
                                                                              className="action-table">
                                                                        <Dropdown.Toggle>
                                                                            Thao tác
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => {
                                                                                posStore.isCreating = false;
                                                                                posStore.modalName = 'Sửa máy POS'
                                                                                posStore.staticDataPos.name = item.name;
                                                                                posStore.staticDataPos.id = String(item.id);
                                                                                posStore.staticDataPos.status = String(item.status);
                                                                                posStore.staticDataPos.store_id = String(item.location_id);
                                                                                posStore.modalOpen = true;
                                                                            }}>
                                                                                <i className="fa fa-edit"></i>
                                                                                Sửa
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => {
                                                                                showDelete.showDeleteConfirm('Xác nhận xoá thuộc tính', `Bạn chắc chắn muốn xoá máy POS "${item.name}" không?`, () => {
                                                                                    posStore.deletePos()
                                                                                });
                                                                                posStore.staticDataPos.id = item.id
                                                                            }}>
                                                                                <i className="fa fa-trash"></i>
                                                                                Xóa
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                            </>)}
                                        </>)}

                                </tbody>
                            </table>
                            {posStore.loadingDataTable
                                ? ""
                                : Math.ceil(
                                posStore.metadata.total_page
                            ) > 1 && (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                        posStore.metadata
                                            .total_page
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={
                                        posStore.staticParamPos.page
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ActionModal/>
        </div>
    )
}
export default observer(Pos)
