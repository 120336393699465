import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link, useParams} from "react-router-dom";
import {Button, Form, Space} from "antd";
import {productStore} from "../ProductStore";
import {toastUtils} from "../../../../common/utils/Toastutils";
import EditProductMenu from "./components/EditProductMenu";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import {marginBottom} from "html2canvas/dist/types/css/property-descriptors/margin";

const EditProductBarcodePage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "barcode";
    useEffect(() => {
        productStore.fetchGetBarcodeProduct().then();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            variant_id: productStore.variantIdBarcode,
        });
    }, [productStore.variantIdBarcode, form]);
    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            productStore.fetchUpdateBarcodeProduct()
                        }}
                        style={{maxWidth: 600}}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="barcode"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="grid-3-1 gap-20">
                                        <div>
                                            <div className="item-form" style={{marginBottom: '20px'}}>
                                                <Form.Item name="variant_id" label="Chọn biến thể của sản phẩm"
                                                           rules={[{required: true, message: 'Không được để trống!'}]}>
                                                    <SelectCustom
                                                        list={productStore.dataProductBarcode}
                                                        placeholder={`-- Chọn --`}
                                                        onChange={(value: any) => productStore.variantIdBarcode = value}
                                                        selected={() => {
                                                            const variantIdBarcode = productStore.variantIdBarcode;
                                                            return variantIdBarcode || undefined;
                                                        }}
                                                        getLabel={(item: any) => item.sku}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <Form.Item>
                                                <Space>
                                                    <Button type="primary" htmlType="submit">
                                                        Tạo mới
                                                    </Button>
                                                </Space>
                                            </Form.Item>
                                        </div>
                                        <div className="item-form">
                                            <div className="image-barcode">
                                                <img src={productStore.dataProductBarcode?.find((item: any) => item.id == productStore.variantIdBarcode)?.barcode_img ? productStore.dataProductBarcode?.find((item: any) => item.id == productStore.variantIdBarcode)?.barcode_img : '/assets/images/empty_img.png'}
                                                    alt=""/>

                                            </div>
                                            <p>{productStore.dataProductBarcode?.find((item: any) => item.id == productStore.variantIdBarcode)?.barcode_code ? 'Barcode: ' + productStore.dataProductBarcode?.find((item: any) => item.id == productStore.variantIdBarcode)?.barcode_code : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        </>
    )
}
export default observer(EditProductBarcodePage)