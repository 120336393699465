import {observer} from "mobx-react";
import React from "react";
import {dashboardStore} from "../../DashboardStore";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
interface ProductChartProps {
    dataTopSellers: any[];
}
const ProductChart: React.FC<ProductChartProps> = ({ dataTopSellers }) =>{

    const listCusomerChart = [
        {type:'total',value:'Theo doanh thu'},
        {type:'qty',value:'Theo số lượng'},
    ]
    const options = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const,
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
            },
        },
    };
    let labels: any[] = [];
    let dataStore: any[] = [];
    dataTopSellers.map((dataProduct:any) => {
        if(dataProduct?.info?.options?.attribute_compare){
            labels.push(dataProduct?.info?.options?.productName + ' - ' + dataProduct?.info?.options?.attribute_compare );
        }else{
            labels.push(dataProduct?.info?.options?.productName);
        }

        if(dashboardStore.typeTopSellers == 'total'){
            dataStore.push(dataProduct.total);
        }else{
            dataStore.push(dataProduct.qty);
        }
    })

    const data = {
        labels,
        datasets: [
            {
                label: '',
                data: dataStore,
                borderColor: '#0E72ED',
                backgroundColor: '#0E72ED',
            },
        ],
    };

    return(
        <div id="customerChart">
            <div className="saleChartHeader">
                <p>TOP 10 SẢN PHẨM BÁN CHẠY</p>
                <ul>
                    {listCusomerChart.map((item: any, index) => (
                        <li>
                            <button
                                className={`item-type-sales' ${dashboardStore.typeTopSellers == item.type ? 'active' : ''}`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    dashboardStore.typeTopSellers = item.type
                                    dashboardStore.fetchDataTopSellers()
                                }}
                                >
                                {item.value}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="chart">
                <Bar options={options} data={data} />
            </div>
        </div>
    )
}
export default observer(ProductChart)