import React, { useEffect, useState } from "react";
import "./BoxFilterDiningTablesNew.scss";
import { observer } from "mobx-react";
import { Button, Collapse } from "react-bootstrap";
import { diningTablesStore } from "../../DiningTableStore";
import { useLocation, useNavigate } from "react-router-dom";

const BoxFilterDiningTablesNew = () => {
  const [filterOpen, setFilterOpen] = useState(true);
  const [filterOpenRoom, setFilterOpenRoom] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    diningTablesStore.dataFilterDiningTables.store_id =
      Number(searchParams.get("store_id")) || null;
    diningTablesStore.dataFilterDiningTables.floor_room_id =
      Number(searchParams.get("floor_room_id")) || null;
  }, [location.search]);

  const updateUrlParams = (isSearchTriggered = false) => {
    const searchParams = new URLSearchParams();

    if (diningTablesStore.dataFilterDiningTables.store_id) {
      searchParams.set(
        "store_id",
        diningTablesStore.dataFilterDiningTables.store_id
      );
    }
    if (diningTablesStore.dataFilterDiningTables.floor_room_id) {
      searchParams.set(
        "floor_room_id",
        diningTablesStore.dataFilterDiningTables.floor_room_id
      );
    }

    diningTablesStore.staticParamDiningTable.page = 0;

    navigate({ search: searchParams.toString() }, { replace: true });

    if (isSearchTriggered) {
      diningTablesStore.fetchAllDiningTablesList();
    }
  };

  // tránh trùng với get room trong modal add Edit
  useEffect(()=>{
    diningTablesStore.fetchAllfloorRoom("filter");
  },[diningTablesStore.storeIdFilter])

  const handleSelectChange = (isSearchTriggered = true) => {
    updateUrlParams(isSearchTriggered); 
  };


  const dataFloorRoomFilter =
    diningTablesStore.dataFloorRoomFilter?.length > 0
      ? diningTablesStore.dataFloorRoomFilter?.map((item: any) => ({
          id: item.id,
          name: `${item.parent ? `${item?.parent?.name} / ` : ""}${item?.name}`,
        }))
      : [];

  const dataLocation =
    diningTablesStore.dataLocation?.length > 0
      ? diningTablesStore.dataLocation?.map((item: any) => ({
          id: item.id,
          name: item.name,
        }))
      : [];

  return (
    <div className="BoxFilterDiningTablesNew">
      <div className="filter-tab-wrapper">
        <div className="filter-tab">
          <Button
            className="filter-collapse-btn"
            onClick={() => setFilterOpen(!filterOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={filterOpen}
          >
            <span>Địa điểm</span>
            <i className="fas fa-chevron-down"></i>
          </Button>

          <Collapse in={filterOpen}>
            <div className="filter-tab__body">
              {dataLocation.map((item: any, index: number) => (
                <div className="filter-tab__option" key={index}>
                  <input
                    checked={Number(diningTablesStore.dataFilterDiningTables.store_id) === item.id}
                    value={item.id}
                    type="radio"
                    name="store_id"
                    id={item.id}
                    onChange={() => {
                      diningTablesStore.dataFilterDiningTables.store_id =
                      Number(item.id);
                      diningTablesStore.dataFilterDiningTables.floor_room_id = null; 
                      diningTablesStore.storeIdFilter = Number(item.id);
                      setFilterOpenRoom(true)
                      handleSelectChange(true);
                    }}
                  />
                  <label className="radio-button" htmlFor={item.id}>
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          </Collapse>
        </div>

        <div className="filter-tab">
          <Button
            className="filter-collapse-btn"
            onClick={() => setFilterOpenRoom(!filterOpenRoom)}
            aria-controls="example-collapse-text"
            aria-expanded={filterOpenRoom}
          >
            <span>Tầng Phòng</span>
            <i className="fas fa-chevron-down"></i>
          </Button>

          <Collapse in={filterOpenRoom}>
            <div className="filter-tab__body">
              {dataFloorRoomFilter.map((item: any, index: number) => (
                <div className="filter-tab__option" key={index}>
                  <input
                    checked={Number(diningTablesStore.dataFilterDiningTables.floor_room_id) === item.id}
                    value={item.id}
                    type="radio"
                    name="floor_room_id"
                    id={item.id}
                    onChange={() => {
                      diningTablesStore.dataFilterDiningTables.floor_room_id =
                      Number(item.id);
                      handleSelectChange(true); 

                    }}
                  />
                  <label className="radio-button" htmlFor={item.id}>
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
       
      </div>
    </div>
  );
};

export default observer(BoxFilterDiningTablesNew);
