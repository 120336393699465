import {observer} from "mobx-react";
import React, {useState} from "react";
import Collapse from 'react-bootstrap/Collapse';
import {Button} from "react-bootstrap";
import {productStore} from "../../ProductStore";
import {brandStore} from "../../../brand/BrandStore";

const FilterCategoryProduct = () => {
    const [openFilterCategory, setOpenFilterCategoryProduct] = useState(true);
    const [selectedCategory, setSelected] = useState<string[]>([]);
    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selectedCategory, name]);
            productStore.staticDataListProduct.category_id = [...selectedCategory, name];
        } else {
            setSelected(selectedCategory.filter((item) => item !== name));
            productStore.staticDataListProduct.category_id = selectedCategory.filter((item) => item !== name);
        }
        productStore.fetchListProduct(true)
    }
    function selectAll(value: boolean) {
        if (value) {
            setSelected(productStore.listCategory.map((item: any) => item.id));
            productStore.staticDataListProduct.category_id = productStore.listCategory.map((item: any) => productStore.staticDataListProduct.category_id.push(item.id));
        } else {
            setSelected([]);
            productStore.staticDataListProduct.category_id = [];
        }
        productStore.fetchListProduct(true)
    }
    const allSelected = selectedCategory.length === productStore.listCategory.length;
    return (
        <div className="filter-category-product filter-goods-manager">
            <Button onClick={() => setOpenFilterCategoryProduct(!openFilterCategory)}
                    aria-controls="example-collapse-text" aria-expanded={openFilterCategory}>
                Danh mục
            </Button>
            <Collapse in={openFilterCategory}>
                <div className="listCategoryProduct">
                    <label className="radio-button">
                        <input type="checkbox" name="category_product" value="all"  checked={allSelected}
                               onChange={(e) => selectAll(e.target.checked)}/>
                        <span className="radio-tick"></span>
                        Tất cả
                    </label>
                    {productStore.listCategory.map((item:any,index:number) => (
                        <label className="radio-button">
                            <input type="checkbox"
                                   name="category_product[]"
                                   value={item.id}
                                   checked={selectedCategory.includes(item.id)}
                                   onChange={(e) => handleSelect(e.target.checked, item.id)}
                            />
                            <span className="radio-tick"></span>
                            <p>{item.name}</p>
                        </label>
                    ))}
                </div>
            </Collapse>
        </div>
    )
}
export default observer(FilterCategoryProduct);