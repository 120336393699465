import React, { useEffect, useState } from "react";
import LayoutSettings from "../components/layout/LayoutSettings";
import "./styles/AdminPage.scss";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import { Popover, Switch } from "antd";
import { observer } from "mobx-react";
import { adminStore } from "./AdminStore";
import { toJS } from "mobx";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import FilterAdmin from "./components/FilterAdmin";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import AddEditStaff from "./components/modal/addEditStaff/AddEditStaff";
import { showDelete } from "../../../shared/components/showDelete";
import ChangePassword from "./components/modal/changePass/ChangePassword";
import EditPermission from "./components/modal/editPermission/EditPermission";
const AdminPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    adminStore.getListStaff();
    adminStore.getListRole();
  }, []);

  const handleChangePage = (e: any) => {
    adminStore.staticParam.page = e.selected;
    const searchParams = new URLSearchParams(location.search);

    searchParams.set("page", (adminStore.staticParam.page + 1).toString());
    navigate({
      search: searchParams.toString(),
    });

    adminStore.getListStaff();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const content = (item: any) => {
    return (
      <div className="popOver-adminPage__content">
        {!item.super_admin && (
          <div
            className="content-box"
            style={{ textDecoration: "none", color: "#061020" }}
            onClick={() => {
              adminStore.typeModal = "editPermission";
              adminStore.openModalChangePermission = true;
              adminStore.dataFormStaff = {
                ...item,
                roles: item.roles?.[0]?.id,
              };
            }}
          >
            <i className="fas fa-shield-alt"></i>
            <p>Thay đổi quyền</p>
          </div>
        )}
        <div
          className="content-box"
          style={{ textDecoration: "none", color: "#061020" }}
          onClick={() => {
            adminStore.typeModal = "edit";
            adminStore.openModalAddEditStaff = true;
            adminStore.dataFormStaff = { ...item, roles: item.roles?.[0]?.id };
          }}
        >
          <i className="fas fa-info-circle"></i>
          <p>Thay đổi thông tin</p>
        </div>
        <div
          className="content-box"
          style={{ textDecoration: "none", color: "#061020" }}
          onClick={() => {
            adminStore.typeModal = "editPass";
            adminStore.openModalChangePassword = true;
            adminStore.dataFormStaff = { ...item, roles: item.roles?.[0]?.id };
          }}
        >
          <i className="fas fa-key"></i>
          <p>Thay đổi mật khẩu</p>
        </div>
      </div>
    );
  };

  const showDeleteConfirm = (item: any) => {
    showDelete.showDeleteConfirm(
      "Xác nhận xóa",
      `Bạn có chắc muốn xóa ${item.name}`,
      async () => {
        await adminStore.deleteStaff(item.id);
      }
    );
  };

  return (
    <LayoutSettings>
      <div className="AdminPage">
        <div className="AdminPage-header">
          <h1 className="title">Quản trị viên</h1>
             <FilterAdmin/>
        </div>
        <div className="AdminPage-body">
          <div className="table-container">
            <div className="table-responsive">
              <table className="table table-striped" id="table-2">
                <thead className="AdminPage-body__thead">
                  <tr>
                    <th
                      style={{
                        width: "30%",
                        textAlign: "left",
                      }}
                    >
                      Tên thành viên
                    </th>
                    <th
                      style={{
                        width: "20%",
                        textAlign: "left",
                      }}
                    >
                      Nhóm quyền
                    </th>
                    <th
                      style={{
                        width: "20%",
                        textAlign: "left",
                      }}
                    >
                      Trạng thái hoạt động
                    </th>
                    <th
                      style={{
                        width: "20%",
                        textAlign: "left",
                      }}
                    >
                      Ngày tạo
                    </th>
                    <th
                      style={{
                        width: "10%",
                        textAlign: "left",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {adminStore.isLoading ? (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        <Loading />
                      </td>
                    </tr>
                  ) : adminStore.dataStaff.length <= 0 ? (
                    <tr>
                      <td colSpan={6}>
                        {" "}
                        <NoContent />{" "}
                      </td>
                    </tr>
                  ) : (
                    adminStore.dataStaff.length > 0 &&
                    adminStore.dataStaff.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div className="infoUser">
                            <div className="infoUser-boxImg">
                              <img
                                src={
                                  item.avatar
                                    ? item.avatar
                                    : "/assets/icon/icon_user.svg"
                                }
                                alt="img-default"
                              />
                            </div>
                            <div className="infoUser-right">
                              <h1 className="text-truncate" title={item.name}>
                                {item.name}
                              </h1>
                              <div className="phone">
                                <img
                                  src="/assets/icon/phoneCustomer.svg"
                                  alt="img-default"
                                />
                                {item.phone ? item.phone : "Chưa có số"}
                              </div>
                              <div className="email">
                                <img
                                  src="/assets/icon/emaiCustomer.svg"
                                  alt="img-default"
                                />
                                <span
                                  className="text-truncate"
                                  title={item.email}
                                >
                                  {item.email}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {item.roles?.length > 0 ? (
                            <span
                              className="permissionsGroup text-truncate"
                              title={item?.roles[0].name}
                            >
                              {item?.roles[0].name}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div
                            className={`switch-Status ${
                              adminStore.isLoadingBtn ? "disabled" : ""
                            }`}
                          >
                            <Switch
                              size="default"
                              checked={item.status === 1 ? true : false}
                              disabled={item.super_admin === 1}
                              onChange={async (e) => {
                                adminStore.dataFormStaff = {
                                  ...item,
                                  roles: item.roles[0].id,
                                  status: e ? 1 : 0,
                                };
                                await adminStore.updateStaff({});
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td>
                          <div className="actionUser d-flex gap-2">
                            <Popover
                              placement="bottomRight"
                              title={""}
                              arrow={false}
                              content={content(item)}
                              trigger="click"
                              overlayClassName={`popOver-adminPage ${
                                adminStore.openModalAddEditStaff ||
                                adminStore.openModalChangePassword ||
                                adminStore.openModalChangePermission
                                  ? "hiddenTemp"
                                  : ""
                              }`}
                            >
                              <img
                                src="/assets/icon/icon-PenEdit.svg"
                                alt="img-default"
                              />
                            </Popover>
                            {!item.super_admin && (
                              <img
                                src="/assets/icon/icon-trash.svg"
                                alt="img-default"
                                className="ms-1"
                                onClick={() => {
                                  showDeleteConfirm(item);
                                }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          {adminStore.metadata.total_page > 1 && (
            <ReactPaginate
              previousLabel={
                <img
                  src="/assets/icon/fi-rr-angle-small-left.svg"
                  alt="right"
                />
              }
              nextLabel={
                <img
                  src="/assets/icon/fi-rr-angle-small-right.svg"
                  alt="right"
                />
              }
              breakLabel={"..."}
              pageCount={adminStore.metadata.total_page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              containerClassName={"pagination"}
              activeClassName={"active"}
              onPageChange={handleChangePage}
              forcePage={adminStore.staticParam.page}
            />
          )}
        </div>
      </div>

      <AddEditStaff />
      <ChangePassword />
      <EditPermission />
    </LayoutSettings>
  );
};

export default observer(AdminPage);
