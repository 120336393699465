import React, {useEffect} from "react";
import {observer} from "mobx-react";
import LayoutSettings from "../components/layout/LayoutSettings";
import {filesStore} from "./FilesStore";
import './styles/index.css'
import {handleFile} from "../../../common/helpers/UploadFileHelper";
import {categoryProductStore} from "../../products/categoryProduct/CategoryProductStore";

const FilesPage = () => {
    useEffect(() => {
        filesStore.fetchMediaList().then()
    }, []);
    return(
        <LayoutSettings>
            <div className="AdminPage">
                <div className="AdminPage-header">
                    <h1 className="title">Danh sách tệp tin</h1>
                </div>
                <div className="AdminPage-body">
                    <div className="table-container page-files">
                        <div className="mailbox-controls">
                            <button className="reload">
                                <i className="fas fa-sync"></i>
                            </button>
                            <button className="remove-file">
                                <i className="fas fa-trash" style={{color:'#fff'}}></i>
                            </button>
                            <button className="upload">
                                <i className="fa fa-upload" style={{color: '#fff'}}></i>
                                <span>Tải lên</span>
                                <input accept="image/jpeg, image/jpg, image/png, image/svg+xml, image/webp"
                                    type="file"
                                       onChange={(e) => handleFile(e, filesStore.changeFile)}
                                />
                            </button>
                            <button className="create-folders">
                                <i className="fa fa-folder" style={{color: '#fff'}}></i>
                                <span>Tạo thư mục</span>
                            </button>
                            <div className="structure">
                                <button>
                                    <i className="fa fa-list"></i>
                                </button>
                                <button>
                                <i className="fa fa-th"></i>
                                </button>
                            </div>
                        </div>
                        <div className="search">
                            <form  >
                                <input
                                    type="text"
                                    name="keyword"

                                    id="keyword"
                                    // onChange={(e: any) =>
                                    //     (categoryProductStore.staticParamCategoryProduct.search =
                                    //         e.currentTarget.value)
                                    // }
                                    placeholder="Đường dẫn"
                                />
                                <button type="submit">
                                    <img
                                        src="/assets/icon/search.svg"
                                        alt="search"
                                    />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutSettings>
    )
}
export default observer(FilesPage)