import { observer } from "mobx-react";
import { Modal } from "antd";
import { tagProductStore } from "../TagProductStore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { toastUtils } from "../../../../common/utils/Toastutils";

const PutTagProduct = () => {
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .max(150, "Tên thẻ quá dài")
            .required("Không dược để trống")
            .matches(
                /^[^<>(){}[\]`\/!@#$%^&*~"'.,:?|\\]+$/,
                "Tên không được chứa các ký tự đặc biệt"
            ),
        is_featured: Yup.string().required("Không được để trống"),
        status: Yup.string().required("Không được để trống"),
    });
    const loadingBtn = tagProductStore.isLoadingBtn;
    return (
        <>
            <Modal
                className="modal-form"
                title="Sửa thẻ"
                footer={null}
                open={tagProductStore.isModalCustom}
                onOk={tagProductStore.handleOK}
                onCancel={tagProductStore.handleCancel}
                afterClose={tagProductStore.handleClose}
            >
                <Formik
                    initialValues={tagProductStore.initialValues}
                    enableReinitialize
                    validationSchema={SignupSchema}
                    onSubmit={(value: any, { resetForm }: any) => {
                        tagProductStore.isLoadingBtn = true;
                        tagProductStore.initialValues.name = value.name.trim();
                        tagProductStore
                            .putTagProduct()
                            .then((resolve) => {})
                            .catch((err) => console.log("err", err));
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="item-form">
                                <label className="label-form">
                                    Tên thẻ <span>*</span>
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    placeholder="Vui lòng nhập"
                                    maxLength={100}
                                    onInput={(e: any) => {
                                        if (e.target.value.length > 99) {
                                            toastUtils.warning(
                                                "Tên thẻ quá dài, tối đa 50 ký tự"
                                            );
                                        }
                                    }}
                                />
                                <ErrorMessage component="span" name="name" />
                            </div>

                            <div className="item-form item-selected">
                                <label className="label-form">Nổi bật</label>
                                <SelectStatus
                                    onChange={(value: any) => {
                                        tagProductStore.initialValues.is_featured =
                                            value;
                                    }}
                                    selected={() =>
                                        tagProductStore.initialValues
                                            .is_featured
                                    }
                                    textOn="Có"
                                    textOff="Không"
                                />
                            </div>

                            <div className="item-form item-selected">
                                <label className="label-form">Trạng Thái</label>
                                <SelectStatus
                                    onChange={(value: any) => {
                                        tagProductStore.initialValues.status =
                                            value;
                                        console.log(
                                            "check status",
                                            tagProductStore.initialValues.status
                                        );
                                    }}
                                    selected={() =>
                                        tagProductStore.initialValues.status
                                    }
                                />
                            </div>

                            <div className="btnCreateModal">
                                <button
                                    className="cancelModal"
                                    type="reset"
                                    onClick={tagProductStore.handleCancel}
                                >
                                    Đóng
                                </button>
                                <button
                                    className="submitForm"
                                    type="submit"
                                    disabled={loadingBtn}
                                >
                                    {loadingBtn ? "Vui lòng đợi..." : "Lưu"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default observer(PutTagProduct);
