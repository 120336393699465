import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import { dashboardStore } from "./DashboardStore";
import { observer } from "mobx-react";
import SaleChart from "./components/saleChart/SaleChart";
import { Dropdown } from "react-bootstrap";
import CustomerChart from "./components/customerChart/CustomerChart";
import ProductChart from "./components/productChart/ProductChart";
import Notifications from "./components/notifications/Notifications";
const listFilterTime = [
    { id: "today", value: "Hôm nay" },
    { id: "yesterday", value: "Hôm qua" },
    { id: "last_7_days", value: "7 ngày trước" },
    { id: "this_month", value: "Tháng này" },
    { id: "last_month", value: "Tháng trước" },
];
const DashBoardPage = () => {
    const formatCurrency = (amount: number): string => {
        return amount.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
        });
    };

    // const callAPi = async (  ) => {
    //      dashboardStore.fetchDataStore().then();
    //      dashboardStore.fetchDataRevenue('day',7,'7 ngày trước').then();
    //  }
    useEffect(() => {
        dashboardStore.fetchDataStore().then();
        dashboardStore.fetchDataCustomer().then();
        dashboardStore.fetchDataRevenue().then();
        dashboardStore.fetchDataTopSellers().then();
        dashboardStore.fetchDataNotifications().then();
    }, []);

    return (
        <div className="container">
            <div className="mainContent">
                <div className="mainContentLeft">
                    <div className="mainContentLeft-RevenueOrder">
                        <div className="mainContentLeftFilter">
                            <p>Kết quả bán hàng</p>
                            <div className="fitter fitterMonth">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="dropdownFiltertime"
                                    >
                                        <span
                                            className="titleTime"
                                            id="titleTime"
                                        >
                                            {dashboardStore.textDay}
                                        </span>
                                        <img
                                            src="/assets/icon/fi-sr-caret-down.svg"
                                            alt="fi-sr-caret-down"
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {listFilterTime.map((item, index) => (
                                            <Dropdown.Item
                                                href="javascript:void(0)"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    dashboardStore.typeTimeSalesChart = item.id;
                                                    dashboardStore.initializeDates(item.id);
                                                    dashboardStore.textDay = item.value;
                                                    dashboardStore.fetchDataRevenue();
                                                    dashboardStore.fetchDataCustomer();
                                                    dashboardStore.fetchDataTopSellers();
                                                }}
                                            >
                                                {item.value}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="fitter fitterStore">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="dropdownFitterStore"
                                    >
                                        <span
                                            className="titleTime"
                                            id="titleTime"
                                        >
                                            {dashboardStore.textStore}
                                        </span>
                                        <img
                                            src="/assets/icon/fi-sr-caret-down.svg"
                                            alt="fi-sr-caret-down"
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            href="javascript:void(0)"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                dashboardStore.initializeDates(dashboardStore.typeTimeSalesChart);
                                                dashboardStore.staticDataSalesChart.store = 0;
                                                dashboardStore.textStore =
                                                    "Tất cả chi nhánh";
                                                dashboardStore.fetchDataRevenue();
                                                dashboardStore.fetchDataCustomer();
                                                dashboardStore.fetchDataTopSellers();
                                            }}
                                        >
                                            Tất cả chi nhánh
                                        </Dropdown.Item>
                                        {dashboardStore?.dataListStore?.map(
                                            (item: any) => (
                                                <Dropdown.Item
                                                    href="javascript:void(0)"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        dashboardStore.staticDataSalesChart.store =
                                                            item.id;
                                                        dashboardStore.textStore =
                                                            item.name;
                                                        dashboardStore.fetchDataRevenue();
                                                        dashboardStore.fetchDataCustomer();
                                                        dashboardStore.fetchDataTopSellers();
                                                    }}
                                                >
                                                    {" "}
                                                    {item.name}
                                                </Dropdown.Item>
                                            )
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="mainContentLeftRevenueOrder">
                            <div className="mainContentLeftRevenue">
                                <div className="image">
                                    <img src="/assets/icon/money.svg" alt="" />
                                </div>
                                <div className="price">
                                    <p className="price-value" id="sales">
                                        {dashboardStore.isLoading ? (
                                            <img
                                                src="/assets/images/loader.gif"
                                                alt="loader"
                                            />
                                        ) : (
                                            formatCurrency(
                                                dashboardStore.summary.total_revenue ? dashboardStore.summary.total_revenue : 0
                                            )
                                        )}
                                    </p>
                                    <p className="price-text">Doanh thu</p>
                                </div>
                            </div>
                            <div className="mainContentLeftOrder">
                                <div className="image">
                                    <img
                                        src="/assets/icon/order.svg"
                                        alt="order"
                                    />
                                </div>
                                <div className="listPrice">
                                    <div
                                        className="itemPrice"
                                        id="number_of_orders"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="10000"
                                    >
                                        <p className="value" id="order">
                                            {dashboardStore.isLoading ? (
                                                <img
                                                    src="/assets/images/loader.gif"
                                                    alt="loader"
                                                />
                                            ) : (
                                                dashboardStore.summary
                                                    .total_order
                                            )}
                                        </p>
                                        <p className="text">Đơn hàng</p>
                                    </div>
                                    <div
                                        className="itemPrice"
                                        id="total_orders_pending"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="1000"
                                    >
                                        <p className="value">
                                            {dashboardStore.isLoading ? (
                                                <img
                                                    src="/assets/images/loader.gif"
                                                    alt="loader"
                                                />
                                            ) : (
                                                dashboardStore.summary
                                                    .pending_count
                                            )}
                                        </p>
                                        <p className="text">Chờ xử lý</p>
                                    </div>
                                    <div
                                        className="itemPrice"
                                        id="total_orders_completed"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="1234"
                                    >
                                        <p className="value">
                                            {dashboardStore.isLoading ? (
                                                <img
                                                    src="/assets/images/loader.gif"
                                                    alt="loader"
                                                />
                                            ) : (
                                                dashboardStore.summary
                                                    .completed_count
                                            )}
                                        </p>
                                        <p className="text">Hoàn thành</p>
                                    </div>
                                    <div
                                        className="itemPrice"
                                        id="total_orders_cancel_refund"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="7800"
                                    >
                                        <p className="value">
                                            {dashboardStore.isLoading ? (
                                                <img
                                                    src="/assets/images/loader.gif"
                                                    alt="loader"
                                                />
                                            ) : (
                                                dashboardStore.summary
                                                    .refund_count
                                            )}
                                        </p>
                                        <p className="text">Huỷ và hoàn trả</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SaleChart
                        dataRevenue={dashboardStore.dataRevenue}
                        dataListStore={dashboardStore.dataListStore}
                    />
                    <CustomerChart
                        dataCustomer={dashboardStore.dataCustomer}
                        dataListStore={dashboardStore.dataListStore}
                    />
                    <ProductChart
                        dataTopSellers={dashboardStore.dataTopSellers}
                    />
                </div>
                <div className="mainContentRight">
                    <Notifications
                        dataListNotifications={
                            dashboardStore.dataListNotifications
                        }
                        metadataNotifications={
                            dashboardStore.metadataNotifications
                        }
                    />
                </div>
            </div>
        </div>
    );
};
export default observer(DashBoardPage);
