import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {productStore} from "../../ProductStore";
import {Button, Form, Input, InputNumber, Modal, Space} from "antd";
import SelectStatus from "../../../../../shared/components/form/SelectStatus";
import SelectCustom from "../../../../../shared/components/form/SelectCustom";
import SelectStatusNumber from "../../../../../shared/components/form/SelectStatusNumber";
import {handleKeyPress} from "../../../../../common/utils/Utils";

const  ModalCreateListVariant = () => {
    const [formAttributes] = Form.useForm();
    useEffect(() => {
        formAttributes.setFieldsValue({
            price_original: productStore.selectedItem?.price_original,
            price_compare_original: productStore.selectedItem?.price_compare_original,
            sku_original: productStore.selectedItem?.sku_original,
            weight_original: productStore.selectedItem?.weight_original,
            height_original: productStore.selectedItem?.height_original,
            length_original: productStore.selectedItem?.length_original,
            width_original: productStore.selectedItem?.width_original,
            status_attribute: productStore.selectedItem?.status_attribute,
            allow_inventory_attribute: productStore.selectedItem?.allow_inventory_attribute,
            qty_attribute: productStore.selectedItem?.qty_attribute,
        });
    }, [productStore.selectedItem, formAttributes]);

    const handleQtyChange = (index:any, delta:any) => {
        const item = productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute[index];
        const newQty = Math.max(0, (item.qty || 0) + delta);
        item.qty = newQty;
    };
    const handleInputChange = (index:any, value:any) => {
        const newQty = Math.max(0, value);
        productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute[index].qty = newQty;
    };

    return(
        <>
            {productStore.isModalOpen && productStore.selectedItem && (
                <Modal
                    className=""
                    title="Chỉnh sửa thuộc tính"
                    footer={null}
                    open={productStore.isModalOpen}
                    onCancel={productStore.handleModalClose}
                    afterClose={productStore.handleClose}
                >
                    <Form className="form-create-edit"
                          form={formAttributes}
                          name="control-hooks-edit"
                          onFinish={(values) => {
                              productStore.setIsModalOpen(false);
                          }}
                    >
                        <div className="item-form">
                            <Form.Item
                                name="price_original"
                                label="Giá bán"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <InputNumber
                                    onKeyPress={handleKeyPress}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as unknown as number}
                                    defaultValue={productStore.selectedItem.price_original}
                                    onChange={(value) => {
                                        productStore.listGenerateAttributes[productStore.selectedItem.index].price_original = `${value}`;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="price_compare_original"
                                label="Giá gốc"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <InputNumber
                                    onKeyPress={handleKeyPress}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as unknown as number}
                                    defaultValue={productStore.selectedItem.price_compare_original}
                                    onChange={(value) => {
                                        productStore.listGenerateAttributes[productStore.selectedItem.index].price_compare_original = `${value}`;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="sku_original"
                                label="SKU"
                                rules={[{required: true, message: "Không được để trống!"},{min: 4, message: "Sku phải từ 4 ký tự trở lên"}]}
                            >
                                <Input
                                    defaultValue={productStore.selectedItem.sku_original}
                                    onChange={(event) => {
                                        productStore.listGenerateAttributes[productStore.selectedItem.index].sku_original = event.target.value;
                                    }}/>
                            </Form.Item>
                        </div>
                        <div className="grid-2 gap-20">
                            <div className="item-form">
                                <Form.Item
                                    name="weight_original"
                                    label="Cân nặng (gram)"
                                    rules={[{required: true, message: "Không được để trống!"}]}
                                >
                                    <Input
                                        onKeyPress={handleKeyPress}
                                        type="number"
                                        defaultValue={productStore.selectedItem.weight_original}
                                        onChange={(event) => {
                                            productStore.listGenerateAttributes[productStore.selectedItem.index].weight_original = event.target.value;
                                        }}/>
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="height_original"
                                    label="Chiều cao (cm)"
                                    rules={[{required: true, message: "Không được để trống!"}]}
                                >
                                    <Input
                                        onKeyPress={handleKeyPress}
                                        type="number"
                                        defaultValue={productStore.selectedItem.height_original}
                                        onChange={(event) => {
                                            productStore.listGenerateAttributes[productStore.selectedItem.index].height_original = event.target.value;
                                        }}/>
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="length_original"
                                    label="Chiều dài (cm)"
                                    rules={[{required: true, message: "Không được để trống!"}]}
                                >
                                    <Input
                                        onKeyPress={handleKeyPress}
                                        type="number"
                                        defaultValue={productStore.selectedItem.length_original}
                                        onChange={(event) => {
                                            productStore.listGenerateAttributes[productStore.selectedItem.index].length_original = event.target.value;
                                        }}/>
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="width_original"
                                    label="Chiều rộng (cm)"
                                    rules={[{required: true, message: "Không được để trống!"}]}
                                >
                                    <Input
                                        onKeyPress={handleKeyPress}
                                        type="number"
                                        defaultValue={productStore.selectedItem.width_original}
                                        onChange={(event) => {
                                            productStore.listGenerateAttributes[productStore.selectedItem.index].width_original = event.target.value;
                                        }}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="item-form">
                            <Form.Item name="status_attribute" label="Tình trạng" rules={[{required: true, message: 'Không được để trống!'}]}>
                                <SelectStatus selected={() => productStore.listGenerateAttributes[productStore.selectedItem.index].status_attribute}
                                              onChange={(value: any) => productStore.listGenerateAttributes[productStore.selectedItem.index].status_attribute = value}
                                              textOn={`Còn hàng`} textOff={`Hết hàng`}/>
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item name="allow_inventory_attribute" label="Loại tồn kho" rules={[{required: true, message: 'Không được để trống!'}]}>
                                <SelectStatus selected={() => productStore.listGenerateAttributes[productStore.selectedItem.index].allow_inventory_attribute}
                                              onChange={(value: any) => productStore.listGenerateAttributes[productStore.selectedItem.index].allow_inventory_attribute = value}
                                              textOn={`Cho phép quản lý tồn kho`} textOff={`Không cho phép quản lý tồn kho`}/>
                            </Form.Item>
                        </div>
                        {productStore.listGenerateAttributes[productStore.selectedItem.index].allow_inventory_attribute === '1' && (
                            <>
                                {/*<label className="label-allow-order">*/}
                                {/*    <Checkbox*/}
                                {/*        name="allow_order_when_out_of_stock"*/}
                                {/*        onChange={handleCheckboxChange}*/}
                                {/*        checked={productStore.staticDataProduct.allow_inventory === '1'}*/}
                                {/*    >*/}
                                {/*        Cho phép đặt hàng khi sản phẩm hết hàng*/}
                                {/*    </Checkbox>*/}
                                {/*</label>*/}
                                <div className="item-form-inventory">
                                    {productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute.map((itemStore:any, indexStore:number) => (
                                        <div className="item-store" key={indexStore}>
                                            <p>{itemStore.name}</p>
                                            <div className="qty">
                                                <div className="minus">
                                                    <button
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            handleQtyChange(indexStore, -1);
                                                        }}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                                <Input
                                                    type="number"
                                                    placeholder="0"
                                                    value={productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute[indexStore].qty || ""}
                                                    onChange={(e) => handleInputChange(indexStore, parseInt(e.target.value, 10))}
                                                    min="0"
                                                    max="99999"
                                                    maxLength={16}
                                                />
                                                <div className="plus">
                                                    <button
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            handleQtyChange(indexStore, 1);
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Hoàn tất
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    )
}
export  default observer(ModalCreateListVariant);