import { observer } from "mobx-react";
import { Button, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { roomStore } from "../RoomStore";

const RoomFilterTab = () => {
    const [filterOpen, setFilterOpen] = useState(true);
    const [filteredRooms, setFilteredRooms] = useState(roomStore.listRoomAll); // Khởi tạo với danh sách tất cả

    useEffect(() => {
        const updateFilteredRooms = () => {
            if (roomStore.locationFilterValue === -1) {
                setFilteredRooms(roomStore.listRoomAll);
            } else {
                setFilteredRooms(
                    roomStore.listRoomAll.filter(
                        (room) => room.location_id === roomStore.locationFilterValue
                    )
                );
            }
        };

        updateFilteredRooms();
    }, [roomStore.locationFilterValue, roomStore.listRoomAll]);

    return (
        <>
            <div className="filter-tab">
                <Button
                    className="filter-collapse-btn"
                    onClick={() => setFilterOpen(!filterOpen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={filterOpen}
                >
                    <span>Tầng</span>
                    <i className="fas fa-chevron-down"></i>
                </Button>
                {roomStore.loadingDataAll ? (
                    <></>
                ) : (
                    <Collapse in={filterOpen}>
                        <div className="filter-tab__body">
                            {filteredRooms.length > 0 ? (
                                filteredRooms.map((room, index) => (
                                    <div className="filter-tab__option" key={index}>
                                        <input
                                            checked={roomStore.roomFilterValue === room.id}
                                            value={room.id}
                                            type="radio"
                                            name="room_id"
                                            id={`room-${room.id}`}
                                            onClick={() => {
                                                roomStore.handleCheckOnRoomOption(room.id);
                                            }}
                                        />
                                        <label
                                            className={`radio-button ${
                                                roomStore.roomFilterValue === room.id ? 'selected-label' : ''
                                            }`}
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                            htmlFor={`room-${room.id}`}
                                        >
                                            {room.name}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <div>Không có tầng nào</div>
                            )}
                        </div>
                    </Collapse>
                )}
            </div>
        </>
    );
};

export default observer(RoomFilterTab);
