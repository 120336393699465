import { makeAutoObservable } from "mobx";
import { loginService } from "./LoginService";
import StorageService from "../../common/service/StorageService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";
import Constants from "../../common/constants/Constants";
const storageService = new StorageService();

class LoginStore {
    username: string = "";
    password: string = "";
    domain_name: string = "";
    isOpenLogin: boolean = true;
    isLoading: boolean = false;
    isDomain: boolean = false;
    isGoBack: boolean = false;
    isForgetPassWord: boolean = false;
    isWillExpire: string = "";

    constructor() {
        //* Tự động theo dõi các thay đổi
        makeAutoObservable(this);
    }
    setUserName(value: string) {
        this.username = value;
    }
    setPassWord(value: string) {
        this.password = value;
    }
    setApiUrl(value: string) {
        this.domain_name = value;
    }
    setisOpenLogin(value: boolean) {
        this.isOpenLogin = value;
    }
    setisForgetPassWord(value: boolean) {
        this.isForgetPassWord = value;
    }

    async checkDomain() {
        this.isLoading = true;
        const result = await loginService.verifyDomain(this.domain_name);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.isDomain = true;
            this.isWillExpire = result.body.will_expire;
            storageService.setIdTenant(result.body.enpoint);
            storageService.setIdDomain(this.domain_name);
            // storageService.setNameUser(result.body.tenant_id);
            // this.setApiUrl(result.body.endpoint);
            // this.login(result.body.enpoint);
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.warning("Vui không để trống domain", "");
        } else if (result.status === HttpStatusCode.InternalServerError) {
            toastUtils.error("Không tồn tại domain này", "");
        } else if (result.status === HttpStatusCode.Forbidden) {
            toastUtils.warning("Domain của bạn đã hết hạn", "");
        }
    }

    async login(endpoint: string) {
        this.isLoading = true;

        const result = await loginService.createToken(
            this.username,
            this.password,
            endpoint
        );
        const valitedateEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Đăng nhập thành công", "");
            StorageService.setToken(result.body.access_token);
            StorageService.setRefreshToken(result.body.refresh_token);
            storageService.setNameUser(result.body.name);
            StorageService.saveArayLS(Constants.ROLE,result.body.roles[0].permissions) // lưu quyền
            window.location.href = "/";
        }
        if (this.username.length < 1) {
            toastUtils.warning("Vui lòng nhập email", "");
        } else if (!valitedateEmail.test(this.username)) {
            toastUtils.warning("Tên đăng nhập phải là email", "");
        }
        if (this.password.length < 1) {
            toastUtils.warning("Vui lòng nhập password", "");
        }

        if (result.status === HttpStatusCode.BadRequest) {
            if (result.body.message.user === "not_found_user!") {
                toastUtils.warning("Không tìm thấy thông tin tài khoản", "");
            } else if (
                result.body.message.authentication ===
                "please_check_your_login_information"
            ) {
                toastUtils.warning(
                    "Vui lòng kiểm tra lại thông tin đăng nhập",
                    ""
                );
            }
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.warning("Vui lòng nhập mật khẩu", "");
        }
    }
}

export const loginStore = new LoginStore();
