import { makeAutoObservable } from "mobx";
import { couponService } from "./CouponService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

interface StaticParamCoupon {
    offset: number | null;
    order_by: string | null;
    page: number;
    per_page: number | null;
    search: string;
}

class CouponStore {
    isLoading = true;
    isModalOpenCreate = false;
    isModalOpenUpdate = false;
    isModalDelete = false;
    isWaitingText = "";
    isLoadingBtn = false;

    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }
    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamCoupon.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamCoupon.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
    }

    initialValues: {
        id: number;
        name: string;
        code: string;
        value: number;
        is_conditional: string;
        min_amount: number;
        start_from: string;
        will_expire: string;
        preview: string | undefined;
        is_percentage: string;
        is_featured: string;
        status: string;
    } = {
        id: 1,
        name: "",
        code: "",
        value: 0,
        is_conditional: "0",
        min_amount: 0,
        start_from: "",
        will_expire: "",
        preview: "",
        is_percentage: "1",
        is_featured: "1",
        status: "1",
    };

    setIsModalOpenCreate = (isOpen: boolean) => {
        this.isModalOpenCreate = isOpen;
    };
    setIsModalOpenUpdate = (isOpen: boolean) => {
        this.isModalOpenUpdate = isOpen;
    };
    showModalOpenCreate = () => {
        this.setIsModalOpenCreate(true);
    };
    showModalOpenUpdate = () => {
        this.setIsModalOpenUpdate(true);
    };
    handleOK = () => {
        this.setIsModalOpenCreate(false);
        this.setIsModalOpenUpdate(false);
    };
    handleCancel = () => {
        this.setIsModalOpenCreate(false);
        this.setIsModalOpenUpdate(false);
    };
    changeFileImage = (pathFile: string) => {
        this.staticCoupon.image = pathFile;
        this.staticCoupon.preview = pathFile;
        this.initialValues.preview = pathFile;
    };
    handleCloseIcon = () => {
        this.staticCoupon.image = "";
        this.initialValues.preview = "";
    };
    handeClearFormCreate = () => {
        this.staticCoupon.id = 1;
        this.staticCoupon.name = "";
        this.staticCoupon.code = "";
        this.staticCoupon.value = 0;
        this.staticCoupon.is_conditional = "0";
        this.staticCoupon.is_featured = "1";
        this.staticCoupon.is_percentage = "1";
        this.staticCoupon.min_amount = 0;
        this.staticCoupon.start_from = "";
        this.staticCoupon.will_expire = "";
        this.staticCoupon.preview = "";
        this.staticCoupon.status = "1";
    };

    metadata: any = {
        page: "",
        size: 0,
        total: "",
        total_page: "",
    };

    staticParamCoupon: StaticParamCoupon = {
        offset: 0,
        order_by: "name",
        page: 0,
        per_page: 10,
        search: "",
    };
    dataListCoupon: any[] = [];

    staticCoupon: {
        id: number;
        image: any;
        name: string;
        code: string;
        value: number;
        is_conditional: string;
        min_amount: number;
        start_from: string;
        will_expire: string;
        preview: string | null;
        is_percentage: string;
        is_featured: string;
        status: string;
    } = {
        id: 1,
        image: "",
        name: "",
        code: "",
        value: 0,
        is_conditional: "0",
        min_amount: 0,
        start_from: "",
        will_expire: "",
        preview: "",
        is_percentage: "1",
        is_featured: "1",
        status: "1",
    };

    async fetchListCoupon() {
        this.isLoading = true;
        let { order_by, page, search, offset, per_page } =
            this.staticParamCoupon;
        const paramForCoupons = {
            order_by: order_by,
            per_page: per_page,
            search: search,
            offset: offset,
            page: page,
        };
        const result = await couponService.fetchCoupons(paramForCoupons);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataListCoupon = result.body.data;
        } else {
            toastUtils.error("Không nhận được dữ liệu", "");
        }
    }

    async fetchAllListCoupon(run?: boolean) {
        let { order_by, page, search, offset, per_page } =
            this.staticParamCoupon;
        const paramForCoupons = {
            order_by: order_by,
            per_page: per_page,
            search: search,
            offset: offset,
            page: page + 1,
        };

        if (this.dataListCoupon.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await couponService.fetchCoupons(paramForCoupons);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.handeClearFormCreate();
            if (
                result.body.data.length !== this.dataListCoupon.length ||
                JSON.stringify(result.body.data) !==
                    JSON.stringify(this.dataListCoupon)
            ) {
                this.dataListCoupon = result.body.data;
                this.metadata = result.body.metadata;
            }
        } else {
            toastUtils.error("Không nhận được dữ liệu", "");
        }
    }

    async fetchCreateCoupon(): Promise<void> {
        this.isLoadingBtn = true;
        let {
            name,
            code,
            value,
            is_conditional,
            min_amount,
            is_featured,
            start_from,
            will_expire,
            preview,
            is_percentage,
            status,
        } = this.staticCoupon;

        const paramsCreate = {
            name: code,
            code: code,
            value: value,
            is_conditional: is_conditional,
            min_amount: min_amount,
            start_from: start_from,
            will_expire: will_expire,
            preview: preview,
            is_percentage: is_percentage,
            is_featured: is_featured,
            status: status,
        };

        const result = await couponService.fetchCreateCoupon(paramsCreate);
        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thêm thành công", "");
            this.dataListCoupon.unshift(paramsCreate);
            this.dataListCoupon.pop();
            this.handeClearFormCreate();
        } else {
            toastUtils.error("Lỗi tạo mới", "");
        }
    }

    async fetchPutCoupon(): Promise<void> {
        this.isLoadingBtn = true;
        let {
            id,
            name,
            code,
            value,
            is_conditional,
            min_amount,
            is_featured,
            start_from,
            will_expire,
            preview,
            is_percentage,
            status,
        } = this.initialValues;

        const paramsPut = {
            name: code,
            code: code,
            value: value,
            is_conditional: is_conditional,
            min_amount: min_amount,
            start_from: start_from,
            will_expire: will_expire,
            preview: preview,
            is_percentage: is_percentage,
            is_featured: is_featured,
            status: status,
        };
        const result = await couponService.fetchPutCoupon(id, paramsPut);
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            const updateDataCoupont = this.dataListCoupon.map((item: any) =>
                item.id === this.initialValues.id
                    ? {
                          ...item,
                          name: this.initialValues.name,
                          code: this.initialValues.code,
                          value: this.initialValues.value,
                          is_conditional: this.initialValues.is_conditional,
                          min_amount: this.initialValues.min_amount,
                          start_from: this.initialValues.start_from,
                          will_expire: this.initialValues.will_expire,
                          avatar: this.initialValues.preview,
                          is_percentage: this.initialValues.is_percentage,
                          is_featured: this.initialValues.is_featured,
                          status: this.initialValues.status,
                      }
                    : item
            );
            this.dataListCoupon = updateDataCoupont;
            toastUtils.success("Sửa thành công", "");
        } else if (result.status === HttpStatusCode.BadRequest) {
            toastUtils.error(
                "Không để trống ngày bắt đầu và ngày kết thúc",
                ""
            );
        }
    }

    async fetchDeleteCoupon() {
        let { id } = this.staticCoupon;
        const result = await couponService.fetchDeleteCoupon(id);

        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Xóa thành công", "");
            this.dataListCoupon = this.dataListCoupon.filter(
                (item: any) => item.id !== this.staticCoupon.id
            );

            if (this.dataListCoupon.length === 0) {
                this.staticParamCoupon.page = 0;
                this.fetchAllListCoupon(true);
            }
        }
    }
}

export const couponStore = new CouponStore();
