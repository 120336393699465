import {makeAutoObservable} from "mobx";
import {permissionsService} from "../permissions/PermissionsService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {filesService} from "./FilesService";


class FilesStore {
    isLoading: boolean = false
    preview: string = ''
    constructor() {
        makeAutoObservable(this)
    }
    changeFile = (pathFile: string) => {
        this.preview = pathFile;
        console.log(this.preview)
    };

    async fetchMediaList() {
        const result = await filesService.fetchMediaList();
        if (result.status === HttpStatusCode.Ok) {

        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
}

export const filesStore = new FilesStore()