import {makeAutoObservable} from "mobx";
import StorageService from "../../../common/service/StorageService";
const storageService = new StorageService();

class LogoutStore{
    constructor() {
        makeAutoObservable(this)
    }
    async getBanner(){

    }
    logout(){
        StorageService.removeToken()
        StorageService.removeRefreshToken()
        window.location.href = "/dang-nhap";
    }
}
export const logoutStore = new LogoutStore()