import { observer } from "mobx-react";
import { roomStore } from "./RoomStore";
import Loading from "../../shared/components/loading/Loading";
import { Dropdown } from "react-bootstrap";
import NoContent from "../../shared/components/NoContent";
import { showDelete } from "../../shared/components/showDelete";
import React, { useEffect } from "react";
import "./styles/room.css";
import LocationFilterTab from "./components/LocationFilterTab";
import { createSearchParams, useNavigate } from "react-router-dom";
import EditModal from "./components/EditModal";
import RoomFilterTab from "./components/RoomFilterTab";
import ReactPaginate from "react-paginate";

const Room = () => {
    const navigate = useNavigate();

    function onSubmitSearch(event: any) {
        const searchKeyword = roomStore.staticParamRoom.search;
        event.preventDefault();
        const params: any = {};
        if (searchKeyword !== "") {
            params.keyword = `${searchKeyword}`;
        }
        navigate({
            search: createSearchParams(params).toString(),
        });
        roomStore.getRoomWithRequirement();
    }

    function handleSelect(value: boolean, name: string) {
        if (value) {
            roomStore.selectedRoomInTable = [
                ...roomStore.selectedRoomInTable,
                name,
            ];
        } else {
            roomStore.selectedRoomInTable = roomStore.selectedRoomInTable.filter(
                (item) => item !== name
            );
        }
    }

    function onHandleKeyDown(event: any) {
        if (event.key === "Enter") {
            onSubmitSearch(event);
        }
    }

    const handleChangePage = (e: any) => {
        roomStore.staticParamRoom.page = e.selected;
        roomStore.getRoomAll();
    };

    useEffect(() => {
        roomStore.getStoreAll().then();
        roomStore.getRoomAll().then();
    }, []);

    return (
        <div className="container">
            <div className="mainContentRoom">
                <div className="header">
                    <div className="header__title">Danh sách tầng/phòng</div>
                    <form className="header__search" onSubmit={onSubmitSearch}>
                        <input
                            id="search"
                            type="text"
                            onKeyDown={(event: any) => {
                                onHandleKeyDown(event);
                            }}
                            value={
                                roomStore.staticParamRoom.search
                                    ? roomStore.staticParamRoom.search
                                    : ""
                            }
                            onChange={(event) =>
                                (roomStore.staticParamRoom.search =
                                    event.target.value)
                            }
                            placeholder="Nhập tên phòng"
                        />
                        <button type="submit">
                            <img src="/assets/icon/search.svg" alt="" />
                        </button>
                    </form>
                    <button
                        className="btn-add-modal"
                        onClick={() => {
                            roomStore.modalOpen = true;
                            roomStore.isCreating = true;
                            roomStore.modalName = "Thêm mới tầng/phòng";
                        }}
                    >
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                        </span>
                    </button>
                </div>
                <div className="body-page">
                    <div className="filter-tab-wrapper">
                        <LocationFilterTab />
                        <RoomFilterTab />
                    </div>
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={{ width: "30%" }}>Tên</th>
                                    <th style={{ width: "25%" }}>Tầng</th>
                                    <th style={{ width: "28%" }}>Địa điểm</th>
                                    <th style={{ width: "10%" }}>Thứ tự</th>
                                    <th style={{ width: "15%" }}>Thao tác</th>
                                </tr>
                                </thead>
                                <tbody>
                                {roomStore.loadingDataAll === true ? (
                                    <tr>
                                        <td colSpan={5}>
                                            <Loading />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {roomStore.loadingDataTable === true ? (
                                            <tr>
                                                <td colSpan={5}>
                                                    <Loading />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {roomStore.listRoom.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <NoContent />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <>
                                                        {roomStore.listRoom.map(
                                                            (item: any, index: number) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span title={item.name}>
                                                                            {item.name}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {item.parent_id === 0 ? (
                                                                            <span></span>
                                                                        ) : (
                                                                            <span title={item.parent_id}>
                                                                                {item.parent?.name}
                                                                            </span>
                                                                        )}
                                                                    </td>

                                                                    <td>
                                                                        {roomStore.listStore?.find((store) => store.id === item.location_id)?.name}
                                                                    </td>
                                                                    <td>
                                                                        <span title={item.ordering}>
                                                                            {item.ordering}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <Dropdown
                                                                            align={"start"}
                                                                            className="action-table"
                                                                        >
                                                                            <Dropdown.Toggle>
                                                                                Thao tác
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        roomStore.isCreating = false;
                                                                                        roomStore.modalName =
                                                                                            "Sửa phòng";
                                                                                        roomStore.dataDetail =
                                                                                            item;
                                                                                        roomStore.modalOpen = true;
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-edit"></i>
                                                                                    Sửa
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        showDelete.showDeleteConfirm(
                                                                                            "Xác nhận xoá thuộc tính",
                                                                                            `Bạn chắc chắn muốn xoá tầng/phòng"${item.name}" không?`,
                                                                                            () => {
                                                                                                roomStore.deleteRoom();
                                                                                            }
                                                                                        );
                                                                                        roomStore.staticDataRoom.id =
                                                                                            item.id;
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                    Xóa
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                </tbody>
                            </table>
                            {!roomStore.isLoading &&
                                roomStore.listRoom.length > 0 &&
                                roomStore.metadata.total_page > 1 && (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    pageCount={Math.ceil(roomStore.metadata.total_page)}
                                    onPageChange={handleChangePage}
                                    forcePage={roomStore.staticParamRoom.page}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <EditModal />
        </div>
    );
};

export default observer(Room);
