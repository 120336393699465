import { observer } from "mobx-react"
import { Button } from "react-bootstrap"
import { useState } from "react";
import { Collapse } from "react-bootstrap"
import { posStore } from "../PosStore";

const StatusFilterTab = ()=>{
    const  [filterOpen, setFilterOpen] = useState(true);
    return(<>
        <div className="filter-tab">
            <Button className="filter-collapse-btn" onClick={() => setFilterOpen(!filterOpen)}
                    aria-controls="example-collapse-text" aria-expanded={filterOpen}>
                <span>Trạng thái</span>
                <i className="fas fa-chevron-down"></i>
            </Button>
            <Collapse in={filterOpen}>
                <div className="filter-tab__body">
                    <div className="filter-tab__option">
                        <input checked={posStore.statusFilterActiveStatus} type="radio" name="active" id="active" onClick={()=>{posStore.handleCheckOnStatusActiveOption()}}/>
                        <label className="radio-button" htmlFor="active">
                            Hoạt động
                        </label>
                    </div>
                    <div className="filter-tab__option">
                        <input checked={posStore.statusFilterInactiveStatus} type="radio" name="inactive" id="inactive" onClick={()=>{posStore.handleCheckOnStatusInactiveOption()}}/>
                        <label className="radio-button" htmlFor="inactive">
                            Không hoạt động
                        </label>
                    </div>
                </div>
            </Collapse>
        </div>
    </>)
}
export default observer(StatusFilterTab)
