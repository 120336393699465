import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GroupProduct from "../pages/products/groupProduct/GroupProduct";
import CategoryProduct from "../pages/products/categoryProduct/CategoryProductPage";
import PrivateRoute from "./privaterouters";
import TagProduct from "../pages/products/tagProduct/TagProduct";
import Brand from "../pages/products/brand/Brand";
import Login from "../pages/login/Login";
import Coupon from "../pages/products/coupon/Coupon";
import Attribute from "../pages/products/attribute/Attribute";
import EditProductGeneral from "../pages/products/product/editProduct/EditProductGeneralPage";
import EditProductVariant from "../pages/products/product/editProduct/EditProductVariantPage";
import EditProductBarcode from "../pages/products/product/editProduct/EditProductBarcodePage";
import EditProductQrcode from "../pages/products/product/editProduct/EditProductQrcodePage";
import EditProductImage from "../pages/products/product/editProduct/EditProductImagePage";
import Label from "../pages/products/label/Label";
import Info from "../pages/user/info/Info";
import Inventory from "../pages/products/inventory/Inventory";
import Order from "../pages/order/Order";
import EditOrder from "../pages/order/components/EditOrder";
import PrintOrder from "../pages/order/components/PrintOrder";
import Pos from "../pages/pos/Pos";
import Room from "../pages/room/RoomPage";
import CreateProduct from "../pages/products/product/createProduct/CreateProductPage";
import ProductPage from "../pages/products/product/ProductPage";
import DiningTablesPage from "../pages/pos/diningTables/DiningTablesPage";
import DetailOrderPosPage from "../pages/salespoint/detailOrderPos/DetailOrderPosPage";
import ViewDetailOrderPosPage from "../pages/salespoint/detailOrderPos/ViewDetailOrderPosPage";
import OrdersSessionMachinePage from "../pages/salespoint/orderSessionMachine/OrdersSessionMachinePage";
import DashBoard from "../pages/dashboard/DashBoardPage";
import ProductInPosPage from "../pages/salespoint/productInPos/ProductInPosPage";
import StoreBranchPage from "../pages/storebranch/StoreBranchPage";
import DetailMachinePosPage from "../pages/salespoint/detailMachinePos/DetailMachinePosPage";
import SalePointPage from "../pages/salespoint/SalePointPage";
import StoreBranchModal from "../pages/storebranch/StoreBranchModal";
import PaymentPosPage from "../pages/salespoint/paymentPos/PaymentPosPage";
import PaymentSuccessPage from "../pages/salespoint/paymentSuccess/PaymentSuccessPage";
import CustomerDebtPage from "../pages/salespoint/debt/listCustomerDebt/CustomerDebtPage";
import PaymentDebtPage from "../pages/salespoint/debt/paymentDebt/PaymentDebtPage";
import RefundOrderPage from "../pages/salespoint/refund/RefundOrderPage";
import RefundOrderDetailsPage from "../pages/salespoint/refund/RefundOrderDetailsPage";
import PaymentDebtSuccessPage from "../pages/salespoint/debt/paymentDebtSuccess/PaymentDebtSuccessPage";
import CustomerDebtDetailPage from "../pages/salespoint/debt/CustomerDebtDetail/CustomerDebtDetailPage";
import RefundPaymentPage from "../pages/salespoint/refund/refundPayment/RefundPaymentPage";
import AdminPage from "../pages/settings/admin/AdminPage";
import {checkPermission} from "../common/utils/Utils";
import NoAccess from "../shared/components/NoAccess";
import {permissionCode} from "../common/constants/Constants";
import PermissionsPage from "../pages/settings/permissions/PermissionsPage";
import PaymentsPage from "../pages/settings/payments/PaymentsPage";
import FilesPage from "../pages/settings/files/FilesPage";
import PermissionsEditPage from "../pages/settings/permissions/PermissionsEditPage";
interface IRouter {
    code: string,
    children: any
}
const CheckRouter = ({children, code}: IRouter) => {
    if (!checkPermission(code)) {
        return <NoAccess/>;
    }
    return children;
};

const Redirect = () => {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path={`/dang-nhap`} element={<Login />} />
            <Route path={`/`} element={<CheckRouter code={permissionCode.dashboard}>
                <DashBoard/>
            </CheckRouter>} />
            <Route path={`/hang-hoa/`}>
                <Route
                    path={`san-pham`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <ProductPage/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`san-pham/them-moi`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <CreateProduct/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`san-pham/:id/thong-tin-chung`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <EditProductGeneral/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`san-pham/:id/gia`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <EditProductVariant/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`san-pham/:id/tao-ma-barcode`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <EditProductBarcode/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`san-pham/:id/tao-ma-qrcode`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <EditProductQrcode/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`san-pham/:id/hinh-anh`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <EditProductImage/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`thuong-hieu`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.brands}>
                        <Brand/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`nhom-san-pham`}
                    element={<PrivateRoute element={<GroupProduct />} />}
                />
                <Route
                    path={`danh-muc`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.categories}>
                        <CategoryProduct/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`ma-giam-gia`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.sales}>
                        <Coupon/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`the-san-pham`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.tags}>
                        <TagProduct/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`nhan`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.features}>
                        <Label/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`ton-kho`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.products}>
                        <Inventory/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`thuoc-tinh`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.attributes}>
                        <Attribute/>
                    </CheckRouter>} />}
                />
            </Route>
            <Route
                path={`thong-tin-tai-khoan`}
                element={<PrivateRoute element={<Info />} />}
            />
            <Route
                path={`don-hang`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.order}>
                    <Order/>
                </CheckRouter>} />}
            />
            <Route
                path={`don-hang/:id`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.order}>
                    <EditOrder/>
                </CheckRouter>} />}
            />
            <Route
                path={`in-hoa-don/:id`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.order}>
                    <PrintOrder/>
                </CheckRouter>} />}
            />

            <Route
                path={`chi-nhanh-cua-hang`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.locations}>
                    <StoreBranchPage/>
                </CheckRouter>} />}
            />
            <Route
                path={`chi-nhanh-cua-hang/them-moi`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.locations}>
                    <StoreBranchModal/>
                </CheckRouter>} />}
            />
            <Route
                path={`chi-nhanh-cua-hang/:id/sua`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.locations}>
                    <StoreBranchModal/>
                </CheckRouter>} />}
            />

            <Route path={`/pos/`}>
                <Route
                    path={`may-pos`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.machine_pos}>
                        <Pos/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`tang-phong`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.machine_pos}>
                        <Room/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`ban-an`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.machine_pos}>
                        <DiningTablesPage/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`chi-tiet-don-hang/:id`}
                    element={<PrivateRoute element={<CheckRouter code={permissionCode.pos}>
                        <DetailOrderPosPage/>
                    </CheckRouter>} />}
                />
                <Route
                    path={`:id/chi-tiet-don-hang/:pos_session_id/:idViewData`}
                    element={
                        <PrivateRoute element={<CheckRouter code={permissionCode.pos}>
                            <ViewDetailOrderPosPage/>
                        </CheckRouter>} />
                    }
                />
                <Route
                    path={`phien-may/:id`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <DetailMachinePosPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`:posId/danh-sach-don-hang-phien-may/:sessionId`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <OrdersSessionMachinePage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`:postId/san-pham`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <ProductInPosPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`:postId/cong-no`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <CustomerDebtPage/>
                    </CheckRouter>}
                ></Route>

                <Route
                    path={`:postId/chi-tiet-cong-no/:customerId`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <CustomerDebtDetailPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`:postId/thanh-toan-cong-no`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <PaymentDebtPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`thanh-toan-cong-no-thanh-cong/:transactionPosId`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <PaymentDebtSuccessPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`:postId/thanh-toan`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <PaymentPosPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`thanh-cong/:transactionPosId`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <PaymentSuccessPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`phien-may/:id`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <DetailMachinePosPage/>
                    </CheckRouter>}
                ></Route>
                  <Route
                    path={`:posId/hoan-tien`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <RefundOrderPage/>
                    </CheckRouter>}
                ></Route>
                  <Route
                    path={`:posId/hoan-tien/:orderId`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <RefundOrderDetailsPage/>
                    </CheckRouter>}
                ></Route>
                <Route
                    path={`:posId/thanh-toan-hoan-tien`}
                    element={<CheckRouter code={permissionCode.pos}>
                        <RefundPaymentPage/>
                    </CheckRouter>}
                ></Route>
            </Route>

            <Route
                path={`danh-sach-pos`}
                element={<PrivateRoute element={<CheckRouter code={permissionCode.pos}>
                    <SalePointPage/>
                </CheckRouter>} />}
            />

            <Route path={`/cai-dat/`}>
                <Route
                    path={`quan-tri-vien`}
                    element={<PrivateRoute element={<AdminPage />} />}
                />
                <Route
                    path={`quyen`}
                    element={<PrivateRoute element={<PermissionsPage />} />}
                />
                <Route
                    path={`quyen/:id`}
                    element={<PrivateRoute element={<PermissionsEditPage />} />}
                />
                <Route
                    path={`thanh-toan`}
                    element={<PrivateRoute element={<PaymentsPage />} />}
                />
                <Route
                    path={`tep-tin`}
                    element={<PrivateRoute element={<FilesPage />} />}
                />
            </Route>
        </Routes>
    );
};

export default Redirect;
