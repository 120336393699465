import React from "react";
import {observer} from "mobx-react";
import {productStore} from "../../ProductStore";
import {Button, Form, Input, InputNumber, Modal, Space} from "antd";
import SelectCustom from "../../../../../shared/components/form/SelectCustom";
import SelectStatusNumber from "../../../../../shared/components/form/SelectStatusNumber";
import {handleFile} from "../../../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../../../shared/components/form/UploadFile";
import SelectStatus from "../../../../../shared/components/form/SelectStatus";
import {toastUtils} from "../../../../../common/utils/Toastutils";
import {handleKeyPress} from "../../../../../common/utils/Utils";
const  ModalCreateVariant = () => {
    const [formCreateVariant] = Form.useForm();
    const handleQtyChange = (index:any, delta:any) => {
        const item = productStore.staticDataAddVariant.qty[index];
        const newQty = Math.max(0, (item.qty || 0) + delta);
        item.qty = newQty;
    };
    const handleInputChange = (index:any, value:any) => {
        const newQty = Math.max(0, value);
        productStore.staticDataAddVariant.qty[index].qty = newQty;
    };
    return(
        <>
            <Modal
                className=""
                title="Thêm biến thể mới"
                footer={null}
                open={productStore.isModalOpenCreateVariant}
                onCancel={productStore.handleModalCloseCreateVariant}
                afterClose={productStore.handleClose}
            >
                <Form className="form-create-edit"
                      form={formCreateVariant}
                      name="control-hooks-edit"
                      onFinish={(values) => {
                          if(productStore.staticDataAddVariant.price === productStore.staticDataAddVariant.price_original) {
                              toastUtils.warning(`Giá bán phải nhỏ hơn giá gốc`, `1`);
                              return false;
                          }
                          if(productStore.staticDataAddVariant.thumbnail === ''){
                              toastUtils.warning(`Hình ảnh thuộc tính không được để trống`, `1`);
                              return false;
                          }
                          // if(productStore.staticDataAddVariant.price > productStore.staticDataAddVariant.price_original){
                          //     toastUtils.warning(`Giá bán không được lớn hơn giá gốc`, `1`);
                          //     return false;
                          // }
                          productStore.staticDataAddVariant.attribute_ids  = productStore.dataProduct.attributes.map((item:any) => {return item?.attribute_id})
                          productStore.staticDataAddVariant.height  = values.height
                          productStore.staticDataAddVariant.length  = values.length
                          productStore.staticDataAddVariant.weight  = values.weight
                          productStore.staticDataAddVariant.width  = values.width
                          productStore.staticDataAddVariant.sku  = values.sku
                          productStore.staticDataAddVariant.unit  = values.unit
                          productStore.staticDataAddVariant.status  = values.status
                          productStore.staticDataAddVariant.allow_inventory  = values.allow_inventory
                          productStore.staticDataAddVariant.locked  = '1'
                          productStore.fetchCreateVariant(formCreateVariant)
                      }}
                >
                    <div className="grid-2 gap-20">
                        {productStore.dataProduct?.attributes?.map((item: any, index: number) => (
                            <div className="item-form">
                                <Form.Item
                                    key={`attributes${index}`}
                                    name={`attributes${index}`}
                                    label={item.name}
                                    rules={[{required: true, message: 'Không được để trống!'}]}>
                                    <SelectCustom
                                        list={item.children_attributes}
                                        placeholder={`-- Chọn --`}
                                        onChange={(value: any) => productStore.dataProduct.attributes[index].attribute_id = value}
                                        selected={() => {
                                            const attributes = productStore.dataProduct?.attributes[index]?.attribute_id;
                                            return attributes || undefined;
                                        }}
                                        getLabel={(item: any) => item.name}
                                    />
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                    <UploadFile
                        text={`hình ảnh`}
                        description={`Hình ảnh sản phẩm (kích thước 800 x 800)`}
                        file={productStore.staticDataAddVariant.thumbnail}
                        onIconRemove={productStore.handleClosePreviewAddVariant}
                        onFileChange={(e) => handleFile(e, productStore.changeFilePreviewAddVariant)}
                    />
                    <div className="grid-2 gap-20">
                        <div className="item-form">
                            <Form.Item
                                name="price"
                                label="Giá bán"
                                rules={[
                                    {required: true, message: 'Không được để trống!'},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            const priceCompareOrigin = getFieldValue('price_original');
                                            if (value && priceCompareOrigin && value > priceCompareOrigin) {
                                                return Promise.reject(new Error('Giá bán không được lớn hơn giá gốc!'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    onKeyPress={handleKeyPress}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                    onChange={(value) => {
                                        productStore.staticDataAddVariant.price = `${value}`;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="price_original"
                                label="Giá gốc"
                                rules={[
                                    {required: true, message: 'Không được để trống!'},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            const priceOrigin = getFieldValue('price');
                                            if (value && priceOrigin && priceOrigin > value) {
                                                return Promise.reject(new Error('Giá gốc không được nhỏ hơn giá bán!'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    onKeyPress={handleKeyPress}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                    onChange={(value) => {
                                        productStore.staticDataAddVariant.price_original = `${value}`;
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="sku"
                                label="SKU"
                                rules={[{required: true, message: "Không được để trống!"},{min: 4, message: "Sku phải từ 4 ký tự trở lên"}]}
                            >
                                <Input
                                    onChange={(event) => {

                                    }}/>
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="unit"
                                label="Đơn vị"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <Input
                                    onChange={(event) => {

                                    }}/>
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="weight"
                                label="Cân nặng (gram)"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <Input
                                    onKeyPress={handleKeyPress}
                                    type="number"
                                    onChange={(event) => {

                                    }}/>
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="height"
                                label="Chiều cao (cm)"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <Input
                                    onKeyPress={handleKeyPress}
                                    type="number"
                                    onChange={(event) => {

                                    }}/>
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="length"
                                label="Chiều dài (cm)"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <Input
                                    onKeyPress={handleKeyPress}
                                    type="number"
                                    onChange={(event) => {
                                    }}/>
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item
                                name="width"
                                label="Chiều rộng (cm)"
                                rules={[{required: true, message: "Không được để trống!"}]}
                            >
                                <Input
                                    onKeyPress={handleKeyPress}
                                    type="number"
                                    onChange={(event) => {
                                    }}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-form">
                        <Form.Item name="status" label="Tình trạng"
                                   rules={[{required: true, message: 'Không được để trống!'}]}>
                            <SelectStatusNumber selected={() => productStore.staticDataAddVariant.status}
                                                onChange={(value: any) => productStore.staticDataAddVariant.status = value}
                                                placeholder='-- Chọn --'
                                                textOn={`Còn hàng`} textOff={`Hết hàng`}/>
                        </Form.Item>
                    </div>
                    <div className="item-form">
                        <Form.Item name="allow_inventory" label="Loại tồn kho"
                                   rules={[{required: true, message: 'Không được để trống!'}]}>
                            <SelectStatusNumber selected={() => productStore.staticDataAddVariant.allow_inventory}
                                                onChange={(value: any) => productStore.staticDataAddVariant.allow_inventory = value}
                                                placeholder='-- Chọn --'
                                                textOn={`Cho phép quản lý tồn kho`}
                                                textOff={`Không cho phép quản lý tồn kho`}/>
                        </Form.Item>
                    </div>
                    {productStore.staticDataAddVariant.allow_inventory === 1 && (
                        <>
                            <div className="item-form-inventory">
                                {productStore.staticDataAddVariant?.qty.map((itemStore:any, indexStore:number) => (
                                    <div className="item-store" key={indexStore}>
                                        <p>{itemStore.name}</p>
                                        <div className="qty">
                                            <div className="minus">
                                                <button
                                                    type="button"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleQtyChange(indexStore, -1);
                                                    }}
                                                >
                                                    -
                                                </button>
                                            </div>
                                            <Input
                                                type="number"
                                                placeholder="0"
                                                value={productStore.staticDataAddVariant?.qty[indexStore].qty || "0"}
                                                onChange={(e) => handleInputChange(indexStore, parseInt(e.target.value, 10))}
                                                min="0"
                                                max="99999"
                                                maxLength={16}
                                            />
                                            <div className="plus">
                                                <button
                                                    type="button"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleQtyChange(indexStore, 1);
                                                    }}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Hoàn tất
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default observer(ModalCreateVariant);